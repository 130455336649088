import Gis from './Gis/actions';
import GisLayer from './GisLayer/actions';
import DataSet from './DataSet/actions';
import { SET_OPTION_BY_PATH, SET_CONFIG_FIELD_BY_PATH, SET_CUSTOM_DATA, RESET_CUSTOM_DATA } from './mutations';

const PREFIX = 'Component';
export const setOption = `${PREFIX}/setOption`;
export const setOptions = `${PREFIX}/setOptions`;
export const setCustomData = `${PREFIX}/setCustomData`;
export const resetCustomData = `${PREFIX}/resetCustomData`;
export const showComponent = `${PREFIX}/showComponent`;
export const hideComponent = `${PREFIX}/hideComponent`;
export const toggleComponent = `${PREFIX}/toggleComponent`;

const actions = {
  /**
   * Действие setOption устанавливает настройку компонента
   * @param {String} param.name название опции компонента (можно указать путь через ".")
   * @param {String} param.value значение опции
   */
  [setOption]({ commit }, { componentId, name, value }) {
    commit(SET_OPTION_BY_PATH, {
      componentId,
      optionName: name,
      optionValue: value
    });
  },

  /**
   * setOptions устанавливает несколько настроек из атрибутов переданного объекта
   * @param {Object} options - настройки
   */
  [setOptions]({ commit }, { componentId, ...options }) {
    for (const entry of Object.entries(options)) {
      commit(SET_OPTION_BY_PATH, {
        componentId,
        optionName: entry[0],
        optionValue: entry[1]
      });
    }
  },

  /**
   * Действие setCustomData устанавливает значение customData компонента
   */
  [setCustomData]({ commit }, { componentId, ...customData }) {
    commit(SET_CUSTOM_DATA, {
      componentId,
      customData
    });
  },

  /**
   * Действие resetCustomData сбрасывает значение customData компонента
   */
  [resetCustomData]({ commit }, { componentId }) {
    commit(RESET_CUSTOM_DATA, {
      componentId
    });
  },

  /**
   * Действие отображает комопнент (ставит visible = true в конфиге itemConfig)
   */
  [showComponent]({ commit }, { componentId }) {
    commit(SET_CONFIG_FIELD_BY_PATH, {
      componentId,
      fieldName: 'visible',
      fieldValue: true
    });
  },

  /**
   * Действие скрывает комопнент (ставит visible = false в конфиге itemConfig)
   */
  [hideComponent]({ commit }, { componentId }) {
    commit(SET_CONFIG_FIELD_BY_PATH, {
      componentId,
      fieldName: 'visible',
      fieldValue: false
    });
  },

  /**
   * Действие переключает видимость компонента (меняет visible в конфиге itemConfig)
   */
  [toggleComponent]({ state, commit }, { componentId }) {
    let componentConfig = state.items[componentId];
    if (!componentConfig) {
      return;
    }

    commit(SET_CONFIG_FIELD_BY_PATH, {
      componentId,
      fieldName: 'visible',
      fieldValue: componentConfig.visible === false
    });
  },

  ...Gis,
  ...GisLayer,
  ...DataSet
};

export default actions;
