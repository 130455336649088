import hash from 'object-hash';

import { setUrlParameter } from '@/utils/common';
import { WFS_VERSION, PROTOCOLS, NO_PROXY_HOSTS } from './const';
import { getUsedAttributes } from './utils';
import { formatTimelineValue } from '@/utils/dateTime';
import { OPACITY_MONTH_DIFF_ATTRIBUTE } from './const';

import { ANIMATION_VIEW_STACKED } from '../timeline/GisTimelineConstants';
import { deepCopy } from '@/utils/deep';
import { mapboxToDevExtreme } from '@/utils/transformFilters';
import { DEFAULT_EPSG_SRS, DEFAULT_EPSG_BBOX } from '@/utils/const';
import { AnimatedCarsLayer } from '../customLayers/animatedCarsLayer';

const PROXY_TILES = process.env.VUE_APP_PROXY_TILES !== 'disabled';
const PROXY_SERVICE = process.env.VUE_APP_PROXY_SERVICE || '';
const PROXY_SERVICE_WITH_SUBDOMAINS = process.env.VUE_APP_PROXY_SERVICE_WITH_SUBDOMAINS || null;
const PROXY_SERVICE_SUBDOMAINS = process.env.VUE_APP_PROXY_SERVICE_SUBDOMAINS || null;

export default {
  tile: createMapboxTileSource,
  wms: createMapboxWmsSource,
  wmts: createMapboxWmtsSource,
  wcs: createMapboxWcsSource,
  wfs: createMapboxGeoJsonSource,
  geojson: createMapboxGeoJsonSource,
  pkk: createMapboxPkkLayer,
  vector: createVectorSource
};

export const sourceHash = {
  wms: getWmsSourceHash,
  pkk: getPkkSourceHash
};

function createMapboxTileSource(layerConfig) {
  const layerOptions = layerConfig.options || {};
  const subdomains = layerOptions.subdomains || '';
  const bounds = checkBounds(layerOptions.bounds) ? layerOptions.bounds : [-180, -85.051129, 180, 85.051129];
  let url = layerOptions.url || '';
  let maxzoom = layerOptions.maxNativeZoom || 22;
  let minzoom = layerOptions.minNativeZoom || 0;
  const scheme = layerOptions.scheme || 'xyz';
  const tileSize = layerOptions.tileSize || 256;
  const is_cache = layerOptions.is_cache || false;
  const useProxy = layerOptions.useProxy || false;
  const { paramForRepaint } = layerOptions;
  let legendStyleItemId = layerOptions.legendStyleItemId || layerOptions.legendStyleItemIds[layerOptions.legendStyleItemIds.length - 1];
  //VUE_APP_TILES_HOST - настройка для отображения растров на локалхосте (.env.local)
  const tilesHost = process.env.VUE_APP_TILES_HOST ?? window.location.origin;
  const fullUrl = url.startsWith('/') ? `${tilesHost}${url}` : url;
  const types = ['redChannel', 'greenChannel', 'blueChannel'];
  const properties = ['gam_val', 'rescale', 'sig_val', 'sig_bias', 'expression'];
  const deleteProps = ['id', 'isOpen', 'isOptionsOpen', 'filename', 'channelsSettings', 'maxzoom', 'minzoom', 'caption', 'name'];
  const urlWithStyle = new URL(fullUrl);
  const params = {};
  if (legendStyleItemId) {
    const obj = deepCopy(layerOptions?.rasterStyle).find((item) => {
      return item.name === legendStyleItemId;
    });
    if (obj?.channelsSettings) {
      //В списке могут быть не только настройки каналов, отсекаем не каналы
      const channelsSettings = obj.channelsSettings.filter((c) => types.includes(c.type));
      if (channelsSettings.length === 1) {
        delete obj.sig_val;
        delete obj.gam_val;
        delete obj.sat;
      }
      types.forEach((type, index) => {
        const channel = channelsSettings.find((c) => c.type === type);
        if (!channel) return;
        properties.forEach((property) => {
          if (channelsSettings.length === 1 && (property === 'sig_val' || property === 'gam_val' || property === 'sat' || property === 'sig_bias')) return;
          params[property] = params[property] || [];
          params[property][index] = channel[property];
        });
      });
    }
    Object.assign(obj, params);
    for (const prop of deleteProps) {
      delete obj[prop];
    }
    for (let item in obj) {
      if (obj[item] !== undefined && obj[item] !== null) {
        let value = Array.isArray(obj[item]) ? JSON.stringify(obj[item]) : obj[item];
        if (item === 'mask') {
          value = '[' + obj[item] + ']';
        }
        if (urlWithStyle.searchParams.has(item)) {
          urlWithStyle.searchParams.set(item, value);
        } else {
          urlWithStyle.searchParams.append(item, value);
        }
      }
    }
  }
  if (paramForRepaint) {
    urlWithStyle.searchParams.append('paramForRepaint', paramForRepaint);
  }

  let proxyUrl = decodeURI(JSON.stringify(urlWithStyle).replace(/"/g, ''));
  if (useProxy && PROTOCOLS.some((protocol) => fullUrl.includes(protocol))) {
    proxyUrl = `${process.env.VUE_APP_PROXY_SERVICE}?url=${fullUrl}`;
  }

  let tiles = subdomains.split(',').map((subdomain) => {
    return `${proxyUrl.replace('{s}', subdomain)}`;
  });

  if (is_cache) {
    tiles = tiles.map((tile) => {
      return tile + (tile.includes('?') ? '&' : '?') + `is_cache=${is_cache}`;
    });
  }

  return {
    type: 'raster',
    bounds,
    maxzoom,
    minzoom,
    scheme,
    tileSize,
    tiles
  };
}

function createMapboxWmsSource(layerConfig) {
  const layerOptions = layerConfig.options || {};
  const subdomains = layerOptions.subdomains || '';
  const url = layerOptions.url || layerOptions.wmsUrl || '';
  const bounds = checkBounds(layerOptions.bounds) ? layerOptions.bounds : [-180, -85.051129, 180, 85.051129];
  const maxzoom = layerOptions.maxNativeZoom || 22;
  const minzoom = layerOptions.minNativeZoom || 0;
  const layers = layerOptions.layers || '';
  const styles = layerOptions.styles || '';
  const format = layerOptions.format || 'image/png';
  const transparent = layerOptions.transparent || false;
  const version = layerOptions.version || '1.1.1';
  const tileSize = layerOptions.tileSize || 256;
  const srs = layerOptions.srs || DEFAULT_EPSG_SRS;
  const bbox = layerOptions.bbox || DEFAULT_EPSG_BBOX;
  const paramForRepaint = layerOptions.paramForRepaint;
  const useProxy = layerOptions.useProxy || false;

  const params = {
    layers,
    styles,
    format,
    transparent,
    version,
    width: tileSize,
    height: tileSize,
    bbox,
    [layerOptions.version === '1.3.0' ? 'crs' : 'srs']: srs,
    paramForRepaint
  };
  const paramsStr = Object.entries(params)
    .map(([paramName, paramValue]) => {
      return `${paramName}=${paramValue}`;
    })
    .join('&');

  //Костыль, если в строке уже есть параметры
  let delimiter = '?';
  if (url.indexOf('?') !== -1) {
    delimiter = '&';
  }

  let proxyUrl = url;
  if (useProxy && PROXY_TILES && PROTOCOLS.some((protocol) => url.includes(protocol)) && NO_PROXY_HOSTS.every((host) => !url.includes(host))) {
    proxyUrl = `${process.env.VUE_APP_PROXY_SERVICE}?url=${url}`;
  }
  const tiles = subdomains.split(',').map((subdomain) => {
    return `${proxyUrl.replace('{s}', subdomain)}${delimiter}service=WMS&request=GetMap&${paramsStr}`;
  });

  return {
    type: 'raster',
    bounds,
    maxzoom,
    minzoom,
    tileSize,
    tiles
  };
}

export function getWmsSourceHash(layerConfig) {
  const layerOptions = layerConfig.options || {};
  const url = layerOptions.url || layerOptions.wmsUrl || '';
  const bounds = checkBounds(layerOptions.bounds) ? layerOptions.bounds : [-180, -85.051129, 180, 85.051129];
  const maxzoom = layerOptions.maxNativeZoom || 22;
  const minzoom = layerOptions.minNativeZoom || 0;
  const format = layerOptions.format || 'image/png';
  const transparent = layerOptions.transparent || false;
  const version = layerOptions.version || '1.1.1';
  const tileSize = layerOptions.tileSize || 256;
  const srs = layerOptions.srs || DEFAULT_EPSG_SRS;
  const bbox = layerOptions.bbox || DEFAULT_EPSG_BBOX;

  const urlParams = {
    format,
    transparent,
    version,
    width: tileSize,
    height: tileSize,
    bbox,
    [layerOptions.version === '1.3.0' ? 'crs' : 'srs']: srs
  };

  return hash.MD5({
    type: 'raster',
    bounds,
    maxzoom,
    minzoom,
    tileSize,
    url,
    ...urlParams
  });
}

function createMapboxWmtsSource(layerConfig) {
  const layerOptions = layerConfig.options || {};
  const subdomains = layerOptions.subdomains || '';
  const url = layerOptions.url || layerOptions.wmtsUrl || '';
  const bounds = checkBounds(layerOptions.bounds) ? layerOptions.bounds : [-180, -85.051129, 180, 85.051129];
  const maxzoom = layerOptions.maxNativeZoom || 22;
  const minzoom = layerOptions.minNativeZoom || 0;
  const layer = layerOptions.layers || [];
  const styles = layerOptions.styles || '';
  const format = layerOptions.format || 'image/png';
  const version = layerOptions.version || '1.0.0';
  const tilematrixset = 'EPSG:900913';
  const tilematrix = 'EPSG:900913:{z}';
  const tilecol = '{x}';
  const tilerow = '{y}';
  const tileSize = layerOptions.tileSize || 256;
  const useProxy = layerOptions.useProxy || false;

  const params = {
    layer,
    styles,
    format,
    version,
    tilematrixset,
    tilematrix,
    tilecol,
    tilerow
  };
  const paramsStr = Object.entries(params)
    .map(([paramName, paramValue]) => {
      return `${paramName}=${paramValue}`;
    })
    .join('&');

  //Костыль, если в строке уже есть параметры
  let delimiter = '?';
  if (url.indexOf('?') !== -1) {
    delimiter = '&';
  }

  let proxyUrl = url;
  if (useProxy && PROXY_TILES && PROTOCOLS.some((protocol) => url.includes(protocol)) && NO_PROXY_HOSTS.every((host) => !url.includes(host))) {
    proxyUrl = `${process.env.VUE_APP_PROXY_SERVICE}?url=${url}`;
  }

  const tiles = subdomains.split(',').map((subdomain) => {
    return `${proxyUrl.replace('{s}', subdomain)}${delimiter}service=WMTS&request=GetTile&${paramsStr}`;
  });

  return {
    type: 'raster',
    bounds,
    maxzoom,
    minzoom,
    tileSize,
    tiles
  };
}

function createMapboxWcsSource(layerConfig) {
  const layerOptions = layerConfig.options || {};
  const url = layerOptions.url || layerOptions.wcsUrl || '';
  const coverageId = layerOptions.layers || '';
  const styles = layerOptions.styles || '';
  const format = layerOptions.format || 'image/png';
  const version = WFS_VERSION;
  const coordinates = layerOptions.coordinates;
  const useProxy = layerOptions.useProxy || false;

  let params = {
    coverageId,
    styles,
    format,
    version
  };

  const paramsStr = Object.entries(params)
    .map(([paramName, paramValue]) => {
      return `${paramName}=${paramValue}`;
    })
    .join('&');

  let proxyUrl = url;
  if (useProxy && PROXY_TILES && PROTOCOLS.some((protocol) => url.includes(protocol)) && NO_PROXY_HOSTS.every((host) => !url.includes(host))) {
    proxyUrl = `${process.env.VUE_APP_PROXY_SERVICE}?url=${url}`;
  }

  return {
    type: 'image',
    url: `${proxyUrl}?service=WCS&request=GetCoverage&${paramsStr}`,
    coordinates
  };
}

function createMapboxGeoJsonSource(layerConfig, mapbox) {
  const layerOptions = layerConfig.options || {};

  //Тип geojson слоя - анимация движения транспорта
  if (layerOptions.subType === 'cars') {
    const customLayer = AnimatedCarsLayer.getInstance(layerConfig.componentId, layerOptions, mapbox);
    customLayer.start(layerConfig.sourceId);
    return customLayer.getSource();
  } else {
    return {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: Object.values(layerOptions.featuresDict)
      },
      promoteId: layerOptions.keyField || 'id',
      ...layerOptions.sourceOptions
    };
  }
}

function createMapboxPkkLayer(layerConfig) {
  const layerOptions = layerConfig.options || {};
  const url = layerOptions.url || '';
  //const bounds = checkBounds(layerOptions.bounds) ? layerOptions.bounds : [-180, -85.051129, 180, 85.051129];
  const maxzoom = layerOptions.maxNativeZoom || 22;
  const minzoom = layerOptions.minNativeZoom || 0;
  let layers = layerOptions.layers || '';
  const format = layerOptions.format || 'PNG32';
  const transparent = layerOptions.transparent || false;
  const size = layerOptions.size || 256;
  const bbox = layerOptions.bbox || DEFAULT_EPSG_BBOX;
  const bboxSR = layerOptions.bboxSR || 102100;
  const imageSR = layerOptions.imageSR || 102100;
  const dpi = layerOptions.dpi || 90;
  const arcGisToken = layerOptions.arcGisToken || null;
  const paramForRepaint = layerOptions.paramForRepaint;
  //Внутренний параметр Росреестра
  const _ts = false;

  if (layers.indexOf('show:') !== 0) {
    layers = `show:${layers}`;
  }

  //historicMoment - параметр из аркгис апи, на неизвестный параметр может быть непредсказуемая реакция
  const params = {
    layers,
    format,
    transparent,
    size: `${size},${size}`,
    bbox,
    f: 'image',
    bboxSR,
    imageSR,
    dpi,
    historicMoment: paramForRepaint,
    _ts
  };

  if (arcGisToken) {
    params.token = arcGisToken;
  }

  const paramsStr = Object.entries(params)
    .map(([paramName, paramValue]) => {
      return `${paramName}=${paramName === 'bbox' ? paramValue : encodeURIComponent(paramValue)}`;
    })
    .join('&');

  let proxyUrl = url;
  //Для ППК прокси не нужен
  if (PROXY_TILES && PROTOCOLS.some((protocol) => url.includes(protocol))) {
    proxyUrl = `${PROXY_SERVICE_WITH_SUBDOMAINS || PROXY_SERVICE}?url=${url}`;
  }

  let tiles = [];
  if (PROXY_SERVICE_SUBDOMAINS) {
    tiles = PROXY_SERVICE_SUBDOMAINS.split(',').map((subdomain) => {
      return `${proxyUrl.replace('{s}', subdomain)}export?${paramsStr}`;
    });
  } else {
    tiles = [`${proxyUrl}export?${paramsStr}`];
  }

  return {
    type: 'raster',
    //bounds,
    maxzoom,
    minzoom,
    tileSize: size,
    tiles
  };
}

function getPkkSourceHash(layerConfig) {
  const layerOptions = layerConfig.options || {};
  const url = layerOptions.url || '';
  const maxzoom = layerOptions.maxNativeZoom || 22;
  const minzoom = layerOptions.minNativeZoom || 0;
  const format = layerOptions.format || 'PNG32';
  const transparent = layerOptions.transparent || false;
  const size = layerOptions.size || 256;
  const bbox = layerOptions.bbox || DEFAULT_EPSG_BBOX;
  const bboxSR = layerOptions.bboxSR || 102100;
  const imageSR = layerOptions.imageSR || 102100;
  const dpi = layerOptions.dpi || 90;

  const urlParams = {
    format,
    transparent,
    size: `${size},${size}`,
    bbox,
    f: 'image',
    bboxSR,
    imageSR,
    dpi
  };

  return hash.MD5({
    type: 'raster',
    maxzoom,
    minzoom,
    url,
    ...urlParams
  });
}

function createVectorSource(layerConfig, mapbox) {
  const layerOptions = layerConfig.options || {};
  let url = excludeWhereFromUrl(layerOptions.url || '');
  // передаем параметр portal_id для tms-запроса
  const portal_id = layerConfig.portal_id;
  const keyField = layerOptions.keyField || 'id';
  const tmsFields = getTmsFields(layerOptions.tmsFields, layerOptions.mapboxLayers);
  const attributes = [...new Set([...getUsedAttributes(layerOptions), keyField, ...tmsFields])];
  const { paramForRepaint } = layerOptions;
  //Сортировка нужна для исключения мигания слоя после изменения порядка атрибутов
  attributes.sort((item1, item2) => {
    if (item1 > item2) return 1;
    else if (item1 < item2) return -1;
    else return 0;
  });

  const filterValue = layerOptions.filterValue;
  const params = {
    properties: attributes,
    paramForRepaint,
    is_cache: layerOptions.is_cache,
    portal_id
  };

  if (layerOptions.filterId) {
    //Если есть идентификатор созданного  фильтра то достаточно передать только его
    params._config_filter_id = layerOptions.filterId;
    //Все случаи переделаны на _config_filter_id, но этот код пока оставим на всякий случай
  } else {
    //Если нет идентификатора, то отрабатываем по старой ветке параметры where и filter
    if (Array.isArray(layerOptions.where) && layerOptions.where.length) {
      if (Array.isArray(layerOptions.filterValue) && layerOptions.filterValue.length) {
        params.where = [layerOptions.where, 'and', layerOptions.filterValue];
      } else {
        params.where = layerOptions.where;
      }
    } else if (filterValue) {
      params.where = filterValue;
    }
    if (layerOptions.filter) {
      params.filter = layerOptions.filter;
    }
  }

  //Получение постоянных параметров фильтрации
  const paramsFromUrl = getParamsFrom(url); //Параметры, зашитые в url (так лучше не делать)
  const paramsFromOptions = layerOptions.params; //Параметры из настроек слоя, это не одобрено
  //Дополнительные параметры из formParams
  let formParams = [];
  if (layerOptions.formParams && layerOptions.formParamsValue) {
    formParams = {
      [layerOptions.formParams.targetField]: layerOptions.formParamsValue
    };
  }
  params.params = {
    ...paramsFromUrl,
    ...paramsFromOptions,
    ...formParams
  };
  if (layerOptions.currentReportId) {
    params.params.report_id = layerOptions.currentReportId;
  }

  //TODO: Переделать. Надо хранить эти параметры не в настройке слоя-стиля, а в корне настроек слоя
  const clusterStyle = layerOptions.mapboxLayers.find((style) => style.metadata?.isCluster);
  if (clusterStyle) {
    const visibleRulesName = Object.keys(layerOptions.legendStyleItems).filter((rule) => layerOptions.legendStyleItems[rule].visible === true);

    const allRules = layerOptions.mapboxLayers
      .filter((layer) => !layer.metadata.isCluster) // фильтруем слои, у которых metadata.isCluster = true
      .map((layer) => ({ name: layer.name, filter: layer.filter ? mapboxToDevExtreme(layer.filter) : null }));

    // Оставляем только видимые стилевые слои
    const visibleRules = allRules.filter((obj) => visibleRulesName.includes(obj.name));

    //Добавлено -1, чтобы не было такого, что на определенном зуме ничего не видно
    params.params.max_cluster_zoom = clusterStyle.maxzoom - 1;
    params.params.cluster_distance = clusterStyle.clusterDistance;
    params.params.rules_filters = visibleRules;
    // Пока отключим это, потому что каждое изменение bbox перезапрашивает тайлы заново
    // params.params.bbox = mapbox.getBounds().toArray();
  }

  //Добавление параметра с датой из таймлайна
  if (layerOptions.timelineDateField && layerOptions.selectedDate) {
    const timeLineDate = formatTimelineValue(layerOptions.selectedDate, layerOptions.timelineMode, layerOptions.timelineDateFieldType);
    //Дату тоже расположим в params

    if (['params', 'all'].includes(layerOptions.timeLineFieldSendMode)) {
      params.params[layerOptions.timelineDateField] = timeLineDate;
    }

    if (['where', 'all'].includes(layerOptions.timeLineFieldSendMode) || !layerOptions.timeLineFieldSendMode) {
      if (layerOptions.where && layerOptions.where.length) {
        params.where = [layerOptions.where, 'and', getTimelineWhere(layerOptions)];
      } else {
        params.where = getTimelineWhere(layerOptions);
      }
    }
  }

  //Удаление params из url
  url = url.replace(/(&params=).*?(&|$)/, '');

  const paramsString = Object.entries(params)
    .map(([key, value]) => {
      return `${key}=${JSON.stringify(value)}`;
    })
    .join('&');

  const maxzoom = layerOptions.maxNativeZoom || 22;
  const minzoom = layerOptions.minNativeZoom || 0;
  const subdomains = layerOptions.subdomains || '';
  const bounds = checkBounds(layerOptions.bounds) ? layerOptions.bounds : [-180, -85.051129, 180, 85.051129];

  //Костыль, если в строке уже есть параметры
  let delimiter = '?';
  if (url.indexOf('?') !== -1) {
    delimiter = '&';
  }

  let tiles;
  //TODO: сделать настройку
  if (layerOptions.url.includes('rosreestr')) {
    //Для росреестра не добавлять properties
    tiles = subdomains.split(',').map((subdomain) => {
      return `${url.replace('{s}', subdomain)}` + (paramForRepaint ? `${delimiter}paramForRepaint=${paramForRepaint}` : '');
    });
  } else {
    tiles = subdomains.split(',').map((subdomain) => {
      return `${url.replace('{s}', subdomain)}${delimiter}${paramsString}`;
    });
  }

  return {
    type: 'vector',
    bounds,
    maxzoom,
    minzoom,
    tiles,
    promoteId: keyField
  };
}

export function redrawSource(sources, sourceId) {
  const source = sources[sourceId];
  if (source?.tiles) {
    source.tiles = source.tiles.map((tile) => {
      let ret;
      if (tile.includes('MapServer/export')) {
        //ПКК непредсказумо реагирует на неизвестные параметры
        //historicMoment - параметр из АПИ
        ret = setUrlParameter(tile, 'historicMoment', new Date().getTime());
      } else {
        ret = setUrlParameter(tile, 'paramForRepaint', new Date().getTime());
      }
      return ret;
    });
    return { ...sources, [sourceId]: source };
  }
}

function getTmsFields(tmsFields, mapboxLayers) {
  let retFields = Array.isArray(tmsFields) ? [...tmsFields] : [];

  if (mapboxLayers && Array.isArray(mapboxLayers) && mapboxLayers.some((layer) => layer.opacity) && !tmsFields.includes(OPACITY_MONTH_DIFF_ATTRIBUTE)) {
    retFields.push(OPACITY_MONTH_DIFF_ATTRIBUTE);
  }

  //Добавление атрибутов в подсказках
  const tooltipLayer = mapboxLayers.filter((layer) => layer.type === 'tooltip')[0];
  if (tooltipLayer && tooltipLayer.layout.sortAttribute) {
    retFields.push(tooltipLayer.layout.sortAttribute);
  }
  if (tooltipLayer && tooltipLayer.layout.content) {
    let matches = [...tooltipLayer.layout.content.matchAll(/replaceField\(([^<>]+?)\)/g)];
    retFields = retFields.concat(matches.map((mt) => mt[1]));
    matches = [...tooltipLayer.layout.content.matchAll(/formatNumeric\('([^<>]+?)'/g)];
    retFields = retFields.concat(matches.map((mt) => mt[1]));
  }
  retFields = [...new Set(retFields)];
  return retFields;
}
function getTimelineWhere(layerOptions) {
  const { timelineDateField, selectedDate, timelineMode, timelineViewType, timelineDateFieldType } = layerOptions;

  const timeLineDate = formatTimelineValue(selectedDate, timelineMode, timelineDateFieldType);
  let minDate;
  let maxDate;
  try {
    minDate = new Date(selectedDate.getTime());
    switch (timelineMode) {
      case 'year':
        if (timelineDateFieldType == 'timestamp') {
          minDate.setDate(1);
          minDate.setMonth(0);
          minDate.setHours(0, 0, 0);
          minDate.setMilliseconds(0);
          maxDate = new Date(minDate.getTime());
          maxDate = new Date(maxDate.setFullYear(minDate.getFullYear() + 1));
        }
        break;
      case 'month':
        minDate.setDate(1);
        minDate.setHours(0, 0, 0);
        minDate.setMilliseconds(0);
        maxDate = new Date(minDate.getTime());
        maxDate = new Date(maxDate.setMonth(minDate.getMonth() + 1));
        break;
      case 'date':
        minDate.setHours(0, 0, 0);
        minDate.setMilliseconds(0);
        maxDate = new Date(minDate.getTime());
        maxDate = new Date(maxDate.setDate(minDate.getDate() + 1));
        break;
      case 'hour':
        maxDate = new Date(minDate.getTime());
        maxDate = new Date(maxDate.setHours(minDate.getHours() + 1));
        break;
      case '20minutes':
        maxDate = new Date(minDate.getTime());
        maxDate = new Date(maxDate.setMinutes(minDate.getMinutes() + 20));
        break;
    }
  } catch (err) {}
  if (maxDate) {
    if (timelineViewType == ANIMATION_VIEW_STACKED) {
      //с накоплением
      return [timelineDateField, '<', formatTimelineValue(maxDate, timelineMode, timelineDateFieldType)];
    } else {
      //без накопления
      return [
        [timelineDateField, '>=', formatTimelineValue(minDate, timelineMode, timelineDateFieldType)],
        'and',
        [timelineDateField, '<', formatTimelineValue(maxDate, timelineMode, timelineDateFieldType)]
      ];
    }
  } else {
    //Режим для поля год
    if (timelineViewType == ANIMATION_VIEW_STACKED) {
      return [timelineDateField, '<=', timeLineDate];
    } else {
      return [timelineDateField, '=', timeLineDate];
    }
  }
}

function checkBounds(bounds) {
  return Array.isArray(bounds) && bounds.length === 4;
}

function excludeWhereFromUrl(url) {
  if (!url || !url.includes('where')) {
    return url;
  }
  const clearUrl = url.substr(0, url.lastIndexOf('?'));
  const getParams = url.substr(url.lastIndexOf('?') + 1).split('&');
  return `${clearUrl}?${getParams.filter((param) => !param.includes('where')).join('&')}`;
}

function getParamsFrom(urlString) {
  try {
    const url = new URL(urlString);
    return JSON.parse(url.searchParams.get('params') || '{}');
  } catch {
    return {};
  }
}
