<template>
  <!--eslint-disable-->
  <div
    class="constructor"
    @mousedown="handleMouseDown"
    @mousemove="handleMouseMove"
    @mouseup="handleMouseUp"
  >
    <PageNotFound
      v-if="pageNotFoundStatus"
      @setPageNotFoundStatus="setPageNotFoundStatus"
    />
    <div
      v-if="showConstructor"
      class="constructor__toolbar-wrapper"
    >
      <DxToolbar>
        <!--Кнопка навигации по страницам-->
        <template #navigationDrawerButton>
          <DxButton
            :activeStateEnabled="false"
            :focusStateEnabled="false"
            icon="menu"
            :hint="navigationDrawerOpened ? 'Скрыть список страниц' : 'Показать список страниц'"
            @click="navigationDrawerOpened = !navigationDrawerOpened"
            :class="[toggledButtonClass, { 'dx-item-selected': navigationDrawerOpened }]"
          >
          </DxButton>
        </template>
        <DxToolbarItem
          location="before"
          template="navigationDrawerButton"
        />

        <!--Кнопка показа левой панели-->
        <template #leftDrawerButton>
          <DxButton
            :activeStateEnabled="false"
            :focusStateEnabled="false"
            icon="fas fa-align-left"
            :hint="leftDrawerOpened ? 'Скрыть левую панель' : 'Показать левую панель'"
            @click="leftDrawerOpened = !leftDrawerOpened"
            :class="[toggledButtonClass, { 'dx-item-selected': leftDrawerOpened }]"
          >
          </DxButton>
        </template>
        <DxToolbarItem
          location="before"
          template="leftDrawerButton"
        />

        <!--Кнопка режима выделения компонентов-->
        <template #toggleButton>
          <DxButton
            :activeStateEnabled="false"
            :focusStateEnabled="false"
            icon="far fa-hand-pointer"
            :hint="selectMode ? 'Отключить режим выбора компонентов' : 'Включить режим выбора компонентов'"
            @click="toggleSelectMode"
            :class="[toggledButtonClass, { 'dx-item-selected': selectMode }]"
          >
          </DxButton>
        </template>
        <DxToolbarItem
          location="before"
          template="toggleButton"
        />

        <!--Кнопка открытия окна стиля страницы-->
        <template #pageStyleButton>
          <DxButton
            v-if="pageMode"
            :activeStateEnabled="false"
            :focusStateEnabled="false"
            icon="far fa-file-code"
            hint="Редактировать стиль страницы"
            @click="pageStylePopupVisible = true"
          >
          </DxButton>
        </template>
        <DxToolbarItem
          location="before"
          template="pageStyleButton"
        />

        <!--Кнопка открытия окна типов компонентов-->
        <template #pageTypeComponent>
          <!-- Поменять Иконку -->
          <DxButton
            v-if="pageMode"
            :activeStateEnabled="false"
            :focusStateEnabled="false"
            :disabled="disabledComponentButton(pages.componentTypes.url)"
            icon="core core-select-component"
            :hint="pages.componentTypes.titlePopup"
            @click="togglePopupByURLComponentVisible(true, pages.componentTypes)"
          />
        </template>
        <DxToolbarItem
          location="before"
          template="pageTypeComponent"
        />

        <!--Кнопка открытия окна типов событий-->
        <template #pageEventType>
          <!-- Поменять Иконку -->
          <DxButton
            v-if="pageMode"
            :activeStateEnabled="false"
            :focusStateEnabled="false"
            :disabled="disabledComponentButton(pages.eventType.url)"
            icon="core core-select-event"
            :hint="pages.eventType.titlePopup"
            @click="togglePopupByURLComponentVisible(true, pages.eventType)"
          />
        </template>
        <DxToolbarItem
          location="before"
          template="pageEventType"
        />

        <!--Кнопка открытия окна типов действий-->
        <template #pageActionType>
          <!-- Поменять Иконку -->
          <DxButton
            v-if="pageMode"
            :activeStateEnabled="false"
            :focusStateEnabled="false"
            :disabled="disabledComponentButton(pages.actionType.url)"
            icon="core core-select-action"
            :hint="pages.actionType.titlePopup"
            @click="togglePopupByURLComponentVisible(true, pages.actionType)"
          />
        </template>
        <DxToolbarItem
          location="before"
          template="pageActionType"
        />

        <!--Кнопка открытия табло-->
        <template #tableauButtonTemplate>
          <!-- Поменять Иконку -->
          <DxButton
            :activeStateEnabled="false"
            :focusStateEnabled="false"
            :disabled="tableauButtonDisabled"
            icon="core core-cube-solid"
            hint="Настроить данные (Табло)"
            @click="tableauButtonClick"
          />
        </template>

        <DxToolbarItem
          location="before"
          template="tableauButtonTemplate"
        />

        <!--Кнопка сборки мобильного приложения-->
        <template #mobileButtonTemplate>
          <DxButton
            :activeStateEnabled="false"
            :focusStateEnabled="false"
            :class="[toggledButtonClass, { 'dx-item-selected': showMobileBuildGrid }]"
            :disabled="mobileButtonDisabled"
            icon="core core-mobile"
            hint="Сборка мобильного приложения"
            @click="showMobileBuildGrid = !showMobileBuildGrid"
          />
        </template>

        <DxToolbarItem
          location="before"
          template="mobileButtonTemplate"
        />

        <!--Кнопка реестра изображений-->
        <template #imageListTemplate>
          <DxButton
            :activeStateEnabled="false"
            :focusStateEnabled="false"
            :class="toggledButtonClass"
            icon="core core-053-image"
            hint="Реестр изображений"
            @click="showImageList"
          />
        </template>

        <DxToolbarItem
          location="before"
          template="imageListTemplate"
        />

        <!--Кнопка удаления кэша сессии-->
        <template #removeSessionTemplate>
          <DxButton
            :activeStateEnabled="false"
            :focusStateEnabled="false"
            :class="toggledButtonClass"
            icon="refresh"
            hint="Удалить кэш"
            @click="removeSession"
          />
        </template>

        <DxToolbarItem
          location="before"
          template="removeSessionTemplate"
        />

        <!--Панель для работы в конструкторе с адаптивом-->
        <template #clientResolutionPanel>
          <ResolutionPanel
            @changeResolution="changeResolutionValue"
            @rotateDevice="rotateDevice"
            @resetSize="getClientResolution"
            :resolutionFromResizable="resolutionFromResizable"
            :maxHeight="resizableContainer && resizableContainer.offsetHeight"
          />
        </template>
        <DxToolbarItem
          location="center"
          template="clientResolutionPanel"
        />

        <!--Меню пользователя-->
        <DxToolbarItem
          v-if="$store.getters['session/userData']"
          widget="dxMenu"
          location="after"
          :options="userMenuOptions"
        />

        <!--Кнопка показа правой панели-->
        <template #rightDrawerButton>
          <DxButton
            :activeStateEnabled="false"
            :focusStateEnabled="false"
            icon="fas fa-align-right"
            :hint="rightDrawerOpened ? 'Скрыть правую панель' : 'Показать правую панель'"
            @click="toggleRightDrawerOpened"
            :class="[toggledButtonClass, { 'dx-item-selected': rightDrawerOpened }]"
          >
          </DxButton>
        </template>
        <DxToolbarItem
          location="after"
          template="rightDrawerButton"
        />

        <!--Кнопка выхода из конструктора-->
        <DxToolbarItem
          v-if="pageMode"
          widget="dxButton"
          location="after"
          :options="exitConstructorButtonOptions"
        />
      </DxToolbar>
    </div>
    <div
      v-if="showConstructor"
      class="constructor__left-drawer"
    >
      <DxDrawer
        :opened="navigationDrawerOpened"
        :shading="true"
        :closeOnOutsideClick="false"
        openedStateMode="overlap"
      >
        <template #template>
          <PageTree @create-page="openNewPagePopup" />
        </template>
        <DxDrawer :opened="leftDrawerOpened">
          <SplitGrid
            class="constructor__split-grid"
            direction="column"
            :gutterSize="3"
            :columnMinSizes="{ 0: 400, 4: 350 }"
            v-on:drag-start="splitGridDragStart"
          >
            <SplitGridArea
              sizeUnit="px"
              :sizeValue="400"
              :show="leftDrawerOpened"
              id="left_splitGridArea"
            >
              <div class="constructor__drawer-options">
                <OptionsAccordion ref="optionsAccordion" />
              </div>
            </SplitGridArea>
            <SplitGridGutter :render="leftDrawerOpened" />
            <SplitGridArea
              ref="centerSplitGridArea"
              id="center_splitGridArea"
              class="constructor__right-drawer"
            >
              <DxResizable
                ref="resizable"
                area=".constructor__right-drawer"
                :height="this.resolutionFromResizable.height"
                :width="this.resolutionFromResizable.width"
                class="resizable-content constructor__resizable-content"
                handles="right bottom left"
              >
                <div
                  class="constructor__content"
                  :id="url"
                >
                  <ConstructorComponent
                    v-if="$route.params.componentId"
                    :componentId="$route.params.componentId"
                  />
                  <Page
                    v-else
                    :url="$route.params.childUrl"
                    :device="resolutionDevice"
                  />
                </div>
              </DxResizable>
            </SplitGridArea>
            <SplitGridGutter :render="rightDrawerOpened" />
            <SplitGridArea
              sizeUnit="px"
              :sizeValue="350"
              :show="rightDrawerOpened"
              id="right_splitGridArea"
            >
              <ComponentsAccordion ref="ComponentsAccordion" />
            </SplitGridArea>
          </SplitGrid>
          <MobileBuildGrid
            class="constructor__mobile-build"
            v-if="showMobileBuildGrid"
          ></MobileBuildGrid>
        </DxDrawer>
      </DxDrawer>
    </div>
    <DxContextMenu
      :data-source="contextMenuItems"
      :width="200"
      :disabled="!selectMode"
      target=".components-tree__tree"
      @item-click="onContextMenuItemClick"
      @showing="onShowingContextMenu"
    />
    <DxContextMenu
      :data-source="contextMenuItems"
      :width="200"
      :disabled="!selectMode"
      target=".constructor__content"
      @item-click="onContextMenuItemClick"
      @showing="onShowingContextMenu"
    />
    <PopupMonacoEditor
      :visible="pageStylePopupVisible"
      :value="pageStyle"
      title="Стиль страницы"
      @hidden="pageStylePopupVisible = false"
      @shown="pageStylePopupVisible = true"
      @apply="applyPageStyle($event)"
    />
    <PopupNewPageForm
      :visible="createPagePopupVisible"
      @hidden="createPagePopupVisible = false"
      @shown="createPagePopupVisible = true"
    />
    <PopupByURLComponent
      :visible="popupTypeComponentVisible"
      :url="selectedPage.url"
      :title="selectedPage.titlePopup"
      @hidden="onHidden"
      @shown="togglePopupByURLComponentVisible(true, selectedPage)"
    />
  </div>
</template>

<script>
import './dxComponentsRegistration';
import constructorDragMIxin from './constructorDragMixin';
import { jsonRPC } from '@/api/api';
import notify from 'devextreme/ui/notify';
import DxButton from 'devextreme-vue/button';
import DxMenu from 'devextreme-vue/menu';
import DxDrawer from 'devextreme-vue/drawer';
import { DxItem as DxToolbarItem, DxToolbar } from 'devextreme-vue/toolbar';
import DxContextMenu from 'devextreme-vue/context-menu';
import { confirm } from 'devextreme/ui/dialog';
import ComponentsAccordion from './ComponentsAccordion';
import OptionsAccordion from './OptionsAccordion';
import PageTree from './PageTree';
import PopupMonacoEditor from './PopupMonacoEditor';
import PopupNewPageForm from './PopupNewPageForm';
import PopupByURLComponent from './PopupByURLComponent';
import MobileBuildGrid from './MobileBuildGrid';
import { COMPONENT_TYPES_FOR_TABLEAU, VALIDATED_COMPONENT_TYPES } from '../../utils/const';
import stringifyObject from 'stringify-object';
import { mapGetters } from 'vuex';
import { getOptions } from '@/store/modules/config/components/getters';
import { getLegendLayerIds } from '@/store/modules/config/components/Gis/getters';

import Page from '../Containers/Page';
import ConstructorComponent from '../Containers/ConstructorComponent';
import PageNotFound from '../PageNotFound/PageNotFound';
import { confirmDeleteComponents } from './Utility';

import itemsMixin from '../mixins/items';
import ResolutionPanel from '@/components/Constructor/ResolutionPanel';
import DxResizable from 'devextreme-vue/resizable';

import { SplitGrid, SplitGridArea, SplitGridGutter } from 'vue-split-grid';

export default {
  name: 'Constructor',
  mixins: [constructorDragMIxin, itemsMixin],
  components: {
    ResolutionPanel,
    DxButton,
    DxMenu, // eslint-disable-line
    DxToolbar,
    DxToolbarItem,
    DxContextMenu,
    ComponentsAccordion,
    OptionsAccordion,
    DxDrawer,
    PageTree,
    PopupMonacoEditor,
    PopupNewPageForm,
    Page,
    PageNotFound,
    ConstructorComponent,
    DxResizable,
    PopupByURLComponent,
    MobileBuildGrid,
    SplitGrid,
    SplitGridArea,
    SplitGridGutter
  },
  data() {
    return {
      dragObject: {},
      showMobileBuildGrid: false,

      //Костыль, чтобы получить переключаемую кнопку без использования buttonGroup
      //Менять dx-button-mode- и dx-button-<тип кнопки> при необходимости
      toggledButtonClass:
        'dx-button dx-button-normal dx-button-mode-contained dx-widget dx-button-has-icon dx-template-wrapper dx-item-content dx-toolbar-item-content dx-buttongroup-item dx-buttongroup-first-item dx-buttongroup-last-item',

      navigationDrawerOpened: false,
      leftDrawerOpened: true,
      rightDrawerOpened: false,
      pageStylePopupVisible: false,
      createPagePopupVisible: false,
      pageNotFoundStatus: false,
      selectedPage: {
        url: '',
        titlePopup: ''
      },
      pages: {
        componentTypes: {
          url: 'componenttypes',
          titlePopup: 'Типы компонентов'
        },
        eventType: {
          url: 'eventtype',
          titlePopup: 'Типы событий'
        },
        actionType: {
          url: 'actiontype',
          titlePopup: 'Типы действий'
        }
      },

      exitConstructorButtonOptions: {
        icon: 'close',
        hint: 'Выйти из режима конструирования',
        onClick: () => {
          this.exitConstructorButtonClick();
        }
      },
      contextMenuItems: [
        {
          text: 'Копировать',
          value: 'copy',
          disabled: false
        },
        {
          text: 'Вставить',
          value: 'paste',
          disabled: true
        },
        {
          text: 'Вставить полную копию',
          value: 'pasteFullComponentCopy',
          disabled: true
        },
        {
          text: 'Вставить по идентификаторам',
          value: 'pasteFullComponentsIds',
          disabled: true
        },
        {
          beginGroup: true,
          text: 'Удалить',
          value: 'deleteComponent',
          disabled: true
        },
        {
          beginGroup: true,
          text: 'Настройки',
          value: 'options',
          disabled: false
        },
        {
          text: 'Положение',
          value: 'cssStyle',
          disabled: false
        },
        {
          text: 'Стиль',
          value: 'optionsCssStyle',
          disabled: false
        },
        {
          text: 'Стиль подписи',
          value: 'labelCssStyle',
          disabled: false
        },
        {
          beginGroup: true,
          text: 'Проверка ввода',
          value: 'optionsValidator',
          disabled: false
        }
      ],
      resolutionFromResizable: {
        height: null,
        width: null
      },
      device: null,
      model: null,
      dragResizeContainerClicked: null,
      rightDrawerPanelWidth: 350
    };
  },
  computed: {
    ...mapGetters('session', {
      userData: 'userData',
      userIsAdmin: 'userIsAdmin'
    }),

    ...mapGetters('config', {
      getComponentOptions: getOptions,
      getLegendLayerIds
    }),

    showConstructor() {
      return !this.pageNotFoundStatus && !!this.userData;
    },

    pageMode() {
      return !!this.$route.params.childUrl;
    },

    url() {
      return this.$route.params.childUrl || this.$route.params.componentId;
    },

    selectedComponentId() {
      return this.$store.getters['config/selectedComponentId'];
    },

    selectedComponents() {
      return this.$store.getters['config/selectedComponents'];
    },

    selectedComponentConfig() {
      return this.$store.getters['config/getItem'](this.selectedComponentId);
    },

    items() {
      const pageStore = this.$store.state.config.pages[this.url] || {};
      return (pageStore.items || []).sort((itemConfig1, itemConfig2) => {
        return (itemConfig1.componentPos || 0) - (itemConfig2.componentPos || 0);
      });
    },

    selectMode() {
      return this.$store.getters['uiConstructor/selectMode'];
    },

    pageStyle() {
      const url = this.url;
      if (url && this.$store.state.config.pages[url]) {
        const cssStyle = this.$store.state.config.pages[url].cssStyle || {};
        return `(${stringifyObject(cssStyle)})`;
      }
      return '({})';
    },

    userMenuOptions() {
      return {
        items: [
          {
            name: 'userName',
            text: this.userData.userName,
            items: [
              {
                icon: 'fas fa-sign-out-alt',
                name: 'logout',
                text: 'Выход'
              }
            ]
          }
        ],
        submenuDirection: 'rightOrBottom',
        onItemClick: (eventData) => {
          if (eventData.itemData.name === 'logout') {
            this.askSaveChanges(this.logout);
          }
        }
      };
    },

    tableauButtonDisabled() {
      const componentType = this.selectedComponentConfig?.componentType;
      let enableTableau = this.selectedComponentId && (this.selectedComponentConfig?.dataSetComponentId || componentType == 'GisTablo');
      enableTableau = enableTableau && COMPONENT_TYPES_FOR_TABLEAU[componentType];
      return !enableTableau;
    },

    mobileButtonDisabled() {
      return false;
    },

    resolutionDevice() {
      return this.$store.getters['uiConstructor/resolutionDevice'] || 'desktop';
    },

    resizableContainer() {
      return this.$refs.resizable.$el.parentElement;
    },

    popupTypeComponentVisible() {
      return this.$store.getters['uiConstructor/getOption']('popupTypeComponentVisible');
    }
  },

  watch: {
    userData() {
      if (!!this.userData && !this.userIsAdmin) {
        this.pageNotFoundStatus = true;
      }
    }
  },

  updated() {
    if (this.resolutionFromResizable.height === null && this.resolutionFromResizable.width === null) {
      this.addIconToResizableHandles();
      this.getClientResolution();
    }
  },

  mounted() {
    if (!!this.userData && !this.userIsAdmin) {
      this.pageNotFoundStatus = true;

      return;
    }

    this.$store.dispatch('uiConstructor/loadComponentTypes');
    this.$store.dispatch('uiConstructor/loadTemplates');
    this.$store.dispatch('uiConstructor/loadTemplateFields');
    this.$store.dispatch('uiConstructor/loadEventActionParamTypes');
    this.$store.dispatch('uiConstructor/loadComponentTemplateGroups');
    this.$store.dispatch('uiConstructor/loadComponentTemplates');
    this.$store.dispatch('uiConstructor/loadAllPages');
    this.$store.dispatch('uiConstructor/loadConnections');
    this.$store.dispatch('uiConstructor/toggle', true);
  },

  beforeDestroy() {
    this.$store.dispatch('config/clearSelection');
    this.$store.dispatch('config/clearChanges');
  },

  methods: {
    toggleRightDrawerOpened() {
      this.rightDrawerOpened = !this.rightDrawerOpened;
      this.adjustCenterSplitGridAreaWidth();
      setTimeout(() => {
        this.$refs.ComponentsAccordion.updateDimensions();
      }, 500);
    },
    adjustCenterSplitGridAreaWidth() {
      const centerSplitGridArea = this.$refs.centerSplitGridArea;
      if (centerSplitGridArea) {
        if (this.rightDrawerOpened) {
          centerSplitGridArea.sizeValue = this.resolutionFromResizable.width - this.rightDrawerPanelWidth;

          this.$refs.resizable.$el.style.width = `${this.resolutionFromResizable.width - this.rightDrawerPanelWidth}px`;
        } else {
          console.log('tut');
          centerSplitGridArea.sizeValue = this.resolutionFromResizable.width;
          this.$refs.resizable.$el.style.width = `${this.resolutionFromResizable.width}px`;
        }
      }
    },
    setPageNotFoundStatus(value) {
      this.pageNotFoundStatus = value;
    },
    disabledComponentButton(url) {
      return document.URL.includes(url);
    },
    onHidden() {
      if (this.selectedPage.url === 'componenttypes') {
        this.$store.dispatch('uiConstructor/loadComponentTypes');
      }

      this.togglePopupByURLComponentVisible(false);
    },
    togglePopupByURLComponentVisible(
      value,
      selectedPage = {
        url: '',
        titlePopup: ''
      }
    ) {
      this.selectedPage = selectedPage;
      this.$store.commit('uiConstructor/setPopupTypeComponentVisible', { visible: value });
    },

    toggleSelectMode() {
      this.$store.dispatch('uiConstructor/toggleSelectMode', !this.selectMode);
    },
    exitConstructorButtonClick() {
      this.askSaveChanges(() => this.exitConstructor());
    },
    exitConstructor() {
      this.$store.dispatch('uiConstructor/toggle', false);
      this.$router.push(this.$route.query.pageFullUrl || `/${this.url}`);
    },
    askSaveChanges(handler) {
      if (this.$store.getters['config/changed']) {
        confirm('Закончить редактирование страницы?', 'Подтвердите действие').then((dialogResult) => {
          if (!dialogResult) {
            return;
          }
          handler();
        });
      } else {
        handler();
      }
    },
    logout() {
      this.$store.dispatch('session/logout');
    },
    applyPageStyle(cssStyleString) {
      let cssStyle = null;
      try {
        cssStyle = eval(cssStyleString);
      } catch (err) {
        cssStyle = null;
      }
      if (!cssStyle) {
        return;
      }

      this.$store.dispatch('config/savePageStyle', {
        cssStyle,
        url: this.url
      });
    },

    /**
     *  При показе контекстного меню часть пунктов может быть заблокирована
     */
    onShowingContextMenu(e) {
      //Проверяем было ли меню вызвано из drag resize
      this.dragResizeContainerClicked = e.jQEvent.originalEvent.originalEvent.data;

      this.contextMenuItems.map((item) => (item.disabled = !this.selectedComponentId));

      const pasteDisabled = (!this.pageMode && !this.selectedComponentId) || !localStorage.getItem('itemIdsClipboard');
      this.contextMenuItems[1].disabled = pasteDisabled;
      this.contextMenuItems[2].disabled = pasteDisabled;
      this.contextMenuItems[7].disabled = !this.selectedComponentConfig || !VALIDATED_COMPONENT_TYPES.includes(this.selectedComponentConfig.componentType);
    },

    onContextMenuItemClick(e) {
      if (!e.itemData.items) {
        switch (e.itemData.value) {
          case 'copy':
            this.copyComponent();
            break;
          case 'paste':
            this.dragResizeContainerClicked ? this.dragResizeContainerClicked.pasteComponents() : this.pasteComponent();
            break;
          case 'pasteFullComponentCopy':
            this.$store.commit('setLoading', true);
            this.dragResizeContainerClicked ? this.dragResizeContainerClicked.pasteFullComponentCopy() : this.pasteFullComponentCopy();
            break;
          case 'pasteFullComponentsIds':
            this.$store.commit('setLoading', true);
            this.dragResizeContainerClicked ? this.dragResizeContainerClicked.pasteFullComponentsIds() : this.pasteFullComponentsIds();
            break;
          case 'deleteComponent':
            this.deleteComponent();
            break;
          case 'labelCssStyle':
          case 'optionsValidator':
            this.$refs.optionsAccordion.popupVisble[e.itemData.value] = true;
            break;
          case 'options':
          case 'cssStyle':
          case 'optionsCssStyle':
            this.$refs.optionsAccordion.popupVisble.unitedForm = true;
            this.$refs.optionsAccordion.currentTub = e.itemData.value;
        }
      }
    },
    copyComponent() {
      const selectedComponents = this.$store.getters['config/selectedComponents'];
      this.$store.dispatch('config/copyComponentsToClipboard', { componentIds: selectedComponents });
    },

    pasteComponent() {
      let position = null;
      let parentId = null;
      let url = null;
      if (this.selectedComponentId) {
        parentId = this.selectedComponentId;
        position = this.$store.getters['config/lastItemPos'](parentId) + 100;
      } else {
        url = this.url;
        position = this.$store.getters['config/getPageLastChildPosition'](url) + 100;
      }

      this.$store.dispatch('config/pasteComponentsFromClipboard', {
        position,
        parentId,
        url,
        device: this.resolutionDevice
      });
    },

    pasteFullComponentCopy() {
      let parentComponentId = this.selectedComponentId ? this.selectedComponentId : null;
      let url = this.url;
      this.$store.dispatch('config/pasteFullComponentCopy', {
        parentComponentId,
        url,
        device: this.resolutionDevice
      });
    },

    /**Копирование компонентов по списку идентификаторов */
    pasteFullComponentsIds() {
      let parentComponentId = this.selectedComponentId ? this.selectedComponentId : null;
      let url = this.url;
      //Запрос списка идентификаторов
      let ids = window.prompt('Список идентификаторов', '');
      if (!ids) {
        this.$store.commit('setLoading', false);
        return;
      }
      ids = '[' + ids + ']';
      this.$store.dispatch('config/pasteFullComponentCopy', {
        parentComponentId,
        url,
        ids,
        device: this.resolutionDevice
      });
    },

    deleteComponent() {
      confirmDeleteComponents().then(() => {
        if (!this.selectedComponents[0]) {
          return;
        }

        //Не работает вызов действия через mapActions
        this.$store
          .dispatch('config/deleteComponents', {
            componentsId: this.selectedComponents
          })
          .then(() => {
            this.$emit('hidden');
          });
      });
    },

    openNewPagePopup() {
      this.createPagePopupVisible = true;
      this.navigationDrawerOpened = false;
    },
    // Событие от ResolutionPanel
    changeResolutionValue(eventData) {
      /*Добавляются переменные device и model, чтобы синхронизованно сбрасывать и менять
      положение компонента в момент смены модели или типа устройства*/
      if (eventData.device != this.device || eventData.model != this.model) {
        this.device = eventData.device;
        this.model = eventData.model;
        if (this.device === 'desktop') {
          //Компонент прижимается к левому краю
          this.$refs.resizable.$el.style.removeProperty('transform');
          this.$refs.resizable.$el.style.width = `100%`;
        } else {
          //Компонент центрируется
          this.$refs.resizable.$el.style.width = `${eventData.width}px`;

          // Для центровки использую просто flex и margin: 0 auto
          this.$refs.resizable.$el.style.margin = '0 auto';
        }
      }
      this.resolutionFromResizable.width = eventData.width;
      this.resolutionFromResizable.height = eventData.height;
    },
    // Получаем размеры блока с картой
    getClientResolution() {
      // const element = document.querySelector('.constructor__right-drawer');
      const element = document.querySelector('#center_splitGridArea');

      if (!element) return;

      this.resolutionFromResizable.width = Math.ceil(element.getBoundingClientRect().width);
      this.resolutionFromResizable.height = Math.ceil(element.getBoundingClientRect().height);
    },
    // Костыли для добавления иконок для ручек перетаскивания
    addIconToResizableHandles() {
      const resizableElement = document.querySelector('.constructor__resizable-content');

      if (!resizableElement) return;

      const resizableHandlesRight = resizableElement.querySelector('.dx-resizable-handle-right');
      const resizableHandlesLeft = resizableElement.querySelector('.dx-resizable-handle-left');
      const resizableHandlesBottom = resizableElement.querySelector('.dx-resizable-handle-bottom');
      resizableHandlesRight.innerHTML += '<i class="core core-menu"></i>';
      resizableHandlesLeft.innerHTML += '<i class="core core-menu"></i>';
      resizableHandlesBottom.innerHTML += '<i class="core core-menu"></i>';
    },

    // Смена размеров местами при повороте устройства
    rotateDevice() {
      [this.resolutionFromResizable.width, this.resolutionFromResizable.height] = [this.resolutionFromResizable.height, this.resolutionFromResizable.width];
    },

    tableauButtonClick() {
      const componentOptions = this.getComponentOptions(this.selectedComponentId);
      if (!componentOptions) {
        return;
      }

      const { componentType, dataSetComponentId } = this.selectedComponentConfig;
      const datasetOptions = this.getComponentOptions(dataSetComponentId);
      let component_id = this.selectedComponentId;
      //Для GisLayer настройки хранятся в params
      const elementtype_id = componentOptions.elementtype_id || componentOptions.params?.elementtype_id || COMPONENT_TYPES_FOR_TABLEAU[componentType];
      let cubeworkspace_id = componentOptions.cubeworkspace_id || componentOptions.params?.cubeworkspace_id;

      let url = `/tableau?component_id=${component_id}&dataset_component_id=${dataSetComponentId}&elementtype_id=${elementtype_id}`;
      if (!cubeworkspace_id) {
        cubeworkspace_id = datasetOptions.cubeworkspace_id;
      }
      if (cubeworkspace_id) {
        url += `&cubeworkspace_id=${cubeworkspace_id}`;
      }
      if (componentOptions.rotated) {
        url += '&rotated=true';
      }

      window.open(url, '_blank');
    },

    showImageList() {
      this.$store.commit('uiConstructor/setSelectImageVisible', { visible: true });
    },

    removeSession() {
      const params = {
        filter: {
          cache: true
        }
      };
      jsonRPC(process.env.VUE_APP_API_SERVICE, 'NULL.NULL.REMOVESESSION', params)
        .then((data) => {
          if (data && data.result) {
            notify({ message: data.result, width: 'auto' }, 'success', 3000);
          }
        })
        .catch((err) => {
          console.error('err:', err);
          notify({ message: err?.message || err, width: 'auto' }, 'error', 3000);
        });
    },

    splitGridDragStart() {
      if (this.device === 'desktop') {
        this.$refs.resizable.$el.style.width = `this.resolutionFromResizable.width}px`;
      }
    }
  }
};
</script>

<style lang="scss">
.constructor {
  height: 100%;
  user-select: none;
  position: relative;
  display: flex;
  flex-direction: column;

  &__mobile-build {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
  }

  &__toolbar-wrapper {
    padding: 5px;
    flex: 0 0 auto;
    --bg-color: #f7f7f7;
    background-color: var(--bg-color);

    .dx-toolbar {
      background-color: var(--bg-color);
    }
  }

  &__left-drawer {
    flex: 1 1 auto;
    min-height: 0;
  }

  &__right-drawer {
    transition: width 0.3s ease;
  }

  &__drawer-options {
    height: 100%;
    width: 100% !important;

    & .options-accordion {
      width: 100%;

      & > .dx-accordion {
        width: 100% !important;
      }
    }
  }

  &__resizable-content {
    > .dx-resizable-handle-left {
      width: 14px;
      cursor: pointer;
      background: #f7f7f7;
      box-shadow: 0 -1px 0 #dddddd;
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        background: #eaeaea;
        box-shadow: 1px 0 0 #dddddd;
      }

      &:active {
        background: #eaeaea;
        box-shadow: 1px 0 0 #dddddd;
      }
    }

    > .dx-resizable-handle-right {
      width: 14px;
      cursor: pointer;
      background: #f7f7f7;
      box-shadow: 0 -1px 0 #dddddd;
      display: flex;
      align-items: center;

      &:hover {
        background: #eaeaea;
        box-shadow: 1px 0 0 #dddddd;
      }

      &:active {
        background: #eaeaea;
        box-shadow: 1px 0 0 #dddddd;
      }
    }

    > .dx-resizable-handle-bottom {
      height: 14px;
      cursor: pointer;
      background: #f7f7f7;
      box-shadow: 0 -1px 0 #dddddd;
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        background: #eaeaea;
        box-shadow: 1px 0 0 #dddddd;
      }

      &:active {
        background: #eaeaea;
        box-shadow: 1px 0 0 #dddddd;
      }

      & > i {
        transform: rotate(90deg);
      }
    }
  }

  /*Фикс для нажимаемости disabled контролов в режиме конструктора */
  .dx-state-disabled {
    pointer-events: auto;
  }

  &__body {
    height: 100%;
    display: flex;
    flex-direction: column;
    min-height: 0;

    &-invisible-items {
      flex: 0 0 auto;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: flex-start;
      align-content: flex-start;
      padding: 0 5px 5px 0;
    }

    &-visible-items {
      flex: 1 1 auto;
      min-height: 0;
    }

    &-invisible-item {
      margin-left: 5px;
      margin-top: 5px;
    }
  }

  &__content {
    height: 100%;
    border-top: 1px solid #ddd;
    overflow: auto;
  }

  &__load-indicator.dx-loadindicator {
    height: 14px;
    width: 14px;
    vertical-align: middle;
    display: inline-block;
  }

  & .resizable-content {
    padding: 0 14px 14px 14px;
  }

  //Стили для handles DxResizable
  & .dx-resizable {
    max-width: 100%;
    max-height: 100%;
  }

  &__split-grid {
    height: 100%;

    & .components-accordion {
      width: 100% !important;
    }

    & .resizable-content {
      padding: 0 14px 14px 14px;
    }
  }
}

.monaco-editor-wrapper {
  height: 200px;
  border: 1px solid var(--base-border-color);
  border-radius: var(--base-border-radius);
  padding: 2px;
}

/*Это общий класс для подсветки выделения */
.constructor-element {
  &_selected {
    position: relative;

    &:before {
      display: block !important; /*Для адекватной работы при выборе элементов формы */
      content: '';
      position: absolute;
      /*background-color: rgba(51, 122, 183, 0.2);*/
      border: 1px solid #337ab7;
      z-index: 3;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  &_last-selected {
    &:before {
      border: 2px solid #337ab7;
    }
  }
}
</style>
