import notify from 'devextreme/ui/notify';

import { jsonRPC } from '@/api/api';

import { SET_ITEMS_AND_OPTIONS } from '@/store/modules/config/mutations';
import GisLayerFavoriteItem from '@/models/GisLayerFavoriteItem';
import { getParentGisId } from '@/utils/common';

import {
  loadChildComponents,
  clearSelection as clearLayerSelection,
  setVisible as setLayerVisible,
  setEnable as setLayerEnable,
  restoreVisible as gisLayerRestoreVisible,
  setFilterConfig as setLayerFilterConfig,
  refactorConfigFilter,
  loadValidationList as loadLayerValidationList,
  loadSelectedValidationList as loadLayerSelectedValidationList,
  saveFavoritePosition as saveLayerFavoritePosition
} from '../GisLayer/actions';

//Названия геттеров
import {
  getOptions as getGisOptions,
  getLegendLayerIds,
  getFullHalfVisibleLayers,
  getAllLgendGroupLayerIds,
  getLoadingImageDataPromise,
  getLoadingImagePromise,
  getNoParentLegendLayerIds,
  getEditingLayerComponentId,
  getFavoriteLayerIds,
  getRecentLayerIds,
  getFavoriteItemByLayerComponentId,
  getRights,
  getDisabledChecks,
  getGisMinimizeVisibleLayers
} from './getters';

//Названия мутаций
import {
  SET_ACTIVE_BASE_MAP_LAYER_COMPONENT_ID,
  SET_ACTIVE_BASE_TEXT_LAYER_COMPONENT_ID,
  SET_ACTIVE_TERRAIN_LAYER_COMPONENT_ID,
  INIT_OPTIONS,
  SET_LEGEND_LAYER_SEARCH_VALUE,
  SET_ACTIVE_LEGEND_LAYER_COMPONENT_ID,
  SET_LAST_LAYER_CLICK,
  SET_POPUP_MENU_LAYER_COMPONENT_ID,
  SET_ICON_TOOLTIP_LAYER_COMPONENT_ID,
  SET_DBL_CLICKED_LAYER_COMPONENT_ID,
  SET_EDITING_LAYER_COMPONENT_ID,
  SET_POPUP_MENU_LAYER_TARGET_ELEMENT,
  SET_LOADING_IMAGE_DATA_PROMISE,
  SET_LOADING_IMAGE_PROMISE,
  ADD_LEGEND_NO_PARENT_LAYER,
  REMOVE_LEGEND_NO_PARENT_LAYER,
  SET_REPORT_DRAW_LAYER,
  SET_ITEMS,
  SET_FAVORITE_LAYER_ITEMS,
  SET_RECENT_LAYER_ITEMS,
  ADD_FAVORITE_LAYER_ITEM,
  REMOVE_FAVORITE_LAYER_ITEM,
  ADD_RECENT_LAYER_ITEM,
  SET_SELECTED_MOUSE_TOOL,
  SET_VALIDATION_LIST,
  SET_SELECTED_VALIDATION_IDS_LIST,
  SET_SELECTED_VALIDATION_IDS_LIST_FROM_DATASET,
  SET_VISIBLE_LAYER_COMPONENTS,
  SET_ACTIVE_LAYER_LEGEND_ID
} from './mutations';

import {
  ADD_ITEM,
  SET_LOADING,
  SET_ITEMS as SET_LAYER_ITEMS,
  SET_CHILD_LAYERS_LOADED,
  SET_EXPANDED,
  SET_EDITING_IDS,
  SET_FILTER_ID,
  CLEAR_FEATURES,
  SET_EDIT_LAYER_OBJECT,
  CLEAR_SELECTION,
  SET_FAVORITE_POSITION
} from '../GisLayer/mutations';
import { SET_OPTION, SET_OPTION_BY_PATH } from '../mutations';
import {
  isCollapseOnInit,
  getOptions as getLayerOptions,
  getRights as getLayerRights,
  isVisible as isLayerVisible,
  isHalfVisible as isLayerHalfVisible,
  getFilterObject as getLayerFilterObject,
  getFavoritePosition as getLayerFavoritePosition,
  getLegendStyleItemIds
} from '../GisLayer/getters';
import { getLayerSavedOptions } from '../GisLayer/factory';

import TMP_LAYER_CONFIG from '@/components/Gis/TmpLayer/GisTmpLayerConfig';

//Названия действий
const PREFIX = 'Gis';
export const setActiveBaseMapLayerComponentId = `${PREFIX}/setActiveBaseMapLayerComponentId`;
export const setActiveBaseTextLayerComponentId = `${PREFIX}/setActiveBaseTextLayerComponentId`;
export const setActiveTerrainLayerComponentId = `${PREFIX}/setActiveTerrainLayerComponentId`;
export const setLegendLayerSearchValue = `${PREFIX}/setLegendLayerSearchValue`;
export const initOptions = `${PREFIX}/initOptions`;
export const setLegendLayersAllExpandOff = `${PREFIX}/setLegendLayersAllExpandOff`;
export const setGisLayerVisible = `${PREFIX}/setGisLayerVisible`;
export const setLegendLayersVisible = `${PREFIX}/setLegendLayersVisible`;
export const setLegendNoParentLayersVisible = `${PREFIX}/setLegendNoParentLayersVisible`;
export const restoreLayersVisible = `${PREFIX}/restoreLayersVisible`;
export const setActiveLegendLayerComponentId = `${PREFIX}/setActiveLegendLayerComponentId`;
export const setPopupMenuLayerComponent = `${PREFIX}/setPopupMenuLayerComponent`;
export const setIconTooltipLayerComponentId = `${PREFIX}/setIconTooltipLayerComponentId`;
export const setDblClickedLayerComponentId = `${PREFIX}/setDblClickedLayerComponentId`;
export const setEditingLayerComponentId = `${PREFIX}/setEditingLayerComponentId`;
export const loadImageData = `${PREFIX}/loadImageData`;
export const loadValidationList = `${PREFIX}/loadValidationList`;
export const loadSelectedValidationList = `${PREFIX}/loadSelectedValidationList`;
export const loadChildLayerComponents = `${PREFIX}/loadChildLayerComponents`;
export const loadGroupLayers = `${PREFIX}/loadGroupLayers`;
export const loadLayer = `${PREFIX}/loadLayer`;
export const loadLayerComponent = `${PREFIX}/loadLayerComponent`;
export const loadLayerComponents = `${PREFIX}/loadLayerComponents`;
export const loadVisibleLayers = `${PREFIX}/loadVisibleLayers`;
export const loadLocalStorageVisibleLayers = `${PREFIX}/loadLocalStorageVisibleLayers`;
export const loadLayerComponentsBySearch = `${PREFIX}/loadLayerComponentsBySearch`;
export const deleteLayerComponent = `${PREFIX}/deleteLayerComponent`;
export const removeLayerFromLegend = `${PREFIX}/removeLayerFromLegend`;
export const setReportDrawLayerComponent = `${PREFIX}/setReportDrawLayerComponent`;
export const deleteReportDrawLayerComponent = `${PREFIX}/deleteReportDrawLayerComponent`;
export const addNoParentLayerComponent = `${PREFIX}/addNoParentLayerComponent`;
export const deleteNoParentLayerComponent = `${PREFIX}/deleteNoParentLayerComponent`;
export const loadImage = `${PREFIX}/loadImage`;
export const clearSelection = `${PREFIX}/clearSelection`;
export const loadFilterPanelTabOptions = `${PREFIX}/loadFilterPanelTabOptions`;
export const loadFavoriteLayerItems = `${PREFIX}/loadFavoriteLayerItems`;
export const loadFavoriteLayerComponents = `${PREFIX}/loadFavoriteLayerComponents`;
export const loadRecentLayerItems = `${PREFIX}/loadRecentLayerItems`;
export const loadRecentLayerComponents = `${PREFIX}/loadRecentLayerComponents`;
export const toggleLayerFavorite = `${PREFIX}/toggleLayerFavorite`;
export const addLayerToRecent = `${PREFIX}/addLayerToRecent`;
export const setMapTool = `${PREFIX}/setMapTool`;
export const initLayersDefaultFilters = `${PREFIX}/initLayersDefaultFilters`;
export const setGisMinimizeVisibleLayers = `${PREFIX}/setGisMinimizeVisibleLayers`;
export const setActiveLayerLegendId = `${PREFIX}/setActiveLayerLegendId`;

const BASE_MAP_EMPTY_ID = 'NO_BASE_MAP';

export default {
  [setActiveBaseMapLayerComponentId]({ commit }, { gisComponentId, layerComponentId }) {
    commit(SET_ACTIVE_BASE_MAP_LAYER_COMPONENT_ID, {
      gisComponentId,
      layerComponentId
    });
  },

  [setActiveBaseTextLayerComponentId]({ commit }, { gisComponentId, layerComponentId }) {
    commit(SET_ACTIVE_BASE_TEXT_LAYER_COMPONENT_ID, {
      gisComponentId,
      layerComponentId
    });
  },

  [setActiveTerrainLayerComponentId]({ commit }, { gisComponentId, layerComponentId }) {
    commit(SET_ACTIVE_TERRAIN_LAYER_COMPONENT_ID, {
      gisComponentId,
      layerComponentId
    });
  },

  [initOptions]({ commit }, { componentId }) {
    commit(INIT_OPTIONS, componentId);
  },

  [setLegendLayerSearchValue]({ commit }, { componentId, legendLayerSearchValue }) {
    commit(SET_LEGEND_LAYER_SEARCH_VALUE, { componentId, legendLayerSearchValue });
  },

  [setLegendLayersAllExpandOff]({ commit, getters }, { componentId }) {
    getters[getAllLgendGroupLayerIds](componentId).forEach((layerComponentId) => {
      const { expanded } = getters[getLayerOptions](layerComponentId);

      if (expanded) {
        commit(SET_EXPANDED, {
          componentId: layerComponentId,
          expanded: false
        });
      }
    });
  },

  [setGisLayerVisible]({ dispatch, getters }, { componentId, layerComponentId, visible }) {
    if (!componentId || !layerComponentId) {
      return;
    }

    const isGisLegendLayer = getters[getLegendLayerIds](componentId).find((layerId) => layerId == layerComponentId);
    if (isGisLegendLayer) {
      dispatch(setLayerVisible, {
        componentId: layerComponentId.toString(),
        visible
      });
    } else {
      const layerOptions = getters[getLayerOptions](layerComponentId);
      if (layerOptions.baseMap) {
        dispatch(setActiveBaseMapLayerComponentId, {
          gisComponentId: componentId,
          layerComponentId: visible ? layerComponentId.toString() : BASE_MAP_EMPTY_ID
        });
      } else if (layerOptions.baseTextMap) {
        dispatch(setActiveBaseTextLayerComponentId, {
          gisComponentId: componentId,
          layerComponentId: visible ? layerComponentId.toString() : BASE_MAP_EMPTY_ID
        });
      }
    }
  },

  [setLegendLayersVisible]({ dispatch, getters }, { componentId, legendLayersVisible }) {
    getters[getLegendLayerIds](componentId).forEach((layerComponentId) => {
      const layerOptions = getters[getLayerOptions](layerComponentId);
      //Не нужно скрывать оформительский слой
      if (!layerOptions.tmpLayer) {
        dispatch(setLayerVisible, {
          componentId: layerComponentId,
          visible: legendLayersVisible
        });
      }
    });

    dispatch(setLegendNoParentLayersVisible, {
      componentId: componentId,
      visible: legendLayersVisible
    });
  },

  [setLegendNoParentLayersVisible]({ dispatch, getters }, { componentId, legendLayersVisible }) {
    getters[getNoParentLegendLayerIds](componentId).forEach((layerComponentId) => {
      dispatch(setLayerVisible, {
        componentId: layerComponentId,
        visible: legendLayersVisible
      });
    });
  },

  [restoreLayersVisible]({ dispatch, getters }, { componentId, layerComponentIds }) {
    getters[getLegendLayerIds](componentId).forEach((layerComponentId) => {
      dispatch(gisLayerRestoreVisible, {
        componentId: layerComponentId,
        layerComponentIds
      });
    });

    getters[getNoParentLegendLayerIds](componentId).forEach((layerComponentId) => {
      dispatch(gisLayerRestoreVisible, {
        componentId: layerComponentId,
        layerComponentIds
      });
    });
  },

  [setActiveLegendLayerComponentId]({ getters, commit, dispatch }, { gisComponentId, layerComponentId }) {
    const { activeLegendLayerComponentId, selectToolOnActivateLegendLayer, setVisibleOnActivateLegendLayer } = getters[getGisOptions](gisComponentId);
    const layerOptions = getters[getLayerOptions](layerComponentId);
    if (layerComponentId && layerComponentId !== activeLegendLayerComponentId && !layerOptions.group) {
      //Установка активного инструмента
      const newTool = selectToolOnActivateLegendLayer && selectToolOnActivateLegendLayer[layerOptions.type];
      if (newTool) {
        dispatch(setMapTool, { componentId: gisComponentId, name: newTool });
      }
      //Включение видимости слоя
      if (setVisibleOnActivateLegendLayer) {
        dispatch(setLayerVisible, {
          componentId: layerComponentId,
          visible: true
        });
      }
    }
    commit(SET_ACTIVE_LEGEND_LAYER_COMPONENT_ID, { gisComponentId, layerComponentId });
  },

  [setPopupMenuLayerComponent]({ commit, dispatch }, { gisComponentId, layerComponentId, targetElement }) {
    if (layerComponentId === null) {
      commit(SET_POPUP_MENU_LAYER_COMPONENT_ID, { gisComponentId, layerComponentId: null });
      commit(SET_POPUP_MENU_LAYER_TARGET_ELEMENT, { gisComponentId, targetElement: null });
      return Promise.resolve();
    }

    commit(SET_POPUP_MENU_LAYER_COMPONENT_ID, { gisComponentId, layerComponentId });
    commit(SET_POPUP_MENU_LAYER_TARGET_ELEMENT, { gisComponentId, targetElement });
  },

  [setIconTooltipLayerComponentId]({ commit }, { gisComponentId, layerComponentId }) {
    commit(SET_ICON_TOOLTIP_LAYER_COMPONENT_ID, { gisComponentId, layerComponentId });
  },

  [setDblClickedLayerComponentId]({ commit }, { gisComponentId, layerComponentId }) {
    commit(SET_DBL_CLICKED_LAYER_COMPONENT_ID, { gisComponentId, layerComponentId });
  },

  [setEditingLayerComponentId]({ commit, getters, dispatch }, { gisComponentId, layerComponentId }) {
    //Если редактирование запрещено настройками, ничего не делать
    const { editLayerObjects } = getters[getRights](gisComponentId);
    const { editObjects } = getters[getLayerRights](layerComponentId);

    if (!editObjects || !editLayerObjects) {
      return;
    }

    const { selectInActiveLayer, editingLayerComponentId } = getters[getGisOptions](gisComponentId);

    //Если редактирование слоя было выключено
    if (editingLayerComponentId && layerComponentId !== editingLayerComponentId) {
      //Очистка редактируемых объектов
      commit(SET_EDITING_IDS, {
        componentId: editingLayerComponentId,
        ids: []
      });

      // //Очистка geojson объектов (Временно отключаем для переключения на редактирование разных слоев без потери созданной геометрии)
      // commit(CLEAR_FEATURES, {
      //   layerComponentId: editingLayerComponentId
      // });

      // сбрасываем id редактируемого объекта
      commit(SET_EDIT_LAYER_OBJECT, {
        componentId: editingLayerComponentId,
        editingObjectId: null
      });

      // Очистка выделенных объектов
      commit(CLEAR_SELECTION, {
        layerComponentId: editingLayerComponentId
      });

      // Очистка несохраненных изменений (Временно отключаем для переключения на редактирование разных слоев без потери созданной геометрии)
      // commit(CLEAR_CHANGES, {
      //   layerComponentId: editingLayerComponentId
      // });
    }

    //Если было включено редактирование слоя
    if (layerComponentId && layerComponentId !== editingLayerComponentId) {
      // Загружаем общий список проверок для слоя
      dispatch(loadLayerValidationList, {
        componentId: layerComponentId
      });

      // Загружаем список выбранных проверок для слоя
      dispatch(loadLayerSelectedValidationList, {
        componentId: layerComponentId
      });

      //Если у слоя включается редактирование, то дополнительно включим его видимость
      dispatch(setLayerVisible, {
        componentId: layerComponentId,
        visible: true
      });

      //"Раскрытие замка" или выбор в легенде в зависимости от настроек ГИС
      //Это нужно чтобы при редактировании слоя, сразу иметь возможность выбрать его объекты
      if (selectInActiveLayer) {
        //Делаем слой активным в легенде
        dispatch(setActiveLegendLayerComponentId, {
          gisComponentId,
          layerComponentId
        });
      } else {
        //Раскрываем "замок"
        dispatch(setLayerEnable, {
          componentId: layerComponentId,
          enable: true
        });
      }
    }

    commit(SET_EDITING_LAYER_COMPONENT_ID, {
      gisComponentId,
      layerComponentId
    });
  },

  [loadImageData]({ commit, getters }, { gisComponentId, imageName }) {
    let promise = getters[getLoadingImageDataPromise](gisComponentId, imageName);

    if (!promise) {
      promise = jsonRPC('getData', {
        guid_link: 'C16095F3-4F9D-4523-B79D-6DCD808FB23F', //Костыльный гуид к которому привязан реестр изображений для карты
        _config_dataset: 'PAGES.DSPAGE',
        name: imageName
      }).then((data) => {
        return data[0] || null;
      });

      commit(SET_LOADING_IMAGE_DATA_PROMISE, {
        gisComponentId,
        imageName,
        promise
      });
    }

    return promise;
  },

  [loadValidationList]({ commit, getters }, { gisComponentId }) {
    const disabledChecks = getters[getDisabledChecks](gisComponentId);

    if (disabledChecks) {
      return;
    }

    return jsonRPC('getData', {
      _config_dataset: 'CONFIG.DSVALIDATION'
    }).then((data) => {
      commit(SET_VALIDATION_LIST, {
        gisComponentId,
        validationList: data
      });
    });
  },

  [loadSelectedValidationList]({ commit, getters }, { gisComponentId }) {
    const disabledChecks = getters[getDisabledChecks](gisComponentId);

    if (disabledChecks) {
      return;
    }

    return jsonRPC('getData', {
      _config_dataset: 'CONFIG.DSVALIDATION_COMPONENT',
      component_id: gisComponentId
    }).then((data) => {
      const validationIds = data.map((item) => {
        return {
          validationId: item.validation_id,
          validationType: item.validation_type
        };
      });
      const validationСonnections = data.map((item) => {
        return {
          connectionId: item.id,
          validationId: item.validation_id,
          validationType: item.validation_type
        };
      });

      commit(SET_SELECTED_VALIDATION_IDS_LIST, {
        gisComponentId,
        selectedValidationsIdsList: validationIds
      });

      commit(SET_SELECTED_VALIDATION_IDS_LIST_FROM_DATASET, {
        gisComponentId,
        selectedValidationsIdsList: validationСonnections
      });
    });
  },

  [loadImage]({ commit, getters }, { gisComponentId, imageName, imageUrl }) {
    let promise = getters[getLoadingImagePromise](gisComponentId, imageName);

    if (!promise) {
      promise = new Promise((resolve) => {
        const image = new Image();
        image.onload = () => {
          resolve(image);
        };
        image.src = `${imageUrl}&_config_dataset=PAGES.DSPAGE`;
      });

      commit(SET_LOADING_IMAGE_PROMISE, {
        gisComponentId,
        imageName,
        promise
      });
    }

    return promise;
  },

  [clearSelection]({ dispatch, getters }, { componentId, exceptLayerComponentIds }) {
    const { selectableLayerIds } = getters[getGisOptions](componentId);
    getters[getLegendLayerIds](componentId)
      .filter((layerComponentId) => !selectableLayerIds || selectableLayerIds.includes(layerComponentId))
      .forEach((layerComponentId) => {
        dispatch(clearLayerSelection, {
          layerComponentId,
          exceptLayerComponentIds
        });
      });
  },

  [setReportDrawLayerComponent]({ getters, dispatch, commit }, { gisComponentId, layerConfig }) {
    commit(SET_ITEMS_AND_OPTIONS, { components: [layerConfig], keepExisting: false });
    dispatch('emitLayerInitializedEvent', { components: [layerConfig] });
    //Установка видимости стиля
    const options = getters[getLayerOptions](layerConfig.componentId);
    options.legendStyleItems.default.visible = true;
    commit(SET_REPORT_DRAW_LAYER, { gisComponentId, layerComponentId: layerConfig.componentId });
  },

  [deleteReportDrawLayerComponent](context, { gisComponentId, layerComponentId }) {
    clearLayerReferences(context, { gisComponentId, layerComponentId });
    context.commit(SET_REPORT_DRAW_LAYER, { gisComponentId, layerComponentId: null });
  },

  [addNoParentLayerComponent]({ commit, dispatch, getters }, { gisComponentId, layerConfig }) {
    commit(SET_ITEMS_AND_OPTIONS, { components: { [layerConfig.componentId]: layerConfig }, keepExisting: true });
    dispatch('emitLayerInitializedEvent', { components: { [layerConfig.componentId]: layerConfig } });
    commit(SET_OPTION, {
      componentId: layerConfig.componentId,
      optionName: 'parentGisId',
      optionValue: gisComponentId
    });
    commit(ADD_LEGEND_NO_PARENT_LAYER, { gisComponentId, layerComponentId: layerConfig.componentId });
    const gisItems = getters[getGisOptions](gisComponentId).items || [];
    gisItems.push(layerConfig.componentId);
    commit(SET_ITEMS, { gisComponentId, items: gisItems });
  },

  [deleteNoParentLayerComponent](context, { gisComponentId, layerComponentId }) {
    context.commit(REMOVE_LEGEND_NO_PARENT_LAYER, { gisComponentId, layerComponentId });
    clearLayerReferences(context, { gisComponentId, layerComponentId });
  },

  /**Действие удаления слоя из панели легенд без сохранения. Используется для скрытия слоя в табло*/
  [removeLayerFromLegend]({ getters, commit }, { componentId, layerComponentId }) {
    const gisItems = getters[getGisOptions](componentId).items || [];
    const index = gisItems.findIndex((item) => item == layerComponentId);
    if (index >= 0) {
      gisItems.splice(index, 1);
    }
    commit(SET_ITEMS, { gisComponentId: componentId, items: gisItems });
    //clearLayerReferences(context, { componentId, layerComponentId });
  },

  [deleteLayerComponent](context, { gisComponentId, layerComponentId }) {
    //TODO: индикация загрузки
    return context
      .dispatch('deleteComponents', {
        componentsId: [layerComponentId]
      })
      .then(() => {
        clearLayerReferences(context, { gisComponentId, layerComponentId });
      });
  },

  /**Действие добавления существующего слоя, втч из другой карты. Изменения не сохраняются.*/
  [loadLayer]({ dispatch }, { componentId, layerComponentId }) {
    return dispatch(loadLayerComponent, {
      gisComponentId: componentId,
      layerComponentId: layerComponentId,
      addNoParentLayerToLegend: true
    });
  },

  [loadChildLayerComponents](context, { componentId, gisComponentId, loadComponentId = null, forceHideLayers = false }) {
    let constructorActive = context.rootState.uiConstructor.active;

    return jsonRPC('getData', {
      _config_component_id: loadComponentId || componentId,
      _config_dataset: 'CONFIG.DSCOMPONENT_LAYER',
      is_constructor: constructorActive
    }).then((data) => {
      if (data && data[0] && data[0].r_json) {
        let { components, eventAction } = data[0].r_json;

        //Исключение компонентов-слоев, которые надо исключить
        //TODO: возможно это костыль
        const { excludeLayerComponentIds, linkedGisComponentId } = context.getters[getGisOptions](gisComponentId);
        if (Array.isArray(excludeLayerComponentIds)) {
          excludeLayerComponentIds.forEach((componentId) => {
            delete components[componentId];
          });
        }

        if (forceHideLayers) {
          for (let componentId in components) {
            components[componentId].options.edit = false;
            components[componentId].options.visible = false;
          }
        }

        if (linkedGisComponentId) {
          for (let componentId in components) {
            if (components[componentId].parentComponentId === linkedGisComponentId) {
              components[componentId].parentComponentId = gisComponentId;
            }

            if (components[componentId].parentGisId) {
              components[componentId].parentGisId = gisComponentId;
            }
          }
        }

        context.commit(SET_ITEMS_AND_OPTIONS, { components: components || {}, keepExisting: true });
        context.commit('setEventAction', { eventAction: eventAction || [] });
        context.dispatch(loadGroupLayers, { components: components || {} });

        Object.keys(components || {}).forEach((childComponentId) => {
          //Надо убрать идентификатор компонента слоя из специального массива,
          //в котором храняться слои, которые в скрытых папках
          context.commit(REMOVE_LEGEND_NO_PARENT_LAYER, {
            gisComponentId: components[childComponentId].parentGisId,
            layerComponentId: childComponentId
          });

          context.commit(ADD_ITEM, {
            layerComponentId: componentId,
            childComponentId
          });

          context.commit(SET_OPTION, {
            componentId: childComponentId,
            optionName: 'parentGisId',
            optionValue: gisComponentId
          });

          context.commit(SET_CHILD_LAYERS_LOADED, {
            componentId: componentId,
            value: true
          });

          //Если мы загрузили родителя одного из "отвязаных" слоев, то надо его привязать
          const noParentLegendLayerIds = context.getters[getNoParentLegendLayerIds](gisComponentId);
          noParentLegendLayerIds
            .filter((noParentLayerComponentId) => {
              return context.state.items[noParentLayerComponentId].parentComponentId === childComponentId;
            })
            .forEach((noParentLayerComponentId) => {
              context.commit(REMOVE_LEGEND_NO_PARENT_LAYER, {
                gisComponentId,
                layerComponentId: noParentLayerComponentId
              });

              context.commit(ADD_ITEM, {
                layerComponentId: childComponentId,
                childComponentId: noParentLayerComponentId
              });
            });

          const { edit } = context.getters[getLayerOptions](childComponentId);
          if (edit) {
            //Включение редактируемости слоя
            context.dispatch(setEditingLayerComponentId, { gisComponentId, layerComponentId: childComponentId });
          }
        });
        //Установка фильтров по умолчанию
        context.dispatch(initLayersDefaultFilters, { layerComponentsIds: Object.keys(components || {}) });
        context.dispatch('emitLayerInitializedEvent', { components });
      }
    });
  },

  /** загрузка слоев из раскрытых папок */
  [loadGroupLayers](context, { components }) {
    for (const component of Object.values(components)) {
      if (component.componentType === 'GisGroupLayer') {
        if (component.parentGisId == 0) {
          component.parentGisId = getParentGisId(component.parentComponentId, this.getters);
        }
        const collapsedOnInit = context.getters[isCollapseOnInit](component.componentId);
        if (collapsedOnInit) {
          context.commit(SET_EXPANDED, {
            componentId: component.componentId,
            expanded: false,
            saveToLocalStorage: false
          });
        }
        //Загрузить опции из vuex (данные могли быть обновлены из локального хранилища)
        const componentOptions = context.getters[getLayerOptions](component.componentId);
        if (!collapsedOnInit && (componentOptions.expanded || componentOptions.visible)) {
          context.dispatch(loadChildLayerComponents, {
            componentId: component.componentId,
            gisComponentId: component.parentGisId
          });
        }
      }
    }
  },

  [loadLayerComponent](context, { gisComponentId, layerComponentId, addNoParentLayerToLegend = false, rootGisComponentId = null }) {
    if (typeof layerComponentId !== 'string') {
      //все идентификаторы хранятся в виде строк
      layerComponentId = layerComponentId.toString();
    }

    //Если компонент слой уже был загружен, то не грузим его повторно
    if (context.state.items[layerComponentId]) {
      return Promise.resolve(layerComponentId);
    }

    let constructorActive = context.rootState.uiConstructor.active;
    const param = {
      _config_component_id: layerComponentId,
      _config_dataset: 'CONFIG.DSCONFIG',
      is_constructor: constructorActive
    };

    return jsonRPC(process.env.VUE_APP_API_SERVICE, 'CONFIG.DSCONFIG.GETPAGE', param)
      .then((data) => {
        const components = data.components || {};
        const eventAction = data.eventAction || [];

        //Добавление слоя в массив слоев, которые лежат в папках, которые еще не раскрыли,
        // но коотрые нужно показать на карте
        const componentConfig = components[layerComponentId];
        const parentGisId = rootGisComponentId || componentConfig.parentGisId;
        const parentComponentId = components[layerComponentId].parentComponentId;
        if (parentGisId !== gisComponentId) {
          console.error(layerComponentId + ' не принадлежит карте ' + gisComponentId);
        }
        if (!addNoParentLayerToLegend && (!parentGisId || !context.state.items[parentGisId] || parentGisId !== gisComponentId)) {
          return;
        }

        context.commit(SET_ITEMS_AND_OPTIONS, { components });
        context.commit('setEventAction', { eventAction: eventAction || [] });
        context.dispatch('emitLayerInitializedEvent', { components });

        if (!context.state.items[parentComponentId]) {
          if (addNoParentLayerToLegend) {
            context.commit(SET_OPTION, {
              componentId: layerComponentId,
              optionName: 'parentGisId',
              optionValue: gisComponentId
            });
            //Добавление слоев в корень легенды, у которых нет загруженного родителя
            //Используется при рендеринге карты только с указанными слоями
            context.commit(ADD_ITEM, {
              layerComponentId: gisComponentId,
              childComponentId: layerComponentId
            });
          } else {
            //Добавление слоя в массив слоев, которые лежат в папках, которые еще не раскрыли,
            // но коотрые нужно показать на карте
            context.commit(ADD_LEGEND_NO_PARENT_LAYER, {
              gisComponentId,
              layerComponentId
            });
          }
        } else {
          context.commit(ADD_ITEM, {
            layerComponentId: parentComponentId,
            childComponentId: layerComponentId
          });
        }

        //Установка массива items
        context.commit(SET_LAYER_ITEMS, {
          layerComponentId,
          components,
          items: components[layerComponentId].options.items || []
        });

        //Установка Promise о том, что дочерние компоненты загружены
        context.commit(SET_OPTION, {
          componentId: layerComponentId,
          optionName: 'loadChildComponentsPromise',
          optionValue: Promise.resolve(data)
        });
        return layerComponentId;
      })
      .catch((err) => {
        window.console.log(layerComponentId, ':', err);
      });
  },

  //Действие, загружающее слои
  [loadLayerComponents]({ dispatch }, { componentId, layerComponentIds }) {
    if (!layerComponentIds?.length) {
      return null;
    }

    const promises = layerComponentIds.map((layerComponentId) => {
      return dispatch(loadLayerComponent, {
        gisComponentId: componentId,
        layerComponentId
      });
    });

    return Promise.all(promises).then((result) => {
      return result;
    });
  },

  [loadVisibleLayers](context, { gisComponentId }) {
    const { linkedGisComponentId } = context.getters[getGisOptions](gisComponentId);
    let constructorActive = context.rootState.uiConstructor.active;

    return jsonRPC('getData', {
      _config_component_id: linkedGisComponentId || gisComponentId,
      _config_dataset: 'CONFIG.DSCOMPONENT_LAYER',
      is_visible: true,
      is_constructor: constructorActive
    })
      .then((data) => {
        initLoadedLayerComponents(context, data, gisComponentId, linkedGisComponentId);
      })
      .catch((err) => {
        window.console.log(err);
      });
  },

  [loadLocalStorageVisibleLayers](context, { gisComponentId }) {
    //TODO: переделать на один запрос
    Object.keys(localStorage)
      .filter((key) => {
        return key.indexOf(`${gisComponentId}_`) === 0;
      })
      .forEach((localStorageKey) => {
        const underscoreIndex = localStorageKey.indexOf('_');
        const layerComponentId = localStorageKey.substring(underscoreIndex + 1);
        const { visible } = getLayerSavedOptions(layerComponentId, gisComponentId, context.getters[getGisOptions](gisComponentId));
        const options = context.getters[getLayerOptions](layerComponentId);

        //Слои с данными на клиенте не загружаем
        if (visible && !['geojson', 'wfs'].includes(options.type)) {
          context.dispatch(loadLayerComponent, {
            gisComponentId,
            layerComponentId
          });
        }
      });
  },

  [loadLayerComponentsBySearch](context, { gisComponentId, searchValue }) {
    const { linkedGisComponentId } = context.getters[getGisOptions](gisComponentId);

    if (!searchValue) {
      return;
    }

    let constructorActive = context.rootState.uiConstructor.active;
    return jsonRPC('getData', {
      _config_component_id: linkedGisComponentId || gisComponentId,
      _config_dataset: 'CONFIG.DSCOMPONENT_LAYER',
      search: searchValue,
      is_constructor: constructorActive
    }).then((data) => {
      if (!data || !data[0] || !data[0].r_json) {
        //TODO: обработка?
        return;
      }
      const responseData = data[0].r_json;
      const components = getClearComponentResponse(context, responseData);
      const eventAction = getClearEventActionResponse(context, responseData);

      //Исключение компонентов-слоев, которые надо исключить
      //TODO: возможно это костыль
      const { excludeLayerComponentIds } = context.getters[getGisOptions](gisComponentId);
      if (Array.isArray(excludeLayerComponentIds)) {
        excludeLayerComponentIds.forEach((componentId) => {
          delete components[componentId];
        });
      }

      if (linkedGisComponentId) {
        for (let componentId in components) {
          if (components[componentId].parentComponentId === linkedGisComponentId) {
            components[componentId].parentComponentId = gisComponentId;
          }

          if (components[componentId].parentGisId) {
            components[componentId].parentGisId = gisComponentId;
          }
        }
      }

      context.commit(SET_ITEMS_AND_OPTIONS, { components });
      context.commit('setEventAction', { eventAction: eventAction || [] });
      context.dispatch('emitLayerInitializedEvent', { components });

      //Добавление подгруженных слоев к родителям при необходимости
      for (let layerComponentId in components) {
        const parentComponentId = components[layerComponentId].parentComponentId;

        if (parentComponentId !== gisComponentId) {
          context.commit(ADD_ITEM, {
            layerComponentId: parentComponentId,
            childComponentId: layerComponentId
          });
        }
      }
      //Установка фильтров по умолчанию
      context.dispatch(initLayersDefaultFilters, { layerComponentsIds: Object.keys(components || {}) });
    });
  },

  [loadFilterPanelTabOptions](context, { gisComponentId }) {
    const { fullLayerList, linkedGisComponentId } = context.getters[getGisOptions](gisComponentId);

    if (fullLayerList.length) {
      return;
    }

    return jsonRPC('getData', {
      component_id: linkedGisComponentId || gisComponentId,
      _config_dataset: 'CONFIG.PRGETLAYERSFILTER'
    }).then((result) => {
      const data = (result || []).map((item) => {
        return {
          layerComponentId: item.id.toString(),
          dataSetName: item.connection_dataset_code,
          caption: item.caption
        };
      });
      context.dispatch('setComponentOption', {
        componentId: gisComponentId,
        optionName: 'fullLayerList',
        optionValue: data
      });
      return data;
    });
  },

  [loadFavoriteLayerItems]({ commit, dispatch, getters }, { gisComponentId }) {
    const gisOptions = getters[getGisOptions](gisComponentId);
    if (gisOptions.loadFavoriteLayerItemsPromise) {
      return gisOptions.loadFavoriteLayerItemsPromise;
    } else {
      gisOptions.loadFavoriteLayerItemsPromise = jsonRPC('getData', {
        component_id: gisComponentId,
        _config_dataset: 'CONFIG.DSLAYERFAVORITE',
        is_favorite: true,
        orderby: [{ selector: 'createdate', desc: true }]
      }).then((result) => {
        if (Array.isArray(result)) {
          let pos = 1000;
          result.forEach((favorite) => {
            const posOld = favorite.pos;
            if (posOld === 0 || posOld === 100) {
              //Первоначальная установка положения
              favorite.pos = pos;
              favorite.edited = true;
              pos += 1000;
            }
            favorite.component_id = favorite.component_id.toString();
          });
          commit(SET_FAVORITE_LAYER_ITEMS, {
            gisComponentId,
            favoriteLayerItems: result.map((item) => {
              return new GisLayerFavoriteItem(item);
            })
          });
          result.forEach((favorite) => {
            if (favorite.edited) {
              //Сохраняем на сервер и в слой
              dispatch(saveLayerFavoritePosition, { layerComponentId: favorite.component_id, gisComponentId, value: favorite.pos });
            } else {
              //Сохраняем в слой локально
              commit(SET_FAVORITE_POSITION, { layerComponentId: favorite.component_id, value: favorite.pos });
            }
          });
        }
        return result;
      });
      return gisOptions.loadFavoriteLayerItemsPromise;
    }
  },

  [loadRecentLayerItems]({ commit, getters }, { gisComponentId }) {
    const { maxRecentLayers } = getters[getGisOptions](gisComponentId);

    return jsonRPC('getData', {
      component_id: gisComponentId,
      _config_dataset: 'CONFIG.DSLAYERFAVORITE',
      is_favorite: false,
      limit: maxRecentLayers,
      orderby: [{ selector: 'createdate', desc: true }]
    }).then((result) => {
      if (Array.isArray(result)) {
        commit(SET_RECENT_LAYER_ITEMS, {
          gisComponentId,
          recentLayerItems: result.map((item) => {
            return new GisLayerFavoriteItem(item);
          })
        });
      }
      return result;
    });
  },

  [toggleLayerFavorite]({ commit, getters }, { gisComponentId, layerComponentId, isFavorite }) {
    const component_id = layerComponentId.toString();
    const favoriteLayerIds = getters[getFavoriteLayerIds](gisComponentId);
    const index = favoriteLayerIds.indexOf(component_id);

    let operation = null;
    if (isFavorite == undefined) {
      //Переключение
      if (index < 0) {
        operation = 'insertData';
      } else {
        operation = 'deleteData';
      }
    } else {
      if (isFavorite === true && index > 0) {
        return;
      } else if (isFavorite === false && index < 0) {
        return;
      } else {
        operation = isFavorite ? 'insertData' : 'deleteData';
      }
    }

    switch (operation) {
      case 'insertData':
        return jsonRPC(operation, {
          component_id,
          giscomponent_id: gisComponentId,
          _config_dataset: 'CONFIG.DSLAYERFAVORITE',
          is_favorite: true
        }).then((result) => {
          result.forEach((dataItem) => {
            commit(ADD_FAVORITE_LAYER_ITEM, {
              gisComponentId,
              gisLayerFavoriteItem: new GisLayerFavoriteItem(dataItem)
            });
          });

          return result;
        });
      case 'deleteData': {
        const favoriteItem = getters[getFavoriteItemByLayerComponentId](gisComponentId, component_id);
        if (favoriteItem) {
          return jsonRPC(operation, {
            id: favoriteItem.id, //ВНИМАНИЕ! Удаление идет через идентификатор (id) связи. Так решил БЭК
            _config_dataset: 'CONFIG.DSLAYERFAVORITE'
          }).then(() => {
            commit(REMOVE_FAVORITE_LAYER_ITEM, { gisComponentId, layerComponentId: component_id });
          });
        }
      }
    }

    return Promise.resolve();
  },

  [loadFavoriteLayerComponents](context, { gisComponentId }) {
    const { linkedGisComponentId } = context.getters[getGisOptions](gisComponentId);
    let constructorActive = context.rootState.uiConstructor.active;

    const gisOptions = context.getters[getGisOptions](gisComponentId);
    if (gisOptions.loadFavoriteLayersPromise) {
      return gisOptions.loadFavoriteLayersPromise;
    } else {
      gisOptions.loadFavoriteLayersPromise = jsonRPC('getData', {
        _config_component_id: linkedGisComponentId || gisComponentId,
        _config_dataset: 'CONFIG.DSCOMPONENT_LAYER',
        is_visible: true,
        is_favorite: true,
        is_constructor: constructorActive
      })
        .then((data) => {
          initLoadedLayerComponents(context, data, gisComponentId, linkedGisComponentId);
          //Загрузка содержимого раскрытых папок
          const components = data[0]?.r_json?.components;
          context.dispatch(loadGroupLayers, { components: components || {} });
          return data;
        })
        .catch((err) => {
          window.console.log(err);
        });
      return gisOptions.loadFavoriteLayersPromise;
    }
  },

  [loadRecentLayerComponents](context, { gisComponentId }) {
    const { linkedGisComponentId, maxRecentLayers } = context.getters[getGisOptions](gisComponentId);
    let constructorActive = context.rootState.uiConstructor.active;

    return jsonRPC('getData', {
      _config_component_id: linkedGisComponentId || gisComponentId,
      _config_dataset: 'CONFIG.DSCOMPONENT_LAYER',
      is_visible: true,
      is_favorite: false,
      limit: maxRecentLayers,
      is_constructor: constructorActive
    })
      .then((data) => {
        initLoadedLayerComponents(context, data, gisComponentId, linkedGisComponentId);
      })
      .catch((err) => {
        window.console.log(err);
      });
  },

  [addLayerToRecent]({ commit, getters }, { gisComponentId, layerComponentId }) {
    if (!getters[getGisOptions](gisComponentId).showFavoritesPanel) {
      return;
    }
    if (layerComponentId === TMP_LAYER_CONFIG.componentId) {
      return;
    }

    const layerIds = getters[getRecentLayerIds](gisComponentId);
    const index = layerIds.indexOf(layerComponentId);
    let operation = null;
    if (index < 0) {
      operation = 'insertData';
    }

    if (operation) {
      const params = {
        component_id: layerComponentId,
        giscomponent_id: gisComponentId,
        _config_dataset: 'CONFIG.DSLAYERFAVORITE',
        is_favorite: false
      };
      return jsonRPC(operation, params).then((data) => {
        data.forEach((dataItem) => {
          commit(ADD_RECENT_LAYER_ITEM, {
            gisComponentId,
            gisLayerRecentItem: new GisLayerFavoriteItem(dataItem)
          });
        });
      });
    } else {
      return Promise.resolve();
    }
  },

  [setMapTool]({ commit }, { componentId, name }) {
    commit(SET_SELECTED_MOUSE_TOOL, {
      gisComponentId: componentId,
      buttonName: name
    });
  },

  /**
   * Действие инициализирует и применяет фильтры по умолчанию для слоев
   * @param {*} context
   * @param {*} param1
   * @returns
   */
  [initLayersDefaultFilters](context, { layerComponentsIds = [], ignoreVisibility = false }) {
    const filteredLayerComponentsIds = layerComponentsIds.filter((layerComponentId) => {
      const { defaultFilterConfig } = context.getters[getLayerOptions](layerComponentId);
      const visible = ignoreVisibility ? true : context.getters[isLayerVisible](layerComponentId);
      if (!defaultFilterConfig) {
        return false;
      }
      //Если фильтр по умолчанию существует и
      //Его еще не загрузили и
      //Слой видим
      return defaultFilterConfig.configFilterId && !defaultFilterConfig.loading && !defaultFilterConfig.loaded && visible;
    });
    if (!filteredLayerComponentsIds.length) {
      return;
    }
    setLayersOption(context, {
      layerComponentIds: filteredLayerComponentsIds,
      optionName: 'defaultFilterConfig.loading',
      optionValue: true
    });
    filteredLayerComponentsIds.forEach((layerComponentId) => {
      context.commit(SET_LOADING, { componentId: layerComponentId, value: true });
    });

    //Загрузить настройки фильтров по умолчанию
    return loadLayersFilterConfigs(context, { layerComponentsIds: filteredLayerComponentsIds })
      .then(({ data, layersByFilterMap }) => {
        const dataSetNamesByLayerMap = {};
        (data || []).forEach((filterData) => {
          layersByFilterMap[filterData.id].forEach((layerComponentId) => {
            dataSetNamesByLayerMap[layerComponentId] = filterData.dataset;
          });
        });

        //Применить фильтры по умолчанию к слоям с помощью выполнения insertData в BASE.DSFILTER и установки полученных filterId
        let promises = [];
        filteredLayerComponentsIds.forEach((layerComponentId) => {
          const { filterFieldValues } = context.getters[getLayerOptions](layerComponentId);
          promises = Object.keys(filterFieldValues).map((filterLayerComponentId) => {
            //Для получения датасетов фильтров загружаем дочерние компоненты связанных с фильтром слоев
            return context.dispatch(loadChildComponents, { layerComponentId: filterLayerComponentId });
          });
        });
        if (promises.length) {
          return Promise.all(promises).then(() => {
            return insertLayersFilterBase(context, { layerComponentsIds: filteredLayerComponentsIds, dataSetNamesByLayerMap });
          });
        }
        return insertLayersFilterBase(context, { layerComponentsIds: filteredLayerComponentsIds, dataSetNamesByLayerMap });
      })
      .finally(() => {
        setLayersOption(context, {
          layerComponentIds: filteredLayerComponentsIds,
          optionName: 'defaultFilterConfig.loading',
          optionValue: false
        });
        filteredLayerComponentsIds.forEach((layerComponentId) => {
          context.commit(SET_LOADING, { componentId: layerComponentId, value: false });
        });
      });
  },

  [setGisMinimizeVisibleLayers]({ commit, getters }, { gisComponentId, layerComponentId, visible }) {
    const gisMinimizeVisibleLayerIds = getters[getGisMinimizeVisibleLayers](gisComponentId) || [];

    if (!visible && gisMinimizeVisibleLayerIds.includes(layerComponentId)) {
      gisMinimizeVisibleLayerIds.splice(gisMinimizeVisibleLayerIds.indexOf(layerComponentId), 1);
    }

    if (visible) {
      const layerComponentIdPos = gisMinimizeVisibleLayerIds.indexOf(layerComponentId);

      if (layerComponentIdPos > -1) {
        if (layerComponentIdPos !== gisMinimizeVisibleLayerIds.length - 1) {
          gisMinimizeVisibleLayerIds.splice(layerComponentIdPos, 1);
          gisMinimizeVisibleLayerIds.push(layerComponentId);
        }
      } else {
        gisMinimizeVisibleLayerIds.push(layerComponentId);
      }
    }

    commit(SET_VISIBLE_LAYER_COMPONENTS, {
      gisComponentId,
      gisMinimizeVisibleLayerIds
    });
  },

  [setActiveLayerLegendId]({ commit, getters }, { gisComponentId, layerComponentId }) {
    const activeLayerLegendId = getters[getGisOptions](gisComponentId).activeLayerLegendId;
    let value = null;
    if (layerComponentId != activeLayerLegendId) {
      const legendStyleItemIds = getters[getLegendStyleItemIds](layerComponentId);
      value = legendStyleItemIds?.length > 1 ? layerComponentId : null;
    }

    if (value) {
      commit(SET_OPTION, {
        componentId: layerComponentId,
        optionName: 'styleListExpanded',
        optionValue: true
      });
      commit(SET_OPTION, {
        componentId: gisComponentId,
        optionName: 'activeFullLayersCatalog',
        optionValue: false
      });
    }
    commit(SET_ACTIVE_LAYER_LEGEND_ID, { gisComponentId, value });
  }
};

function clearLayerReferences(context, { gisComponentId, layerComponentId }) {
  const gisOptions = context.getters[getGisOptions](gisComponentId);
  const gisItems = gisOptions.items || [];
  const pos = gisItems.indexOf(layerComponentId);
  if (pos >= 0) {
    gisItems.splice(pos, 1);
  }
  if (gisOptions.activeLegendLayerComponentId === layerComponentId) {
    context.commit(SET_ACTIVE_LEGEND_LAYER_COMPONENT_ID, { gisComponentId, layerComponentId: null });
  }
  if (gisOptions.editingLayerComponentId === layerComponentId) {
    context.commit(SET_EDITING_LAYER_COMPONENT_ID, { gisComponentId, layerComponentId: null });
  }
}

function getClearComponentResponse(context, responseData) {
  //Используем только те компоненты, которые не были загружены ранее
  let components = {};
  for (let componentId in responseData.components || {}) {
    if (!context.state.items[componentId]) {
      components[componentId] = responseData.components[componentId];
    }
  }
  return components;
}

function getClearEventActionResponse(context, responseData) {
  return (responseData.eventAction || []).filter((eventAction) => {
    return !context.state.items[eventAction.eventComponentId];
  });
}

function initLoadedLayerComponents(context, data, gisComponentId, linkedGisComponentId) {
  if (!data || !data[0] || !data[0].r_json) {
    //TODO: обработка?
    return;
  }
  const responseData = data[0].r_json;
  const components = getClearComponentResponse(context, responseData);
  const eventAction = getClearEventActionResponse(context, responseData);

  //Исключение компонентов-слоев, которые надо исключить
  //TODO: возможно это костыль
  const { excludeLayerComponentIds } = context.getters[getGisOptions](gisComponentId);
  if (Array.isArray(excludeLayerComponentIds)) {
    excludeLayerComponentIds.forEach((componentId) => {
      delete components[componentId];
    });
  }

  if (linkedGisComponentId) {
    for (let componentId in components) {
      if (components[componentId].parentComponentId === linkedGisComponentId) {
        components[componentId].parentComponentId = gisComponentId;
      }

      if (components[componentId].parentGisId) {
        components[componentId].parentGisId = gisComponentId;
      }
    }
  }

  context.commit(SET_ITEMS_AND_OPTIONS, { components });
  context.commit('setEventAction', { eventAction: eventAction || [] });
  context.dispatch('emitLayerInitializedEvent', { components });

  for (let layerComponentId in components) {
    const parentComponentId = components[layerComponentId].parentComponentId;

    if (!context.state.items[parentComponentId]) {
      //Добавление слоя в массив слоев, которые лежат в папках, которые еще не раскрыли,
      // но коотрые нужно показать на карте
      context.commit(ADD_LEGEND_NO_PARENT_LAYER, {
        gisComponentId,
        layerComponentId
      });
    } else {
      context.commit(ADD_ITEM, {
        layerComponentId: parentComponentId,
        childComponentId: layerComponentId
      });
    }

    //Установка массива items
    context.commit(SET_LAYER_ITEMS, {
      layerComponentId,
      components,
      items: components[layerComponentId].options.items || []
    });

    //Установка фильтров по умолчанию
    context.dispatch(initLayersDefaultFilters, { layerComponentsIds: Object.keys(components || {}) });
  }
}

function loadLayersFilterConfigs(context, { layerComponentsIds }) {
  const layersByFilterMap = {};
  layerComponentsIds.forEach((layerComponentId) => {
    const { defaultFilterConfig } = context.getters[getLayerOptions](layerComponentId);
    //мапинг массива слоев по ид фильтра
    if (layersByFilterMap[defaultFilterConfig.configFilterId]) {
      layersByFilterMap[defaultFilterConfig.configFilterId].push(layerComponentId);
    } else {
      layersByFilterMap[defaultFilterConfig.configFilterId] = [layerComponentId];
    }
  });
  const where = Object.keys(layersByFilterMap).reduce((expression, filterId, index) => {
    if (index) {
      expression.push('or');
    }
    expression.push(['id', '=', filterId]);
    return expression;
  }, []);

  const params = {
    where: where.length === 1 ? where[0] : where,
    _config_dataset: 'CONFIG.DSFILTER'
  };
  //запрос списка необходимых фильтров
  return jsonRPC('getData', params).then((data) => {
    if (!data || !data.length) {
      return {};
    }
    data.forEach((filterData) => {
      //Установка конфига фильтра каждому слою
      const filterBody = JSON.parse(filterData.body.replace(/\\"/g, '"'));
      const filterId = filterData.id;
      layersByFilterMap[filterId].forEach((layerComponentId) => {
        const filterConfig = refactorConfigFilter(filterBody, layerComponentId);
        context.dispatch(setLayerFilterConfig, { layerComponentId, filterConfig });
      });
    });
    return { data, layersByFilterMap };
  });
}

/**
 * Фунция выполняет insertData в BASE.DSFILTER
 * @param {*} context
 * @param {*} param1
 * @returns
 */
function insertLayersFilterBase(context, { layerComponentsIds, dataSetNamesByLayerMap }) {
  //Формируем массив параметров для пакетного запроса
  const requestParams = [];
  layerComponentsIds.forEach((layerComponentId) => {
    let { filter, where, complexFilter, filterListIds, statisticFilterKeys, constructorFilter, params } = context.getters[getLayerFilterObject](layerComponentId);
    if (!filter && !where && !complexFilter && !filterListIds && !constructorFilter && !statisticFilterKeys) {
      return;
    }

    // добавляем значение FilterBuilder, если он был задано
    if (complexFilter) {
      if (where) {
        where = [where, 'and', complexFilter];
      } else {
        where = complexFilter;
      }
    }

    if (constructorFilter) {
      if (where) {
        where = [where, 'and', constructorFilter];
      } else {
        where = constructorFilter;
      }
    }

    //Значения statisticFilterKeys передаются через действие setStatisticFilterValue, вызванное на событиях диграмм в окне статистики слоя
    //При возникновении ошибок см. соответствие данных диаграмм значениям в действиях
    if (statisticFilterKeys) {
      const delimiters = ['<>', '='];
      let statisticFilter = [];
      statisticFilterKeys.forEach((item) => {
        if (statisticFilter.length > 0) {
          statisticFilter.push('or');
        }
        const key = Object.keys(item)[0];
        const value = Object.values(item)[0];
        if (key === 'fields') {
          const delimiterIndex = value.indexOf(delimiters[0]) > -1 ? 0 : 1;
          const valueArray = value.split(delimiters[delimiterIndex]);
          statisticFilter.push([valueArray[0], delimiters[delimiterIndex], valueArray[1] === 'null' ? null : valueArray[1]]);
        } else {
          statisticFilter.push(['the_geom', 'geom_detail', value]);
        }
      });

      if (where) {
        where = [where, 'and', statisticFilter];
      } else {
        where = statisticFilter;
      }
    }

    requestParams.push({
      _config_dataset: 'BASE.DSFILTER',
      dataset: dataSetNamesByLayerMap[layerComponentId],
      body: {
        layerComponentId,
        filter,
        where,
        complexFilter,
        list_ids: filterListIds,
        _static_filter: statisticFilterKeys,
        constructorFilter,
        params
      }
    });
  });

  if (!requestParams.length) {
    return Promise.resolve(null);
  }

  //Выполнение insertData в BASE.DSFILTER
  return jsonRPC('insertData', requestParams).then((data) => {
    if (!data) {
      return null;
    }

    //Установка полученных filterId для слоёв
    data.forEach((filter) => {
      //Установим флаг загрузки, что фильтр по умолчанию загружен
      context.commit(SET_OPTION_BY_PATH, {
        componentId: filter.body.layerComponentId,
        optionName: 'defaultFilterConfig.loaded',
        optionValue: true
      });

      //Установка filterId
      context.commit(SET_FILTER_ID, {
        layerComponentId: filter.body.layerComponentId,
        filterId: filter.id
      });

      //Очистка geojson объектов
      context.commit(CLEAR_FEATURES, { layerComponentId: filter.body.layerComponentId });
    });

    //Возвращаем массив идентификаторов фильров
    return data.map((filter) => {
      return filter.id;
    });
  });
}

function setLayersOption(context, { layerComponentIds, optionName, optionValue }) {
  layerComponentIds.forEach((layerComponentId) => {
    context.commit(SET_OPTION_BY_PATH, {
      componentId: layerComponentId,
      optionName,
      optionValue
    });
  });
}

/**Проверка число видимых слоев не превышает максимального значения и вывод сообщения */
export function verifyVisibleLayersCount(context, gisСomponentId, layerComponentId, silently) {
  const visible = context.getters[isLayerVisible](layerComponentId) || context.getters[isLayerHalfVisible](layerComponentId);
  const { maxVisibleLayersCount } = context.getters[getGisOptions](gisСomponentId);
  const visibleLayers = context.getters[getFullHalfVisibleLayers](gisСomponentId);
  if (!visible && visibleLayers.length >= maxVisibleLayersCount) {
    if (silently !== true) {
      notify(
        { message: `Число видимых слоев превышает предел ${maxVisibleLayersCount}. Скройте один или несколько ненужных слоев.`, width: 'auto' },
        'error',
        6000
      );
    }
    return false;
  } else {
    return true;
  }
}
