/**
 * Настройки GisLayer конструктора
 */
import { DEFAULT_EPSG_BBOX } from '@/utils/const';
export class GisLayerConstructorOptions {
  /**
   * Ссылка
   */
  url = null;

  /**
   * WMS ссылка (без поддоменов, чтобы запросить графику)
   */
  wmsUrl = null;

  /**
   * Настройки слоев mapbox (стили)
   */
  mapboxLayers = [];

  /**
   * {Object[]} Настройки слоев mapbox для подсветки выделения
   */
  mapboxSelectionLayers = null;

  /**
   * {Object[]} Настройки слоев mapbox для подсветки наведения
   */
  mapboxHoverLayers = null;

  /**
   * Максимальный зум для которого идет запрос тайлов
   */
  maxNativeZoom = 22;

  /**
   * Минимальный зум для которого идет запрос тайлов
   */
  minNativeZoom = 0;

  /**
   * Строка с перечислением слоев (WMS, ArcGis)
   */
  layers = '';

  /**Ссылки на copyright слоя */
  copyright = null;

  /**
   * {Number[]}
   */
  bounds = null;

  /**
   * Постоянное значение where фильтра из настроек слоя
   */
  where = null;

  /**
   * Предустановленный фильтр
   * {
   *    configFilterId: null - id фильтра из базы CONFIG
   *    filterName: null - имя фильтра
   *    loading: false - флаг процесса загрузки
   *    loaded:false - флаг окончания загрузки
   * }
   */
  defaultFilterConfig = null;

  /**
   * {String} Ключевое поле для сохранения загруженных объектов в словарь
   */
  keyField = 'id';

  /**
   * Признак выделения объекта после добавления
   */
  selectObjectAfterAdd = true;

  /**
   * Прятать выбранные объекты в основном слое. Нужно чтобы полностью менять стиль выделения
   */
  excludeSelectedFeatures = false;

  /**
   * WMS стили
   */
  styles = '';

  /**
   * WMS формат
   */
  format = 'image/png';

  /**
   * WMS прозрачность
   */
  transparent = false;

  /**
   * WMS версия
   */
  version = '1.1.1';

  /**
   * WMS размер тайла
   */
  tileSize = 256;

  /**
   * Поддомены
   */
  subdomains = '';

  /**
   * ArcGis, размер тайла
   */
  size = 256;

  /**
   * ArcGis, точек на дюйм
   */
  dpi = 50;

  /**
   * Bbox ArcGis, WMS
   */
  bbox = DEFAULT_EPSG_BBOX;

  /**
   * Формат координат wcs
   */
  coordinates = null;

  /**
   * Строка с перечислением слоев (wfs)
   */
  typeName = '';

  /**
   * Максимальное количество объектов в слое (wfs)
   */
  maxFeatures = 500;

  /**
   * Флаг базовой карты
   */
  baseMap = false;

  /**
   * Флаг базовой карты подписей
   */
  baseTextMap = false;

  /**
   * Флаг базовой карты рельефа
   */
  terrainMap = false;

  /**
   * Флаг отображения в легенде
   */
  visibleInLegend = true;

  /**
   * Vector, mapbox source layer
   */
  sourceLayer = null;

  /**
   * Доступные типы геометрий ['Polygon', 'Line', 'Point']
   * в старых слоях geomTypes, в новых geomtypes
   */
  geomTypes = null;

  geomtypes = null;

  /**
   * Удалять объект целиком или только геометрию, по-умолчанию true
   */
  deleteObject = true;

  /**
   * Показывать кнопки "Калькулятор" и "Геокодирование по кадастру"
   */
  visibleCalcAndGeocodeButtons = true;

  /**
   * Костыльное свойство, которое блокирует обновление сущности после обновления карточки
   */
  blockPropertiesUpdate = false;

  /**
   * Показывать карточку при вставке объекта
   */
  showCardOnPaste = true;

  /**
   * Открывать карточку при левом клике
   */
  openCardOnLeftClick = false;

  /**
   * Непрозрачность растрового слоя
   */
  rasterOpacity = 1;

  /**
   * Включен выбор (замок)
   */
  enable = false;

  /**
   * Включено отображение (глаз)
   */
  visible = false;

  /**
   * Включено частичное отображение
   */
  halfVisible = false;

  /**
   * Показывать карточку при добавлении объекта
   */
  showInsertCard = false;

  /**
   * Тип слоя
   */
  type = null;

  /**Подтип слоя, модифицирующий поведение слоя*/
  subType = null;

  /**
   * Флаг о том, что wms слой создан из растра
   */
  raster = false;

  /**Сервер-источник растрового слоя (portal | geoserver) */
  server = null;

  /**
   * Показывать кнопку инструмента "Доступность в точке"
   */
  showPointAvailabilityButton = null;

  /**
   * Можно ли перемещать слои потомки, но не сам данный слой, по-умолчанию наследуется от родителя
   */
  allowDragChildLayers = null;

  /**
   * Свойства. Устарело?
   */
  properties = null;

  /**
   * Параметры фильтрации векторного слоя, которые надо отправлять во все запросы (плитки, подсчет количества, таблица)
   * Примеры: здания, сооружения из ДОМ.РФ, Псков по видам уборки
   */
  params = null;

  /**
   * Значения по умолчанию
   */
  defaultValues = null;

  /**
   * Слой с одним объектом
   */
  singleFeature = false;

  /**
   * TODO: дополнить описание
   */
  singleFeatureEventOnly = false;

  /**
   * Настройки mapbox source
   */
  sourceOptions = null;

  /**
   * Свойства надо брать из отображаемого объекта а не с помощью датасета
   */
  identifyGeometryProperties = false;

  /**
   * Настройки полей для FilterBuilder в форме стиля
   */
  styleFilterFields = [];

  /**
   * Текст(заголовок) слоя
   */
  text = '';

  /**
   * Токен для авторизации в сервисе
   */
  token = null;

  /**
   * Название стиля Mapbox, для предустановленых стилей
   */
  styleName = null;

  /**
   * Url иконки
   */
  iconUrl = null;

  /**
   * Css-класс для иконки
   */
  iconCss = null;

  /**
   * Css-класс для иконки открытой папки
   */
  iconExpandedCss = null;

  /**
   * Цвет css иконки
   */
  iconColor = null;

  /**Разрешено ли включать видимость нескольких слоев в группе одновременно
   * (если false, то при включении видимости слоя в такой папке, видимость предыдущего отключается)
   * TODO: Актуально?
   */
  multiple = true;

  /**
   * Состояние раскрытия для папки
   */
  expanded = false;

  /**
   * Возможность раскрытия для папки
   */
  expandEnabled = true;

  /**
   * Состояние раскрытия списка правил стилей
   */
  styleListExpanded = false;

  /**
   * Сворачивать при инициализации (GisGroupLayer)
   */
  collapsedOnInit = null;

  // Типи для получения объекта идентификации ПКК
  pkkIdentificationTypes = null;
  // Слои для настройки урл в выделении ПКК
  pkkSelectIdentificationLayers = null;
  //Тип для получения точек в слое ПКК для дальнейшего получения объектов ПКК разных подслоев
  pkkSelectIdentificationLayersType = null;
  // УРЛ для выделения ПКК
  pkkSelectIdentificationUrl = null;

  /**
   * Название параметра в который надо передать дату из таймлайна при запросе плиток
   */
  timelineDateField = null;

  /**
   * Режим работы временной шкалы: year, month, date, hour, 20minutes
   */
  timelineMode = 'date';

  /** Указывает, в какой параметр при запросе плиток передавать поле временной шкалы: params, where, all
   * костыльный параметр, поскольку разные слои работают с разными параметрами
   */
  timeLineFieldSendMode = 'where';

  /**Не передавать при отключении временной шкалы */
  timeLineNoTransmit = false;

  scheme = 'xyz';

  /**
   * Настройка видимости дополнительных инструментов слоя
   * {
      table: false,
      options: false,
      moveToLayer: false,
      export: false,
      buffer: false,
      filter: false,
      clone: false,
      search: false,
      calcInterpolation: false
    * }
    */
  panelButtons = {
    basic: true //Костыль, пустой объект lua превращает в пустой массив
  };

  permanentTooltips = {
    enabled: false
  };

  /**
   * Права на слой
   * {
      editLayer: true,
      showCard: true,
      loadData: true,
      editFields: true,
      editAccess: true,
      editOptions: true,
      showCalc: rootGetters['session/userIsAdmin'],
      editObjects: true,
      editAttributes: true
    }
   */
  rights = {
    basic: true //Костыль, пустой объект lua превращает в пустой массив
  };

  /**
   * Долгота и широта расположения модели
   */
  modelOrigin = null;

  /**
   * Высота расположения модели
   */
  modelAltitude = 0;

  /**
   * Матрица поворота модели
   */
  modelRotate = [0, 0, 0];

  /**
   * Всплывающая подсказка слоя
   */
  tooltipExpression = '';

  /**
   * Позиция всплывающей подсказки слоя
   * mouse, center, none
   */
  tooltipPosition = 'none';

  /**Настройка дополнительных параметрв в УРЛ слоя */
  formParams = null;

  /**Показывать форму параметров в панели легенд */
  showFormParams = true;

  /**Атрибуты для возвращения в векторныз плитках */
  tmsFields = [];

  /**
   * Проверять самопересечения в добавляемых и редактируемых полигонах
   */
  validatePolygonKinks = false;

  /**
   * Использовать прокси при запросах (только для растровых тайлов)
   */
  useProxy = false;

  //Возможность включения даты из таймлайна в фильтр слоя
  timeLineDateInFilter = false;

  /**
   * Разрешить выбор нескольких типов геометрии для слоя
   */
  enableMultipleGeomTypes = true;
  /**
   * Для внешних сервисов в легенду для перехода по ссылке
   * */
  externalUrl = null;
  /**
   * Для внешних сервисов в легенду для перехода по ссылке, название ссылки
   * */
  externalUrlCaption = null;

  /**Видимость подписей */
  textVisible = false;

  /**
   * Списки каналов с опциями для настроек растра
   * */
  bandsRasterList = {};

  /**
   * Стили для портального растрового слоя
   * */
  rasterStyle = [];

  /**Анимация слоя*/
  animation = {};

  /**
   * id элемента стиля в тайловых слоях
   * */
  legendStyleItemId = null;
  /**
   * {GeoJson} Геометрия привязки растра сохранненное последнее состояние, что бы после перезагрузки можно было продолжить работу
   * */
  bindingTileGeoJsonBackup = null;
  /**
   * Параметр для запроса к векторной плитке
   * */
  is_cache = false;

  /**
   * Имя/номер шаблона для проверки на соответствие файла импорта данных в слой
   */
  layerImportTemplate = null;

  //Ширина картинки для легенды в растровом слое
  legendImageWidth = '150px';
  //Высота картинки для легенды в растровом слое
  legendImageHeight = '150px';
  //Адрес картинки для легенды в растровом слое
  legendImageUrl = '';
  //Тип кадастрового объекта для поиска по кн
  cadastrType = null;

  constructor(options) {
    Object.keys(options).forEach((optionName) => {
      if (this.hasOwnProperty(optionName)) {
        this[optionName] = options[optionName];
      }
    });
  }
}
