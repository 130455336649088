import mapboxgl from 'mapbox-gl';
import { deepCopy, deepMerge } from '@/utils/deep';
import portalConfig from '@/portalConfig';
import { DEFAULT_EPSG_BBOX } from '@/utils/const';

import router from '/src/router';

import * as lightMapbox from '@/components/Gis/MapboxStyles/light.json';
import * as darkMapbox from '@/components/Gis/MapboxStyles/dark.json';
import * as streetsMapbox from '@/components/Gis/MapboxStyles/streets.json';
import * as outdoorsMapbox from '@/components/Gis/MapboxStyles/outdoors.json';
import * as imageryMapbox from '@/components/Gis/MapboxStyles/imagery.json';

import {
  getOptions as getLayerOptions,
  getMapboxSourceConfig,
  getMapboxSourceId,
  getMapboxLayerConfigs,
  getSelectionMapboxLayerConfigs,
  getMapboxHoverLayerConfigs,
  isGroup,
  isVisible,
  isHalfVisible,
  isExpanded,
  getChildLayerComponentIds,
  getMapboxSourceHash,
  getLayerLayersOption,
  getEnableLayers as gisLayerGetEnableLayers,
  getVisibleLayers as gisLayerGetVisibleLayers,
  getGltfLayers as gisLayerGetGltfLayers,
  getAllSelected as gisLayerGetAllSelected,
  getAllFilters as gisLayerGetAllFilters,
  getAllFeatures as getLayerAllFeatures,
  getDataSetName as getLayerDataSetName,
  getSelectedIds,
  getChanges,
  getAllLayerIds,
  isTimelineEnable,
  isLegendItemVisible,
  isLegendChildItemsVisible,
  getKinksInEditingFeatures,
  getFavoritePosition as getLayerFavoritePosition
} from '../GisLayer/getters';
import TMP_LAYER_CONFIG from '@/components/Gis/TmpLayer/GisTmpLayerConfig';

//Названия геттеров
const PREFIX = 'Gis';
export const getOptions = `${PREFIX}/getOptions`;
export const getLayerConfigs = `${PREFIX}/getLayerConfigs`;
export const getTimelines = `${PREFIX}/getTimelines`;
export const getBaseMapLayerIds = `${PREFIX}/getBaseMapLayerIds`;
export const getBaseTextMapLayerIds = `${PREFIX}/getBaseTextMapLayerIds`;
export const getTerrainMapLayerIds = `${PREFIX}/getTerrainMapLayerIds`;
export const getLegendPanelLayerIds = `${PREFIX}/getLegendPanelLayerIds`;
export const getLegendLayerIds = `${PREFIX}/getLegendLayerIds`;
export const getLegendGroupLayerIds = `${PREFIX}/getLegendGroupLayerIds`;
export const getAllLgendGroupLayerIds = `${PREFIX}/getAllLgendGroupLayerIds`;
export const getBaseMapListItems = `${PREFIX}/getBaseMapListItems`;
export const getBaseTextMapListItems = `${PREFIX}/getBaseTextMapListItems`;
export const getTerrainMapListItems = `${PREFIX}/getTerrainMapListItems`;
export const getMapboxSourcesAndLayers = `${PREFIX}/getMapboxSourcesAndLayers`;
export const getMapboxLayerStyle = `${PREFIX}/getMapboxLayerStyle`;
export const getMapboxStyle = `${PREFIX}/getMapboxStyle`;
export const getActiveBaseMapLayerComponentId = `${PREFIX}/getActiveBaseMapLayerComponentId`;
export const getActiveBaseTextMapLayerComponentId = `${PREFIX}/getActiveBaseTextMapLayerComponentId`;
export const getActiveTerrainMapLayerComponentId = `${PREFIX}/getActiveTerrainMapLayerComponentId`;
export const getLegendLayerSearchValue = `${PREFIX}/getLegendLayerSearchValue`;
export const isLegendLayersAllVisible = `${PREFIX}/isLegendLayersAllVisible`;
export const isLegendLayersSomeVisible = `${PREFIX}/isLegendLayersSomeVisible`;
export const isLegendLayersSomeExpanded = `${PREFIX}/isLegendLayersSomeExpanded`;
export const isAllowDropBetweenGroup = `${PREFIX}/isAllowDropBetweenGroup`;
export const isFavoriteLayer = `${PREFIX}/isFavoriteLayer`;
export const isRecentLayer = `${PREFIX}/isRecentLayer`;
export const isMatchLegendLayersFilter = `${PREFIX}/isMatchLegendLayersFilter`;
export const getLegendLayersFilter = `${PREFIX}/getLegendLayersFilter`;
export const getActiveLegendLayerComponentId = `${PREFIX}/getActiveLegendLayerComponentId`;
export const getPopupMenuLayerComponentId = `${PREFIX}/getPopupMenuLayerComponentId`;
export const getIconTooltipLayerComponentId = `${PREFIX}/getIconTooltipLayerComponentId`;
export const getDisabledChecks = `${PREFIX}/getDisabledChecks`;
export const getValidationList = `${PREFIX}/getValidationList`;
export const getSelectedValidationIdsList = `${PREFIX}/getSelectedValidationIdsList`;
export const getSelectedValidationIdsListFromDataset = `${PREFIX}/getSelectedValidationIdsListFromDataset`;
export const getDblClickedLayerComponentId = `${PREFIX}/getDblClickedLayerComponentId`;
export const getStyleFormLayerComponentId = `${PREFIX}/getStyleFormLayerComponentId`;
export const getTooltipFormLayerComponentId = `${PREFIX}/getTooltipFormLayerComponentId`;
export const getSignLayerComponentId = `${PREFIX}/getSignLayerComponentId`;
export const getBindingTileLayerId = `${PREFIX}/getBindingTileLayerId`;
export const getEnableLayers = `${PREFIX}/getEnableLayers`;
export const getGisMinimizeVisibleLayers = `${PREFIX}/getGisMinimizeVisibleLayers`;
export const getLastVisibleLayer = `${PREFIX}/getLastVisibleLayer`;
export const getVisibleLayers = `${PREFIX}/getVisibleLayers`;
export const getFullHalfVisibleLayers = `${PREFIX}/getFullHalfVisibleLayers`;
export const getLayersWithSelections = `${PREFIX}/getLayersWithSelections`;
export const getTablePanelLayerComponentId = `${PREFIX}/getTablePanelLayerComponentId`;
export const getStatsPanelLayerComponentId = `${PREFIX}/getStatsPanelLayerComponentId`;
export const getFilterPanelLayerComponentId = `${PREFIX}/getFilterPanelLayerComponentId`;
export const getLoadingImageDataPromise = `${PREFIX}/getLoadingImageDataPromise`;
export const getShowFavoritesPanel = `${PREFIX}/getShowFavoritesPanel`;
export const getLoadingImagePromise = `${PREFIX}/getLoadingImagePromise`;
export const getAllSelected = `${PREFIX}/getAllSelected`;
export const getAllFilters = `${PREFIX}/getAllFilters`;
export const getBounds = `${PREFIX}/getBounds`;
export const getNoParentLegendLayerIds = `${PREFIX}/getNoParentLegendLayerIds`;
export const getNoParentVisibleLayers = `${PREFIX}/getNoParentVisibleLayers`;
export const getEditingLayerComponentId = `${PREFIX}/getEditingLayerComponentId`;
export const getAllLayerIdsByProperty = `${PREFIX}/getAllLayerIdsByProperty`;
export const getActiveSnapTool = `${PREFIX}/getActiveSnapTool`;
export const getForceSnapTool = `${PREFIX}/getForceSnapTool`;
export const getActiveSelectedIntersectTool = `${PREFIX}/getActiveSelectedIntersectTool`;
export const getActiveLegendPanel = `${PREFIX}/getActiveLegendPanel`;
export const getActiveFilterPanelMinimize = `${PREFIX}/getActiveFilterPanelMinimize`;
export const getVisibleFeatures = `${PREFIX}/getVisibleFeatures`;
export const getSelectedDate = `${PREFIX}/getSelectedDate`;
export const getSelectedMouseTool = `${PREFIX}/getSelectedMouseTool`;
export const getTimelineEnable = `${PREFIX}/getTimelineEnable`;
export const getTimelineVisible = `${PREFIX}/getTimelineVisible`;
export const getTimelineModes = `${PREFIX}/getTimelineModes`;
export const getSelectedTimelineMode = `${PREFIX}/getSelectedTimelineMode`;
export const getGltfLayers = `${PREFIX}/getGltfLayers`;
export const getLayersByType = `${PREFIX}/getLayersByType`;
export const getUrlsToken = `${PREFIX}/getUrlsToken`;
export const getRights = `${PREFIX}/getRights`;
export const getGisMinimized = `${PREFIX}/getGisMinimized`;
export const getGisMinimizedSearchLayerId = `${PREFIX}/getGisMinimizedSearchLayerId`;
export const getGisComponentIds = `${PREFIX}/getGisComponentIds`;
export const getGisLayerComponentIds = `${PREFIX}/getGisLayerComponentIds`;
export const getUrlFilterdlayer = `${PREFIX}/getUrlFilterdlayer`;
export const getFavoriteLayerIds = `${PREFIX}/getFavoriteLayerIds`;
export const getFavoriteLayersSet = `${PREFIX}/getFavoriteLayersSet`;
export const getFavoriteLayerCount = `${PREFIX}/getFavoriteLayerCount`;
export const getRecentLayerIds = `${PREFIX}/getRecentLayerIds`;
export const getRecentLayerCount = `${PREFIX}/getRecentLayerCount`;
export const getFavoriteItemByLayerComponentId = `${PREFIX}/getFavoriteItemByLayerComponentId`;
export const getCalcFormOption = `${PREFIX}/getCalcFormOption`;
export const getHeatmapOption = `${PREFIX}/getHeatmapOption`;
export const getMapCursorStyle = `${PREFIX}/getMapCursorStyle`;
export const getShowTerrainButton = `${PREFIX}/getShowTerrainButton`;
export const getShowSkyButton = `${PREFIX}/getShowSkyButton`;
export const getPanoramasButton = `${PREFIX}/getPanoramasButton`;
export const getShowOpacitySlider = `${PREFIX}/getShowOpacitySlider`;
export const getShowRasterLayerOpacity = `${PREFIX}/getShowRasterLayerOpacity`;
export const getSkyOptions = `${PREFIX}/getSkyOptions`;
export const getBaseMapRasterOpacity = `${PREFIX}/getBaseMapRasterOpacity`;
export const getBaseTextMapRasterOpacity = `${PREFIX}/getBaseTextMapRasterOpacity`;
export const isSpaceImageryPanelVisible = `${PREFIX}/isSpaceImageryPanelVisible`;
export const getSpaceImageryFoundFeatures = `${PREFIX}/getSpaceImageryFoundFeatures`;
export const getSpaceImageryHoveredFeatureId = `${PREFIX}/getSpaceImageryHoveredFeatureId`;
export const getSpaceImageryVisibleFeatureIds = `${PREFIX}/getSpaceImageryVisibleFeatureIds`;
export const getTmpLayerIntersectLayerId = `${PREFIX}/getTmpLayerIntersectLayerId `;
export const isLayerTableInPopup = `${PREFIX}/isLayerTableInPopup `;
export const getPkkMultiLayerComponentId = `${PREFIX}/getPkkMultiLayerComponentId `;
export const getMapbox = `${PREFIX}/getMapbox `;
export const getOpenCardsGrid = `${PREFIX}/getOpenCardsGrid `;
export const getSelectedObjectId = `${PREFIX}/getSelectedObjectId `;
export const getSelectedData = `${PREFIX}/getSelectedData `;
export const getPartToolMode = `${PREFIX}/getPartToolMode `;
export const getGeocodeSystem = `${PREFIX}/getGeocodeSystem `;

const BASE_MAP_EMPTY_ID = 'NO_BASE_MAP';
const BASE_MAP_EMPTY_TITLE = 'Без базовой карты';
const TEXT_MAP_EMPTY_TITLE = 'Без текстового слоя';
const TERRAIN_MAP_EMPTY_TITLE = 'Без рельефа';
const WITTHOUT_MAP_IMG_URL = require('@/assets/bm/bm-white.png');
const GIS_MINIMIZED = 'gisMinimized';

export default {
  [getOptions]: (state) => (componentId) => {
    return state.componentOptions[componentId] || {};
  },

  [getBounds]: (state, getters) => (componentId) => {
    return getters[getOptions](componentId).bounds || null;
  },

  [getLayerConfigs]: (state, getters) => (componentId) => {
    const gisOptions = getters[getOptions](componentId);
    let gisItems = gisOptions.items || [];
    if (gisOptions.layersCatalogEnabled) {
      //Добавление из избранных, которые не были загружены в каталог
      gisItems = [...gisItems, ...getters[getFavoriteLayerIds](componentId, true)];
    }
    //Удаление дубликатов
    gisItems = [...new Set(gisItems.map((item) => item.toString()))];

    return gisItems
      .map((itemComponentId) => {
        return state.items[itemComponentId] || {};
      })
      .filter((itemComponentConfig) => {
        if (!['GisLayer', 'GisGroupLayer'].includes(itemComponentConfig.componentType)) {
          return false;
        }
        return true;
      });
  },

  [getTimelines]: (state, getters) => (componentId) => {
    let gisItems = getters[getOptions](componentId).items || [];
    //Удаление дубликатов
    gisItems = [...new Set(gisItems.map((item) => item.toString()))];

    return gisItems
      .map((itemComponentId) => {
        const config = state.items[itemComponentId] || {};
        const options = state.componentOptions[itemComponentId];
        return { ...config, options };
      })
      .filter((itemComponentConfig) => {
        return itemComponentConfig.componentType === 'gisTimeline';
      });
  },

  [getBaseMapLayerIds]: (state, getters) => (componentId) => {
    return getters[getLayerConfigs](componentId)
      .filter((layerComponentConfig) => {
        const layerComponentOptions = state.componentOptions[layerComponentConfig.componentId] || {};

        return layerComponentOptions.baseMap;
      })
      .map((layerComponentConfig) => {
        return layerComponentConfig.componentId;
      });
  },

  [getBaseTextMapLayerIds]: (state, getters) => (componentId) => {
    return getters[getLayerConfigs](componentId)
      .filter((layerComponentConfig) => {
        const layerComponentOptions = state.componentOptions[layerComponentConfig.componentId] || {};

        return layerComponentOptions.baseTextMap;
      })
      .map((layerComponentConfig) => {
        return layerComponentConfig.componentId;
      });
  },

  [getTerrainMapLayerIds]: (state, getters) => (componentId) => {
    return getters[getLayerConfigs](componentId)
      .filter((layerComponentConfig) => {
        const layerComponentOptions = state.componentOptions[layerComponentConfig.componentId] || {};

        return layerComponentOptions.terrainMap;
      })
      .map((layerComponentConfig) => {
        return layerComponentConfig.componentId;
      });
  },

  [getLegendPanelLayerIds]: (state, getters) => (componentId, skipLegendLayerFilter) => {
    return getters[getLegendLayerIds](componentId, true).filter((layerComponentId) => {
      return (
        (getters[isLegendItemVisible](layerComponentId, componentId, skipLegendLayerFilter) ||
          getters[isLegendChildItemsVisible](layerComponentId, componentId, skipLegendLayerFilter)) &&
        layerComponentId !== TMP_LAYER_CONFIG.componentId
      );
    });
  },

  [getLegendLayerIds]: (state, getters) => (componentId, desc) => {
    return getters[getLayerConfigs](componentId)
      .filter((layerComponentConfig) => {
        const { baseMap, baseTextMap, terrainMap, visibleInLegend } = state.componentOptions[layerComponentConfig.componentId] || {};

        return !baseMap && !baseTextMap && !terrainMap && visibleInLegend;
      })
      .sort((layerComponentConfig1, layerComponentConfig2) => {
        const pos1 = layerComponentConfig1.componentPos || 0;
        const pos2 = layerComponentConfig2.componentPos || 0;

        if (desc) {
          return pos2 - pos1;
        }

        return pos1 - pos2;
      })
      .map((layerComponentConfig) => {
        return layerComponentConfig.componentId;
      });
  },

  [getLegendGroupLayerIds]: (state, getters) => (componentId) => {
    return getters[getLegendLayerIds](componentId).filter((layerComponentId) => {
      const { group } = getters[getLayerOptions](layerComponentId);

      return group;
    });
  },

  [getAllLgendGroupLayerIds]: (state, getters) => (componentId) => {
    const groupLayerIds = [];
    getters[getLegendGroupLayerIds](componentId).forEach((rootLayerComponentId) => {
      foreachLayerRecursive(rootLayerComponentId, getters, (layerComponentId) => {
        if (getters[getOptions](layerComponentId).group) {
          groupLayerIds.push(layerComponentId);
        }
      });
    });

    return groupLayerIds;
  },

  [getBaseMapListItems]: (state, getters) => (componentId) => {
    return generateBaseMapList(state, getters, getters[getBaseMapLayerIds](componentId), BASE_MAP_EMPTY_TITLE);
  },

  [getBaseTextMapListItems]: (state, getters) => (componentId) => {
    return generateBaseMapList(state, getters, getters[getBaseTextMapLayerIds](componentId), TEXT_MAP_EMPTY_TITLE);
  },

  [getTerrainMapListItems]: (state, getters) => (componentId) => {
    return generateBaseMapList(state, getters, getters[getTerrainMapLayerIds](componentId), TERRAIN_MAP_EMPTY_TITLE);
  },

  [getActiveBaseMapLayerComponentId]: (state, getters) => (componentId) => {
    return getters[getOptions](componentId).activeBaseMapLayerComponentId || null;
  },

  [getActiveBaseTextMapLayerComponentId]: (state, getters) => (componentId) => {
    return getters[getOptions](componentId).activeBaseTextLayerComponentId || null;
  },

  [getActiveTerrainMapLayerComponentId]: (state, getters) => (componentId) => {
    return getters[getOptions](componentId).activeTerrainLayerComponentId || null;
  },

  [getMapboxSourcesAndLayers]: (state, getters) => (componentId) => {
    const {
      bufferSelectionGeoJson,
      digitizedGeoJson,
      selectedObjectIntersectGeoJson,
      validationLayerObjectIntersectGeoJson,
      legendNoParentLayerIds,
      reportLayerComponentId,
      reportFeatureId,
      skyOptions,
      editingLayerComponentId,
      heatmapOptions,
      bindingTileLayerGeoJson,
      tempCosmeticLayers
    } = getters[getOptions](componentId);

    let sources = {};
    let layers = {};
    let selectionLayers = {};
    let hoverLayers = {};
    let spritesUrls = {};

    //Специальный словарь, для объединения слоев с одинаковым источником
    let sourceHashMap = {};

    //Добавление базовой карты (подложки)
    const activeBaseMapLayerComponentId = getters[getActiveBaseMapLayerComponentId](componentId);
    if (activeBaseMapLayerComponentId && activeBaseMapLayerComponentId !== BASE_MAP_EMPTY_ID) {
      addMapboxSourceAndLayer(getters, activeBaseMapLayerComponentId, sources, layers, selectionLayers, hoverLayers, sourceHashMap, spritesUrls);
    }
    const activeBaseTextLayerComponentId = getters[getActiveBaseTextMapLayerComponentId](componentId);
    if (activeBaseTextLayerComponentId && activeBaseTextLayerComponentId !== BASE_MAP_EMPTY_ID) {
      addMapboxSourceAndLayer(getters, activeBaseTextLayerComponentId, sources, layers, selectionLayers, hoverLayers, sourceHashMap, spritesUrls);
    }

    const activeTerrainLayerComponentId = getters[getActiveTerrainMapLayerComponentId](componentId);
    let terrainExaggregationLayer = null;
    if (activeTerrainLayerComponentId && activeTerrainLayerComponentId !== BASE_MAP_EMPTY_ID) {
      terrainExaggregationLayer = addTerrainMapboxSourceAndLayer(getters, activeTerrainLayerComponentId, sources, layers, sourceHashMap);
    }

    // Добавление неба
    if (skyOptions.show) {
      layers['sky'] = {
        id: 'sky',
        type: 'sky',
        paint: {
          'sky-type': 'atmosphere',
          'sky-atmosphere-sun': [30, skyOptions.value],
          'sky-atmosphere-sun-intensity': 15
        }
      };
    }

    //Добавление слоев легенды
    try {
      formMapboxSourcesAndLayers(getters, getters[getLegendLayerIds](componentId), sources, layers, selectionLayers, hoverLayers, sourceHashMap, spritesUrls);
    } catch (err) {
      //TODO: обработка ошибки
      window.console.error('formMapboxSourcesAndLayers: ' + err);
    }

    //Добавление просмотра космоснимков
    addSpaceImageryRastersSourceAndLayer(componentId, getters, sources, layers);

    //Добавление слоев, которые загрузили отдельно (слои из свернутых папок)
    //Это происходит при включении слоев которые нужны для формирования отчетов
    legendNoParentLayerIds.forEach((layerComponentId) => {
      const { defaultFilterConfig } = getters[getLayerOptions](layerComponentId);
      if (getters[isVisible](layerComponentId) && (!defaultFilterConfig.configFilterId || defaultFilterConfig.loading || defaultFilterConfig.loaded)) {
        addMapboxSourceAndLayer(getters, layerComponentId, sources, layers, selectionLayers, hoverLayers, sourceHashMap);
      }
    });

    //Добавление служебных слоев
    const {
      identificationMapboxSourceId,
      identificationMapboxSourceLayer,
      identificationMapboxFeatureId,
      identificationMapboxGeoJson,
      identificationPkkFeature,
      coordinateTableMapboxGeoJson,
      reportDrawLayerId,
      activeSnapTool,
      activeSnapLngLat,
      panorama,
      pointAvailabilityNearOrganisation,
      pointAvailabilityIsZdravLayer,
      pointAvailabilityRouteMode,
      pointAvailabilityObjectPath,
      pointAvailabilityActiveRouteObjectId,
      routePoints,
      identificationPkkLayerId,
      identResultLayerComponentId,
      identResultObjectId
    } = getters[getOptions](componentId);

    //Подсветка объектов отчета
    if (reportLayerComponentId && reportFeatureId) {
      addReportSourceAndLayer(getters, reportLayerComponentId, reportFeatureId, sources, layers);
    }
    //Слои для отображения "Выделить внутри буферной зоны" и выделение окружностью
    if (bufferSelectionGeoJson) {
      addBufferSelectionSourceAndLayer(sources, layers, bufferSelectionGeoJson);
    }

    if (reportDrawLayerId) {
      addMapboxSourceAndLayer(getters, reportDrawLayerId, sources, layers, selectionLayers, hoverLayers, sourceHashMap);
    }

    addChartSourceAndLayer(componentId, getters, sources, layers);
    //Слои для выбора снимков
    addSpaceImageryFeaturesSourceAndLayer(componentId, getters, sources, layers);

    //Слои для работы идентификации
    //Подсветка идентификации векторных слоев (vector, geojson, wfs)
    if (identificationMapboxSourceId && identificationMapboxFeatureId && sources[identificationMapboxSourceId]) {
      const keyField = sources[identificationMapboxSourceId].promoteId || 'id';
      addIdentificationLayer(identificationMapboxSourceId, identificationMapboxSourceLayer, identificationMapboxFeatureId, keyField, layers);
    }
    //Подсветка активного результата идентификации
    if (identResultLayerComponentId && identResultObjectId) {
      addIdentificationResultLayer(getters, identResultLayerComponentId, identResultObjectId, sources, layers);
    }
    //Подсветка идентификации ПКК
    if (identificationPkkFeature) {
      addPkkIdentificationSourceAndLayer(sources, layers, identificationPkkFeature);
    }
    if (coordinateTableMapboxGeoJson) {
      addCoordinateTableSourceAndLayer(sources, layers, coordinateTableMapboxGeoJson);
    }
    //Подсветка идентификации WMS и ArcGIS
    if (identificationMapboxGeoJson) {
      addWmsIdentificationSourceAndLayer(sources, layers, identificationMapboxGeoJson);
    }
    if (digitizedGeoJson) {
      addDigitizedSourceAndLayer(sources, layers, digitizedGeoJson);
    }
    if (activeSnapTool && activeSnapLngLat) {
      addSnappingSourceAndLayer(sources, layers, activeSnapLngLat);
    }
    //Произвольные косметические слои
    if (tempCosmeticLayers) {
      addTempCosmeticLayers(sources, layers, tempCosmeticLayers);
    }
    //Слои для подсветки выделения и отчетов
    layers = {
      ...layers,
      ...selectionLayers,
      ...hoverLayers
    };

    //Подсветка самопересечений редактируемого слоя
    if (editingLayerComponentId) {
      const { validatePolygonKinks } = getters[getOptions](editingLayerComponentId);
      if (validatePolygonKinks) {
        addKinksLayer(getters, sources, layers, editingLayerComponentId);
      }
    }

    if (selectedObjectIntersectGeoJson) {
      addSelectedObjectIntersectLayer(sources, layers, selectedObjectIntersectGeoJson);
    }

    if (bindingTileLayerGeoJson) {
      addBindingTileLayer(sources, layers, bindingTileLayerGeoJson);
    }

    if (heatmapOptions.coordArea) {
      addInterpolateHeatmapCanvasLayer(sources, layers, heatmapOptions.coordArea);
    }

    if (validationLayerObjectIntersectGeoJson) {
      addSelectedObjectIntersectLayer(sources, layers, validationLayerObjectIntersectGeoJson);
    }
    if (pointAvailabilityNearOrganisation) {
      addPointAvailabilityNearOrganisationLayer(sources, layers, pointAvailabilityNearOrganisation, pointAvailabilityIsZdravLayer, pointAvailabilityRouteMode);
    }

    if (pointAvailabilityObjectPath) {
      addPointAvailabilityObjectPathLayer(sources, layers, pointAvailabilityObjectPath, pointAvailabilityRouteMode, pointAvailabilityActiveRouteObjectId);
    }

    if (routePoints) {
      addRoutePointsLayer(sources, layers, routePoints, pointAvailabilityRouteMode);
    }

    const mapboxSourcesAndLayers = {
      sources,
      layers: Object.values(layers)
    };

    if (terrainExaggregationLayer) {
      mapboxSourcesAndLayers.terrain = terrainExaggregationLayer;
    }

    if (spritesUrls.sprite) {
      mapboxSourcesAndLayers.sprite = spritesUrls.sprite;
    }

    return mapboxSourcesAndLayers;
  },

  [getMapboxStyle]: (state, getters) => (componentId) => {
    return {
      version: 8,
      glyphs: 'mapbox://fonts/mapbox/{fontstack}/{range}.pbf',
      ...getters[getMapboxSourcesAndLayers](componentId)
    };
  },

  /**Упрощенный способ получения стилей мапбокс для слоев.*/
  [getMapboxLayerStyle]: (state, getters) => (layerComponentIds) => {
    const sources = {};
    const layers = {};
    const selectionLayers = {};
    const hoverLayers = {};
    const spritesUrls = {};
    //Специальный словарь, для объединения слоев с одинаковым источником
    let sourceHashMap = {};

    for (const layerComponentId of layerComponentIds) {
      addMapboxSourceAndLayer(getters, layerComponentId, sources, layers, selectionLayers, hoverLayers, sourceHashMap, spritesUrls, true);
    }

    return {
      version: 8,
      glyphs: 'mapbox://fonts/mapbox/{fontstack}/{range}.pbf',
      sources,
      layers: Object.values(layers)
    };
  },

  [getLegendLayerSearchValue]: (state, getters) => (componentId) => {
    return getters[getOptions](componentId).legendLayerSearchValue || '';
  },

  [isLegendLayersAllVisible]: (state, getters) => (componentId) => {
    return getters[getLegendLayerIds](componentId).every((layerComponentId) => {
      return getters[isVisible](layerComponentId);
    });
  },

  [isLegendLayersSomeVisible]: (state, getters) => (componentId) => {
    const layerIds = [...getters[getLegendLayerIds](componentId), ...getters[getNoParentLegendLayerIds](componentId)];
    return layerIds.some((layerComponentId) => {
      return getters[isHalfVisible](layerComponentId);
    });
  },

  [isLegendLayersSomeExpanded]: (state, getters) => (componentId) => {
    return getters[getLegendGroupLayerIds](componentId).some((layerComponentId) => {
      return getters[isExpanded](layerComponentId);
    });
  },

  [isAllowDropBetweenGroup]: (state, getters) => (componentId) => {
    return getters[getOptions](componentId).allowDropBetweenGroup;
  },

  [isFavoriteLayer]: (state, getters) => (componentId, layerComponentId) => {
    const layerIds = getters[getFavoriteLayerIds](componentId);
    return layerIds.includes(layerComponentId);
  },

  [isRecentLayer]: (state, getters) => (componentId, layerComponentId) => {
    const layerIds = getters[getRecentLayerIds](componentId);
    return layerIds.includes(layerComponentId);
  },

  [getLegendLayersFilter]: (state, getters) => (componentId) => {
    return getters[getOptions](componentId).legendLayersFilter;
  },

  [isMatchLegendLayersFilter]: (state, getters) => (gisComponentId, layerComponentId) => {
    const { legendLayersFilter } = getters[getOptions](gisComponentId);
    const { group } = getters[getLayerOptions](layerComponentId);

    switch (legendLayersFilter) {
      case 'favorite':
        return !group && getters[isFavoriteLayer](gisComponentId, layerComponentId);
      case 'recent':
        return !group && getters[isRecentLayer](gisComponentId, layerComponentId);
      default:
        return true;
    }
  },

  [getActiveLegendLayerComponentId]: (state, getters) => (componentId) => {
    return getters[getOptions](componentId).activeLegendLayerComponentId || null;
  },

  [getPopupMenuLayerComponentId]: (state, getters) => (componentId) => {
    return getters[getOptions](componentId).popupMenuLayerComponentId || null;
  },

  [getIconTooltipLayerComponentId]: (state, getters) => (componentId) => {
    return getters[getOptions](componentId).iconTooltipLayerComponentId || null;
  },

  [getDisabledChecks]: (state, getters) => (layerComponentId) => {
    return getters[getOptions](layerComponentId).disabledChecks;
  },

  [getValidationList]: (state, getters) => (componentId) => {
    return getters[getOptions](componentId).validationList;
  },

  [getSelectedValidationIdsList]: (state, getters) => (componentId) => {
    return getters[getOptions](componentId).selectedValidationsIdsList;
  },

  [getSelectedValidationIdsListFromDataset]: (state, getters) => (componentId) => {
    return getters[getOptions](componentId).selectedValidationsIdsListFromDataset;
  },

  [getDblClickedLayerComponentId]: (state, getters) => (componentId) => {
    return getters[getOptions](componentId).dblClickedLayerComponentId || null;
  },

  [getStyleFormLayerComponentId]: (state, getters) => (componentId) => {
    return getters[getOptions](componentId).styleFormLayerComponentId || null;
  },

  [getTooltipFormLayerComponentId]: (state, getters) => (componentId) => {
    return getters[getOptions](componentId).tooltipFormLayerComponentId || null;
  },

  [getSignLayerComponentId]: (state, getters) => (componentId) => {
    return getters[getOptions](componentId).signLayerPanelLayerComponentId || null;
  },

  [getBindingTileLayerId]: (state, getters) => (componentId) => {
    return getters[getOptions](componentId).bindingTileLayerId || null;
  },

  [getCalcFormOption]: (state, getters) => (componentId, option) => {
    return getters[getOptions](componentId).calcForm[option] || null;
  },

  [getHeatmapOption]: (state, getters) => (componentId, option) => {
    return getters[getOptions](componentId).heatmapOptions[option] || null;
  },

  [getEnableLayers]: (state, getters) => (componentId) => {
    const options = getters[getOptions](componentId);
    if (options.selectInActiveLayer || options.enableForSelect) {
      const activeLayerComponentId = getters[getActiveLegendLayerComponentId](componentId);
      return activeLayerComponentId ? [activeLayerComponentId] : [];
    } else {
      return [...getters[getLegendLayerIds](componentId), ...(options.legendNoParentLayerIds || [])].reduce((result, layerComponentId) => {
        result.push(...getters[gisLayerGetEnableLayers](layerComponentId));
        return result;
      }, []);
    }
  },

  [getVisibleLayers]: (state, getters) => (componentId) => {
    return getters[getLegendLayerIds](componentId).reduce((result, layerComponentId) => {
      result.push(...getters[gisLayerGetVisibleLayers](layerComponentId));
      return result;
    }, []);
  },

  [getGisMinimizeVisibleLayers]: (state, getters) => (componentId) => {
    let { gisMinimizeVisibleLayerIds } = getters[getOptions](componentId) || [];

    if (!gisMinimizeVisibleLayerIds?.length) {
      gisMinimizeVisibleLayerIds = getters[getVisibleLayers](componentId) || [];
      const tmpLayerPos = gisMinimizeVisibleLayerIds.indexOf('tmpLayer');

      if (tmpLayerPos > -1) {
        gisMinimizeVisibleLayerIds.splice(tmpLayerPos, 1);
      }
    }

    return gisMinimizeVisibleLayerIds;
  },

  [getLastVisibleLayer]: (state, getters) => (componentId) => {
    const gisMinimizeVisibleLayerIds = getters[getGisMinimizeVisibleLayers](componentId);
    const lastVisibleLayerComponentId = gisMinimizeVisibleLayerIds[gisMinimizeVisibleLayerIds.length - 1];

    return isNaN(parseInt(lastVisibleLayerComponentId)) ? null : lastVisibleLayerComponentId;
  },

  [getFullHalfVisibleLayers]: (state, getters) => (componentId) => {
    const layerComponentIds = [];
    getters[getLegendLayerIds](componentId).forEach((rootLayerComponentId) => {
      foreachLayerRecursive(rootLayerComponentId, getters, (layerComponentId) => {
        if (!getters[getOptions](layerComponentId).group && (getters[isVisible](layerComponentId) || getters[isHalfVisible](layerComponentId))) {
          layerComponentIds.push(layerComponentId);
        }
      });
    });
    const { legendNoParentLayerIds } = getters[getOptions](componentId);
    (legendNoParentLayerIds || []).forEach((layerComponentId) => {
      if (!getters[getOptions](layerComponentId).group && (getters[isVisible](layerComponentId) || getters[isHalfVisible](layerComponentId))) {
        layerComponentIds.push(layerComponentId);
      }
    });
    return [...new Set(layerComponentIds)];
  },

  [getLayersWithSelections]: (state, getters) => (componentId) => {
    return getters[getVisibleLayers](componentId).reduce((result, layerComponentId) => {
      const selectedIds = getters[getSelectedIds](layerComponentId);
      if (selectedIds.length) {
        result.push(layerComponentId);
      }
      return result;
    }, []);
  },

  [getEditingLayerComponentId]: (state, getters) => (componentId) => {
    return getters[getOptions](componentId).editingLayerComponentId;
  },

  [getTablePanelLayerComponentId]: (state, getters) => (componentId) => {
    return getters[getOptions](componentId).tablePanelLayerComponentId || null;
  },

  [getStatsPanelLayerComponentId]: (state, getters) => (componentId) => {
    return getters[getOptions](componentId).statsPanelLayerComponentId || null;
  },

  [getFilterPanelLayerComponentId]: (state, getters) => (componentId) => {
    return getters[getOptions](componentId).filterPanelLayerComponentId || null;
  },

  [getLoadingImageDataPromise]: (state, getters) => (componentId, imageName) => {
    const loadingImageDataPromises = getters[getOptions](componentId).loadingImageDataPromises || {};
    return loadingImageDataPromises[imageName] || null;
  },

  [getShowFavoritesPanel]: (state, getters) => (gisComponentId) => {
    return getters[getOptions](gisComponentId).showFavoritesPanel;
  },

  [getLoadingImagePromise]: (state, getters) => (componentId, imageName) => {
    const loadingImagePromises = getters[getOptions](componentId).loadingImagePromises || {};
    return loadingImagePromises[imageName] || null;
  },

  [getAllSelected]: (state, getters) => (componentId) => {
    return [...getters[getLegendLayerIds](componentId), ...getters[getNoParentLegendLayerIds](componentId)].reduce((result, layerComponentId) => {
      result = {
        ...result,
        ...getters[gisLayerGetAllSelected](layerComponentId)
      };
      return result;
    }, {});
  },

  [getAllFilters]: (state, getters) => (componentId) => {
    return [...getters[getLegendLayerIds](componentId), ...getters[getNoParentLegendLayerIds](componentId)].reduce((result, layerComponentId) => {
      result = {
        ...result,
        ...getters[gisLayerGetAllFilters](layerComponentId)
      };
      return result;
    }, {});
  },

  [getNoParentLegendLayerIds]: (state, getters) => (componentId) => {
    return getters[getOptions](componentId).legendNoParentLayerIds || [];
  },

  [getNoParentVisibleLayers]: (state, getters) => (componentId) => {
    return getters[getNoParentLegendLayerIds](componentId).filter((layerComponentId) => {
      return getters[isVisible](layerComponentId);
    });
  },

  [getAllLayerIdsByProperty]: (state, getters) => (componentId, name, value) => {
    return getters[getLegendLayerIds](componentId)
      .map((layerComponentId) => {
        return getters[getAllLayerIds](layerComponentId);
      })
      .join()
      .split(',')
      .filter((item) => item)
      .filter((layerComponentId) => {
        return getters[getOptions](layerComponentId)[name] === value;
      });
  },

  [getActiveSnapTool]: (state, getters) => (componentId) => {
    return getters[getOptions](componentId).activeSnapTool;
  },

  [getForceSnapTool]: (state, getters) => (componentId) => {
    return getters[getOptions](componentId).forceSnapTool;
  },

  [getActiveSelectedIntersectTool]: (state, getters) => (componentId) => {
    return getters[getOptions](componentId).activeSelectedIntersectTool;
  },

  [getActiveLegendPanel]: (state, getters) => (componentId) => {
    return getters[getOptions](componentId).activeLegendPanel;
  },

  [getActiveFilterPanelMinimize]: (state, getters) => (componentId) => {
    return getters[getOptions](componentId).activeFilterPanelMinimize;
  },

  [getVisibleFeatures]: (state, getters) => (componentId) => {
    return getters[getOptions](componentId).visibleFeatures;
  },

  [getSelectedDate]: (state, getters) => (componentId) => {
    return getters[getOptions](componentId).selectedDate || null;
  },

  [getSelectedMouseTool]: (state, getters) => (componentId) => {
    return getters[getOptions](componentId).selectedMouseTool || null;
  },

  [getTimelineEnable]: (state, getters) => (componentId) => {
    let enable = getters[getLegendLayerIds](componentId).some((layerComponentId) => {
      return getters[isTimelineEnable](layerComponentId);
    });
    //Имеются компоненты timeline
    enable = enable || !!getters[getTimelines](componentId)[0];
    return enable;
  },

  [getTimelineVisible]: (state, getters) => (componentId) => {
    return getters[getTimelineEnable](componentId) && getters[getOptions](componentId).timelineButtonActive;
  },

  [getTimelineModes]: (state, getters) => (componentId) => {
    const modes = {};
    getters[getLegendLayerIds](componentId).forEach((rootLayerComponentId) => {
      foreachLayerRecursive(rootLayerComponentId, getters, (layerComponentId) => {
        if (!getters[getOptions](layerComponentId).group && getters[isTimelineEnable](layerComponentId)) {
          modes[getters[getOptions](layerComponentId).timelineMode] = true;
        }
      });
    });
    return Object.keys(modes);
  },

  [getSelectedTimelineMode]: (state, getters) => (componentId) => {
    return getters[getOptions](componentId)?.selectedTimelineMode || 'date';
  },

  [getGltfLayers]: (state, getters) => (componentId) => {
    return getters[getLegendLayerIds](componentId)
      .reduce((result, layerComponentId) => {
        result.push(...getters[gisLayerGetGltfLayers](layerComponentId));
        return result;
      }, [])
      .filter((layerComponentId) => {
        return getters[getLayerOptions](layerComponentId).type === 'gltf';
      })
      .map((layerComponentId) => {
        return getters[getMapboxLayerConfigs](layerComponentId)[0];
      });
  },

  [getLayersByType]: (state, getters) => (componentId, type) => {
    return getters[getLegendLayerIds](componentId)
      .filter((layerComponentId) => {
        return getters[getLayerOptions](layerComponentId).type === type;
      })
      .map((layerComponentId) => {
        return getters[getMapboxLayerConfigs](layerComponentId)[0];
      });
  },

  /**Список ресурсов, которым необходима авторизация, и доступ к ним*/
  [getUrlsToken]: (state, getters) => (componentId) => {
    return [...getters[getLegendLayerIds](componentId, true), ...getters[getBaseMapLayerIds](componentId)]
      .map((layerComponentId) => {
        return getters[getAllLayerIds](layerComponentId);
      })
      .flat()
      .filter((layerComponentId) => {
        return !!getters[getLayerOptions](layerComponentId).token && !['arcgis', 'pkk'].includes(getters[getLayerOptions](layerComponentId).type);
      })
      .map((layerComponentId) => {
        const layerOptions = getters[getLayerOptions](layerComponentId);
        //Обрезаем урл до первого параметра с фиг. скобкой
        let url = layerOptions.url;
        let pos = url.indexOf('{');
        if (pos) {
          url = url.substring(0, pos);
        }
        //Урл без параметров оставляем как есть
        return {
          url: url,
          token: layerOptions.token
        };
      });
  },

  [getRights]: (state, getters) => (componentId) => {
    return getters[getOptions](componentId).rights || {};
  },

  [getGisMinimized]: (state, getters) => (componentId) => {
    let gisMinimizedRightOption = getters[getRights](componentId).gisMinimized || false;

    if (gisMinimizedRightOption) {
      const queryParams = router.currentRoute.query;
      //карта должна переключаться в стандартный режим, если в url страницы есть параметр gisMinimized=false
      if (GIS_MINIMIZED in queryParams && queryParams[GIS_MINIMIZED] === 'false') {
        gisMinimizedRightOption = false;
      }
    }

    return gisMinimizedRightOption;
  },

  [getGisMinimizedSearchLayerId]: (state, getters) => (componentId) => {
    return getters[getOptions](componentId).gisMinimizedSearchLayerId;
  },

  [getGisComponentIds]: (state, getters) => (componentId) => {
    const gis_componentIds = getters[getOptions](componentId).gis_componentIds;
    if (!gis_componentIds || !Array.isArray(gis_componentIds)) {
      return [];
    }
    return gis_componentIds;
  },

  [getGisLayerComponentIds]: (state, getters) => (componentId) => {
    const gis_layerComponentIds = getters[getOptions](componentId).gis_layerComponentIds;
    if (!gis_layerComponentIds || typeof gis_layerComponentIds !== 'object') {
      return null;
    }
    return gis_layerComponentIds;
  },

  [getUrlFilterdlayer]: (state, getters) => (componentId) => {
    return getters[getOptions](componentId).urlFilterdlayer;
  },

  [getFavoriteLayerIds]:
    (state, getters) =>
    (gisComponentId, onlyGroupLayers = false) => {
      if (!gisComponentId) {
        return [];
      }
      const ret = getters[getOptions](gisComponentId)
        .favoriteLayerItems.filter((item) => {
          if (onlyGroupLayers && (state.items[item.componentId] || {}).componentType !== 'GisGroupLayer') {
            //Получаем массив id папок избранного для предотвращения повторной инициализации дочерних компонентов слоя
            return false;
          }
          //Не левый компонент
          const layerConfig = state.items[item.componentId] || {};
          return layerConfig.parentGisId == gisComponentId && getters[getLayerOptions](item.componentId).hasOwnProperty('visible');
        })
        .sort((item1, item2) => {
          const pos1 = getters[getLayerFavoritePosition](item1.componentId);
          const pos2 = getters[getLayerFavoritePosition](item2.componentId);
          return pos1 - pos2;
        });

      return ret.map((item) => {
        return item.componentId;
      });
    },

  [getFavoriteLayersSet]: (state, getters) => (gisComponentId) => {
    return new Set(getters[getFavoriteLayerIds](gisComponentId));
  },

  [getFavoriteLayerCount]: (state, getters) => (gisComponentId) => {
    return getters[getOptions](gisComponentId).favoriteLayerItems.length;
  },

  [getFavoriteItemByLayerComponentId]: (state, getters) => (gisComponentId, layerComponentId) => {
    if (!gisComponentId) {
      return null;
    }

    return getters[getOptions](gisComponentId).favoriteLayerItems.filter((item) => item.componentId.toString() === layerComponentId.toString())[0] || null;
  },

  [getRecentLayerIds]: (state, getters) => (gisComponentId) => {
    if (!gisComponentId) {
      return [];
    }

    return getters[getOptions](gisComponentId)
      .recentLayerItems.map((item) => {
        return item.componentId;
      })
      .filter((layerComponentId) => {
        return !!state.componentOptions[layerComponentId];
      });
  },

  [getRecentLayerCount]: (state, getters) => (gisComponentId) => {
    return getters[getOptions](gisComponentId).recentLayerItems.length;
  },

  [getMapCursorStyle]: (state, getters) => (gisComponentId) => {
    return getters[getOptions](gisComponentId).mapCursorStyle || '';
  },

  [getShowTerrainButton]: (state, getters) => (gisComponentId) => {
    return getters[getOptions](gisComponentId).showTerrainButton;
  },

  [getShowOpacitySlider]: (state, getters) => (gisComponentId) => {
    return getters[getOptions](gisComponentId).showOpacitySlider;
  },

  [getShowRasterLayerOpacity]: (state, getters) => (gisComponentId) => {
    return getters[getOptions](gisComponentId).showRasterLayerOpacity;
  },

  [getShowSkyButton]: (state, getters) => (gisComponentId) => {
    return getters[getOptions](gisComponentId).showSkyButton;
  },

  [getPanoramasButton]: (state, getters) => (gisComponentId) => {
    return getters[getOptions](gisComponentId).showPanoramasButton;
  },

  [getSkyOptions]: (state, getters) => (gisComponentId) => {
    return getters[getOptions](gisComponentId).skyOptions ?? { show: false, value: 0 };
  },

  [getBaseMapRasterOpacity]: (state, getters) => (gisComponentId) => {
    return getters[getOptions](gisComponentId).baseMapRasterOpacity;
  },

  [getBaseTextMapRasterOpacity]: (state, getters) => (gisComponentId) => {
    return getters[getOptions](gisComponentId).baseTextMapRasterOpacity;
  },

  [isSpaceImageryPanelVisible]: (state, getters) => (gisComponentId) => {
    return getters[getOptions](gisComponentId).showSpaceImageryPanel;
  },

  [getSpaceImageryFoundFeatures]: (state, getters) => (gisComponentId) => {
    return getters[getOptions](gisComponentId).spaceImageryFoundFeatures;
  },

  [getSpaceImageryHoveredFeatureId]: (state, getters) => (gisComponentId) => {
    return getters[getOptions](gisComponentId).spaceImageryHoveredFeatureId;
  },

  [getSpaceImageryVisibleFeatureIds]: (state, getters) => (gisComponentId) => {
    return getters[getOptions](gisComponentId).spaceImageryVisibleFeatureIds;
  },

  //TODO Удалить если не попросят вернуть 19.05.2022
  //Отображение таблицы объектов слоя внутри окна (как в старой версии)
  [isLayerTableInPopup]: (state, getters) => (gisComponentId) => {
    return getters[getOptions](gisComponentId).layerTableInPopup || false;
  },

  [getTmpLayerIntersectLayerId]: (state, getters) => (componentId) => {
    return getters[getOptions](componentId).tmpLayerIntersectLayerId;
  },

  //id папки со слоями ПКК
  //(в связи с особенностями сервиса arcgis мы вынуждены выдавать два слоя за один путем создания папки, похожей на слой)
  [getPkkMultiLayerComponentId]: (state, getters) => (componentId) => {
    //Если указан id, используем его
    const pkkLayerComponentId = getters[getOptions](componentId).pkkLayerComponentId;
    if (pkkLayerComponentId) {
      return pkkLayerComponentId;
    }
    //Иначе пытаемся найти папку с названием "Публичная кадастровая карта"
    return getters[getAllLayerIdsByProperty](componentId, 'text', 'Публичная кадастровая карта').find((layerComponentId) => {
      return getters[getOptions](layerComponentId).group;
    });
  },

  [getMapbox]: (state, getters) => (componentId) => {
    return getters[getOptions](componentId).mapbox;
  },

  [getOpenCardsGrid]: (state, getters) => (componentId) => {
    return getters[getOptions](componentId).openCardsGrid;
  },

  [getSelectedObjectId]: (state, getters) => (componentId) => {
    return getters[getOptions](componentId).selectedObjectId;
  },

  [getPartToolMode]: (state, getters) => (componentId) => {
    return getters[getOptions](componentId).partToolMode;
  },

  [getSelectedData]: (state, getters) => (componentId) => {
    const mapSelectedData = [];
    const layersWithSelectionsIds = getters[getLayersWithSelections](componentId);
    layersWithSelectionsIds.forEach((layerComponentId) => {
      const dataSetName = getters[getLayerDataSetName](layerComponentId);
      const { selectionGeoJson } = getters[getOptions](layerComponentId);
      const layerSelectedIds = getters[getSelectedIds](layerComponentId);
      const properties = selectionGeoJson.features.map((feature) => feature.properties);
      mapSelectedData.push({ layerComponentId, dataSetName, list_ids: layerSelectedIds, properties });
    });
    return mapSelectedData;
  },

  [getGeocodeSystem]: (state, getters) => (componentId) => {
    return getters[getOptions](componentId).geocodeSystem;
  }
};

function generateBaseMapList(state, getters, layerIds, emptyItemCaption) {
  const list = layerIds.map((layerComponentId) => {
    return baseMapListItemFactory(state, layerComponentId);
  });
  list.push({
    componentId: BASE_MAP_EMPTY_ID,
    iconUrl: WITTHOUT_MAP_IMG_URL,
    caption: emptyItemCaption,
    baseMap: true,
    baseTextMap: false
  });
  return list;
}

function formMapboxSourcesAndLayers(
  getters,
  layerComponentIds,
  mapboxSources,
  mapboxLayers,
  mapboxSelectionLayers,
  mapboxHoverLayers,
  sourceHashMap,
  spritesUrls
) {
  layerComponentIds
    .filter((layerComponentId) => layerComponentId !== TMP_LAYER_CONFIG.componentId)
    .forEach((layerComponentId) => {
      const group = getters[isGroup](layerComponentId);
      const visible = getters[isVisible](layerComponentId);
      const halfVisible = getters[isHalfVisible](layerComponentId);
      const { defaultFilterConfig } = getters[getLayerOptions](layerComponentId);
      if ((!visible && !halfVisible) || (defaultFilterConfig.configFilterId && !defaultFilterConfig.loaded)) {
        return;
      }

      if (group) {
        formMapboxSourcesAndLayers(
          getters,
          getters[getChildLayerComponentIds](layerComponentId),
          mapboxSources,
          mapboxLayers,
          mapboxSelectionLayers,
          mapboxHoverLayers,
          sourceHashMap,
          spritesUrls
        );
        return;
      }
      //Добавление источника и слоя
      addMapboxSourceAndLayer(getters, layerComponentId, mapboxSources, mapboxLayers, mapboxSelectionLayers, mapboxHoverLayers, sourceHashMap, spritesUrls);
    });
}

function addMapboxSourceAndLayer(
  getters,
  layerComponentId,
  mapboxSources,
  mapboxLayers,
  selectionLayers,
  hoverLayers,
  sourceHashMap,
  spritesUrls,
  ignoreExcluded
) {
  const layerOptions = getters[getLayerOptions](layerComponentId);
  const mapboxSourceHash = getters[getMapboxSourceHash](layerComponentId);
  const mapboxLayerConfigs = getters[getMapboxLayerConfigs](layerComponentId);
  const styleName = layerOptions.styleName;

  if (!ignoreExcluded && layerOptions.excluded) {
    return;
  }

  if (layerOptions.type === 'mapbox') {
    const includes = layerOptions.url.includes(portalConfig.mapboxAccessToken);
    const url = includes ? layerOptions.url : `${layerOptions.url}${portalConfig.mapboxAccessToken}`;
    let styleMapbox;

    if (styleName === 'light') {
      styleMapbox = lightMapbox.default;
    } else if (styleName === 'dark') {
      styleMapbox = darkMapbox.default;
    } else if (styleName === 'streets') {
      styleMapbox = streetsMapbox.default;
    } else if (styleName === 'outdoors') {
      styleMapbox = outdoorsMapbox.default;
    } else if (styleName === 'imagery') {
      styleMapbox = imageryMapbox.default;
    }

    const { layers, sources, sprite } = styleMapbox;

    layerOptions.url = url;
    spritesUrls.sprite = sprite;

    Object.entries(sources).forEach(([key, value]) => {
      mapboxSources[key] = value;
    });

    Object.entries(layers).forEach(([key, value]) => {
      mapboxLayers[key] = value;
    });
  }

  let sourceType = layerOptions.type;
  let mainSourceId;
  let geojsonSourceId;
  //Фильтр скрытия редактируемых объектов
  let editingIds = [];
  if (layerOptions.editingIds && layerOptions.editingIds[0]) {
    editingIds = layerOptions.editingIds;
  }
  //const hoverIds = getters[getHoverFeatureIds](layerComponentId);
  const selectedIds = getters[getSelectedIds](layerComponentId);
  //Объекты, скачанные на клиент, нужно отобразить как геожсон слой
  let geojsonFeatures = getters[getLayerAllFeatures](layerComponentId);
  const { featuresLastEdit } = getters[getLayerOptions](layerComponentId);
  //В тайлах скрываем редактируемые и подсвеченные
  let tileHideIds = [...editingIds];
  //Скрываем geojson объекты
  //Фейковое условие featuresLastEdit > 1 нужно для принудительного обновления
  if (geojsonFeatures[0] && featuresLastEdit > 1) {
    //Исключаем геожсон объекты из тайлов
    tileHideIds = tileHideIds.concat(geojsonFeatures.map((item) => item.id));
  }
  //Скрыть редактируемые и подсвеченные
  let geojsonHideIds = [...editingIds];
  //Скрываем удаленные объекты
  const layerChanges = getters[getChanges](layerComponentId);
  if (layerChanges.deleted[0]) {
    tileHideIds = tileHideIds.concat(layerChanges.deleted);
    geojsonHideIds = geojsonHideIds.concat(layerChanges.deleted);
  }
  if (layerChanges.permanentDeleted[0]) {
    tileHideIds = tileHideIds.concat(layerChanges.permanentDeleted);
    geojsonHideIds = geojsonHideIds.concat(layerChanges.permanentDeleted);
  }
  if (mapboxSourceHash) {
    //Оптимизация для WMS Arcgis слоев
    const layerLayersOptions = getters[getLayerLayersOption](layerComponentId);

    if (sourceHashMap[mapboxSourceHash]) {
      sourceHashMap[mapboxSourceHash].push(...layerLayersOptions);
    } else {
      sourceHashMap[mapboxSourceHash] = [...layerLayersOptions];
    }

    //Использование тайлового источника
    const tileSourceConfig = getters[getMapboxSourceConfig](layerComponentId, sourceType, {
      layers: sourceHashMap[mapboxSourceHash].join(',')
    });
    mainSourceId = mapboxSourceHash;
    mapboxSources[mainSourceId] = tileSourceConfig;

    //Добавление слоев
    mapboxLayerConfigs.forEach((mapboxLayerConfig) => {
      mapboxLayerConfig.source = mainSourceId;
      mapboxLayers[mapboxSourceHash] = mapboxLayerConfig;
    });
  } else {
    //Тайловые источники (векторные тайлы)
    //В режиме единичного объекта векторные тайлы не видны
    if (!['geojson', 'wfs', 'gltf', 'b3dm'].includes(layerOptions.type) && !layerOptions.singleFeature) {
      //В тайловом слое скрываем также объекты скачанные в geojson
      //Добавление основного источника для не geojson слоев
      const tileSourceConfig = getters[getMapboxSourceConfig](layerComponentId, sourceType);
      if (tileSourceConfig) {
        mainSourceId = getters[getMapboxSourceId](layerComponentId, sourceType);
        mapboxSources[mainSourceId] = tileSourceConfig;

        //Добавление слоев для основного источника
        //Скрываем также выбранные
        let hideIds = layerOptions.excludeSelectedFeatures ? [...tileHideIds, ...selectedIds] : tileHideIds;
        //hideIds = [...hideIds, ...hoverIds];
        addStyleLayers(layerOptions, mapboxLayerConfigs, '', mainSourceId, hideIds, mapboxLayers);
      }
    }

    //Добавление geojson источника, если для слоя имеются данные
    //Для geojson слоев этот источник - основной, для остальных - визуализатор отредактированных объектов
    geojsonSourceId = getters[getMapboxSourceId](layerComponentId, 'geojson');
    const jsonSourceConfig = getters[getMapboxSourceConfig](layerComponentId, 'geojson', null, geojsonSourceId);
    if (sourceType === 'geojson') {
      //Для геожсон слоя данные берем из конфига
      geojsonFeatures = jsonSourceConfig?.data?.features ?? [];
    }
    if (geojsonFeatures[0]) {
      let hideIds = layerOptions.excludeSelectedFeatures ? [...geojsonHideIds, ...selectedIds] : geojsonHideIds;
      geojsonSourceId = getters[getMapboxSourceId](layerComponentId, 'geojson');
      mapboxSources[geojsonSourceId] = jsonSourceConfig;
      //Добавление слоев для geojson источника
      addStyleLayers(layerOptions, mapboxLayerConfigs, '_geojson', geojsonSourceId, hideIds, mapboxLayers);
    } else {
      //Данных нет - источник не нужен
      geojsonSourceId = null;
    }

    //Добавление слоев выбранных объектов
    if (selectedIds && selectedIds[0] != undefined) {
      const mapboxSelectionLayerConfigs = getters[getSelectionMapboxLayerConfigs](layerComponentId, mapboxLayerConfigs[0]);
      if (mainSourceId) {
        //для основного источника
        addStyleLayers(layerOptions, mapboxSelectionLayerConfigs, '', mainSourceId, tileHideIds, selectionLayers);
      }
      //для geojson источника
      if (geojsonSourceId) {
        addStyleLayers(layerOptions, mapboxSelectionLayerConfigs, '_geojson', geojsonSourceId, geojsonHideIds, selectionLayers);
      }
    }
    //Добавление слоев подсвеченных объектов
    const mapboxHoverLayerConfigs = getters[getMapboxHoverLayerConfigs](layerComponentId, mapboxLayerConfigs[0]);
    if (mapboxHoverLayerConfigs && mapboxHoverLayerConfigs[0]) {
      if (mainSourceId) {
        //для основного источника
        addStyleLayers(layerOptions, mapboxHoverLayerConfigs, '', mainSourceId, tileHideIds, hoverLayers);
      }
      //для geojson источника
      if (geojsonSourceId) {
        addStyleLayers(layerOptions, mapboxHoverLayerConfigs, '_geojson', geojsonSourceId, geojsonHideIds, hoverLayers);
      }
    }
  }
}

function addTerrainMapboxSourceAndLayer(getters, layerComponentId, sources, layers) {
  const layerOptions = getters[getLayerOptions](layerComponentId);
  //Установка ключа АПИ из УРЛ
  if (layerOptions.url.includes('access_token')) {
    const url = new URL(layerOptions.url);
    mapboxgl.accessToken = url.searchParams.get('access_token');
  }
  const sourceName = 'terrain';
  sources[sourceName] = {
    type: layerOptions.type,
    url: layerOptions.url,
    tileSize: layerOptions.size,
    maxzoom: layerOptions.maxNativeZoom
  };

  let exaggerationLayer = null;

  layerOptions.mapboxLayers.forEach((mapboxLayer, index) => {
    const layerId = mapboxLayer.id || 'terrain-' + index;
    //mapboxLayer.id = layerId;
    mapboxLayer.source = sourceName;
    if (mapboxLayer.exaggeration) {
      exaggerationLayer = mapboxLayer;
    } else {
      layers[layerId] = mapboxLayer;
    }
  });
  return exaggerationLayer;
}

function addStyleLayers(layerOptions, mapboxLayerConfigs, suffix, sourceId, hideIds, outputLayers) {
  const hideEditingFilter = hideIds[0] ? hideIds.map((id) => ['!=', id, ['get', layerOptions.keyField]]) : null;
  if (hideEditingFilter) {
    hideEditingFilter.unshift('all');
  }
  mapboxLayerConfigs.forEach((mapboxLayerConfig) => {
    const mapboxLayerClone = {};
    deepCopy(mapboxLayerConfig, mapboxLayerClone);
    mapboxLayerClone.source = sourceId;
    mapboxLayerClone.id = mapboxLayerConfig.id + suffix;
    if (suffix) {
      //Для geojson слоя
      delete mapboxLayerClone['source-layer'];
    }
    if (hideEditingFilter) {
      //Скрываем редактируемый объект
      mapboxLayerClone.filter = extendFilter(mapboxLayerClone.filter, hideEditingFilter);
    }
    outputLayers[mapboxLayerClone.id] = mapboxLayerClone;
  });
}

/**
 * Фабрика по созданию объектов для настройки списков базовых карт
 * @param {*} state
 * @param {*} layerComponentId
 * @param {*} selected
 */
function baseMapListItemFactory(state, layerComponentId, selected) {
  const layerOptions = state.componentOptions[layerComponentId];

  //Добавим костыль, который хардкодит iconUrl для базовых карт, которые встречались в системе
  let iconUrl = layerOptions.iconUrl;
  if (layerOptions.type === 'yandex#map') {
    iconUrl = require('@/assets/bm/bm-yandex.png');
  } else if (layerOptions.type === 'yandex#satellite') {
    iconUrl = require('@/assets/bm/bm-yandex-sat.png');
  } else if (layerOptions.url.includes('basemaps.cartocdn.com')) {
    iconUrl = require('@/assets/bm/bm-carto-night.png');
  } else if (layerOptions.url.includes('google.com/maps/vt?lyrs=m@189&gl=cn&x={x}&y={y}&z={z}')) {
    //Гугл карта
    iconUrl = require('@/assets/bm/bm-google.png');
  } else if (layerOptions.url.includes('google.com/maps/vt?lyrs=s@189&gl=cn&x={x}&y={y}&z={z}')) {
    //Гугл снимки
    iconUrl = require('@/assets/bm/bm-google-sat.png');
  } else if (layerOptions.url.includes('google.com/maps/vt?lyrs=h@189&gl=cn&x={x}&y={y}&z={z}')) {
    //Гугл подписи
    iconUrl = require('@/assets/bm/bm-google-hyb.png');
  } else if (layerOptions.url.includes('tiles.maps.sputnik.ru')) {
    iconUrl = require('@/assets/bm/bm-sputnik.png');
  } else if (layerOptions.url.includes('maps.2gis.com/tiles')) {
    iconUrl = require('@/assets/bm/bm-2gis.png');
  } else if (layerOptions.url.includes('tile.openstreetmap.org/')) {
    iconUrl = require('@/assets/bm/bm-osm.png');
  } else if (layerOptions.url.includes('mapbox/light-v10')) {
    // mapbox Light
    iconUrl = require('@/assets/bm/mb-light.png');
  } else if (layerOptions.url.includes('mapbox/dark-v10')) {
    // mapbox Dark
    iconUrl = require('@/assets/bm/mb-dark.png');
  } else if (layerOptions.url.includes('mapbox/streets-v11')) {
    // mapbox Streets
    iconUrl = require('@/assets/bm/mb-streets.png');
  } else if (layerOptions.url.includes('mapbox/outdoors-v11')) {
    // mapbox Outdoors
    iconUrl = require('@/assets/bm/mb-outdoors.png');
  } else if (layerOptions.url.includes('mapbox/satellite-streets-v11')) {
    // mapbox Imagery
    iconUrl = require('@/assets/bm/mb-imagery.png');
  }

  return {
    componentId: layerComponentId,
    caption: layerOptions.text,
    type: layerOptions.type,
    iconUrl: iconUrl,
    selected
  };
}

/**
 * Добавление слоев для подсветки идентификации
 */
function addIdentificationResultLayer(getters, layerComponentId, featureId, mapboxSources, mapboxLayers) {
  const layerType = getters[getOptions](layerComponentId).type;
  const sourceId = getters[getMapboxSourceId](layerComponentId, layerType);
  if (!mapboxSources[sourceId]) {
    return;
  }
  //Основной слой с этим источником. Он должен быть уже добавлен
  const mainMapboxLayer = Object.values(mapboxLayers).find((item) => item.source === sourceId) || {};

  const keyField = mapboxSources[sourceId].promoteId || 'id';
  let layerId = IDENT_RESULT_LAYER + '_line';
  mapboxLayers[layerId] = {
    id: layerId,
    source: sourceId,
    type: 'line',
    ['source-layer']: mainMapboxLayer['source-layer'],
    paint: {
      'line-color': '#00bfff',
      'line-width': 4,
      'line-opacity': 1,
      'line-translate': [0, 0]
    },
    filter: [
      'all',
      ['==', ['get', keyField], +featureId || featureId],
      ['in', ['geometry-type'], ['literal', ['LineString', 'MultiLineString', 'Polygon', 'MultiPolygon']]]
    ],
    layout: {
      visibility: 'visible'
    },
    maxzoom: 24,
    minzoom: 0
  };

  layerId = IDENT_RESULT_LAYER + '_circle';
  mapboxLayers[layerId] = {
    id: layerId,
    source: sourceId,
    type: 'circle',
    ['source-layer']: mainMapboxLayer['source-layer'],
    paint: {
      'circle-color': 'transparent',
      'circle-radius': 10,
      'circle-opacity': 1,
      'circle-stroke-color': '#00bfff',
      'circle-stroke-width': 4,
      'circle-stroke-opacity': 1
    },
    filter: ['all', ['==', ['get', keyField], +featureId || featureId], ['in', ['geometry-type'], ['literal', ['Point', 'MultiPoint']]]],
    maxzoom: 24,
    minzoom: 0
  };
}

/**
 * Добавление слоев для подсветки идентификации
 * @param {*} mapboxSources
 * @param {*} mapboxLayers
 */
function addIdentificationLayer(mapboxSourceId, mapboxSourceLayer, featureId, keyField, mapboxLayers) {
  const lineLayerId = 'identificationLayerLine';
  mapboxLayers[lineLayerId] = {
    id: lineLayerId,
    source: mapboxSourceId,
    type: 'line',
    paint: {
      'line-color': '#00bfff',
      'line-width': 4,
      'line-opacity': 1,
      'line-translate': [0, 0]
    },
    filter: [
      'all',
      ['==', ['get', keyField], +featureId || featureId],
      ['in', ['geometry-type'], ['literal', ['LineString', 'MultiLineString', 'Polygon', 'MultiPolygon']]]
    ],
    layout: {
      visibility: 'visible'
    },
    maxzoom: 24,
    minzoom: 0
  };

  const circleLayerId = 'identificationLayerCircle';
  mapboxLayers[circleLayerId] = {
    id: circleLayerId,
    source: mapboxSourceId,
    type: 'circle',
    paint: {
      'circle-color': 'transparent',
      'circle-radius': 10,
      'circle-opacity': 1,
      'circle-stroke-color': '#00bfff',
      'circle-stroke-width': 4,
      'circle-stroke-opacity': 1
    },
    filter: ['all', ['==', ['get', keyField], +featureId || featureId], ['in', ['geometry-type'], ['literal', ['Point', 'MultiPoint']]]],
    maxzoom: 24,
    minzoom: 0
  };

  if (mapboxSourceLayer) {
    mapboxLayers[lineLayerId]['source-layer'] = mapboxSourceLayer;
    mapboxLayers[circleLayerId]['source-layer'] = mapboxSourceLayer;
  }
}

/**
 * Добавление слоев для подсветки выделения ПКК (специальный WMS-сервис)
 * @param {*} mapboxSources
 * @param {*} mapboxLayers
 * @param {String || Array<String>} pkkObjectId
 */
function addPkkIdentificationSourceAndLayer(mapboxSources, mapboxLayers, feature) {
  const sourceId = 'pkkIdentificationSource';
  const layerId = 'pkkIdentificationLayer';

  mapboxSources[sourceId] = {
    type: 'geojson',
    data: {
      type: 'FeatureCollection',
      features: [feature]
    }
  };

  mapboxLayers[layerId] = {
    id: layerId,
    type: 'fill',
    source: sourceId,
    paint: {
      'fill-color': '#fff033',
      'fill-opacity': 0.7
    },
    maxzoom: 24,
    minzoom: 0
  };
}

/**
 * Добавление слоев для подсветки активной точки табличного редактора координат
 * @param {*} mapboxSources
 * @param {*} mapboxLayers
 * @param {*} geojson
 */
function addCoordinateTableSourceAndLayer(mapboxSources, mapboxLayers, geojson) {
  const sourceId = 'coordinateTableSource';
  const layerId = 'coordinateTableLayer';

  mapboxSources[sourceId] = {
    type: 'geojson',
    data: {
      type: 'FeatureCollection',
      features: geojson
    }
  };

  mapboxLayers[layerId] = {
    id: layerId,
    type: 'circle',
    source: sourceId,
    paint: {
      'circle-color': '#fbb03b',
      'circle-radius': 6,
      'circle-opacity': 1,
      'circle-stroke-color': '#fff',
      'circle-stroke-width': 2,
      'circle-stroke-opacity': 1
    },
    maxzoom: 24,
    minzoom: 0
  };
}

function addWmsIdentificationSourceAndLayer(mapboxSources, mapboxLayers, mapboxGeoJson) {
  const sourceId = 'wmsIdentificationSource';
  const layerId = 'wmsIdentificationLayer';

  mapboxSources[sourceId] = {
    type: 'geojson',
    data: mapboxGeoJson
  };

  const type = mapboxGeoJson.geometry.type === 'Point' ? 'circle' : 'line';
  const paint =
    mapboxGeoJson.geometry.type === 'Point'
      ? {
          'circle-color': 'transparent',
          'circle-radius': 10,
          'circle-opacity': 1,
          'circle-stroke-color': '#00bfff',
          'circle-stroke-width': 4,
          'circle-stroke-opacity': 1
        }
      : {
          'line-color': '#00bfff',
          'line-width': 4,
          'line-opacity': 1,
          'line-translate': [0, 0]
        };

  mapboxLayers[layerId] = {
    id: layerId,
    type,
    source: sourceId,
    paint,
    maxzoom: 24,
    minzoom: 0
  };
}

function addTempCosmeticLayers(mapboxSources, mapboxLayers, tempCosmeticLayers) {
  for (const item of tempCosmeticLayers) {
    const sourceId = item.id + '_source';
    //console.log('redraw ' + item.source.data.geometry.coordinates);
    mapboxSources[sourceId] = item.source;
    item.layers.forEach((layer, index) => {
      const layerid = item.id + '_layer_' + index;
      mapboxLayers[layerid] = { ...layer, id: layerid, source: sourceId };
    });
  }
}

function addDigitizedSourceAndLayer(mapboxSources, mapboxLayers, geoJson) {
  const SOURCE_ID = 'digitizedSource';
  const LAYER_LINE_ID = 'digitizedLayer_line';
  const LAYER_POINT_ID = 'digitizedLayer_point';

  mapboxSources[SOURCE_ID] = {
    type: 'geojson',
    data: {
      type: 'FeatureCollection',
      features: geoJson
    }
  };

  mapboxLayers[LAYER_LINE_ID] = {
    id: LAYER_LINE_ID,
    type: 'line',
    source: SOURCE_ID,
    paint: {
      'line-color': '#00bfff',
      'line-width': 2,
      'line-opacity': 1
    }
  };
  mapboxLayers[LAYER_POINT_ID] = {
    id: LAYER_POINT_ID,
    type: 'circle',
    source: SOURCE_ID,
    paint: {
      'circle-radius': 2,
      'circle-color': '#00bfff',
      'circle-opacity': 1,
      'circle-stroke-color': '#867e6e',
      'circle-stroke-width': 1,
      'circle-stroke-opacity': 1
    }
  };
}

function addSnappingSourceAndLayer(mapboxSources, mapboxLayers, activeSnapLngLat) {
  const SNAPPING_SOURCE = 'snappingSource';
  const SNAPPING_LAYER = 'snappingLayer';

  mapboxSources[SNAPPING_SOURCE] = {
    type: 'geojson',
    data: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: activeSnapLngLat
          }
        }
      ]
    }
  };

  mapboxLayers[SNAPPING_LAYER] = {
    id: SNAPPING_LAYER,
    type: 'circle',
    source: SNAPPING_SOURCE,
    paint: {
      'circle-color': 'transparent',
      'circle-radius': 8,
      'circle-stroke-width': 1,
      'circle-stroke-color': '#3388ff'
    }
  };
}

const BUFFER_SELECTION_SOURCE = 'bufferSource';
const BUFFER_SELECTION_LAYER = 'bufferLayer';
const BUFFER_SELECTION_PAINT = {
  'line-color': '#c378e2',
  'line-width': 2,
  'line-dasharray': [5, 5]
};
const RADIUS_SELECTION_CENTER_LAYER = 'circleRadiusCenter';
const RADIUS_SELECTION_PAINT = {
  'circle-color': '#ffffff',
  'circle-radius': 4,
  'circle-stroke-width': 2,
  'circle-stroke-color': '#c378e2'
};

const SELECTED_OBJECT_INTERSECT_SOURCE = 'selectedObjectItersectSource';
const SELECTED_OBJECT_INTERSECT_LAYERS = {
  selectedObjectIntersectLineLayer: {
    type: 'line',
    paint: {
      'line-color': '#FF0000',
      'line-width': 2,
      'line-dasharray': [5, 5]
    }
  },
  selectedObjectIntersectPointLayer: {
    type: 'circle',
    paint: {
      'circle-color': '#ffffff',
      'circle-radius': 4,
      'circle-stroke-width': 3,
      'circle-stroke-color': '#ec4242'
    }
  }
};

const SELECTED_OBJECT_INTERSECT_PAINT = {
  'line-color': '#FF0000',
  'line-width': 2,
  'line-dasharray': [5, 5]
};

const BINDING_TILE_SOURCE = 'bindingTileSource';
const BINDING_TILE_LAYER = 'bindingTileLayer';
const BINDING_TILE_PAINT = {
  'line-color': '#FF0000',
  'line-width': 6
};

const KINKS_SOURCE = 'kinksSource';
const KINKS_LAYER = 'kinksLayer';
const KINKS_LAYER_PAINT = {
  'circle-color': 'transparent',
  'circle-radius': 5,
  'circle-opacity': 1,
  'circle-stroke-color': '#d9534f',
  'circle-stroke-width': 3,
  'circle-stroke-opacity': 1
};

const POINT_AVAILABILITY_LAYER = 'pointAvailability_stroke';
const POINT_AVAILABILITY_ACTIVE_LAYER = 'pointAvailabilityActive_stroke';
const POINT_AVAILABILITY_SOURCE = 'pointAvailabilitySource';
const POINT_AVAILABILITY_ORGANISATION_SOURCE = 'pointAvailabilityOrganisationSource';
const VALID_ORGANISATION_LAYER = 'pointAvailabilityOrganisation_valid_stroke';
const INVALID_ORGANISATION_LAYER = 'pointAvailabilityOrganisation_invalid_stroke';
const DEFAULT_ORGANISATION_LAYER = 'pointAvailabilityOrganisation_default_stroke';

const POINT_AVAILABILITY_STYLES_AUTO = [
  {
    paint: {
      'line-color': '#009900'
    },
    filter: ['<', ['get', 'arrival_time'], 0.25]
  },
  {
    paint: {
      'line-color': '#ff9900'
    },
    filter: ['all', ['>=', ['get', 'arrival_time'], 0.25], ['<', ['get', 'arrival_time'], 0.5]]
  },
  {
    paint: {
      'line-color': '#ff3300'
    },
    filter: ['>=', ['get', 'arrival_time'], 0.5]
  }
];

const POINT_AVAILABILITY_STYLES_HIKE = [
  {
    paint: {
      'line-color': '#009900'
    },
    filter: ['<', ['get', 'arrival_time'], 0.17]
  },
  {
    paint: {
      'line-color': '#ff9900'
    },
    filter: ['all', ['>=', ['get', 'arrival_time'], 0.17], ['<', ['get', 'arrival_time'], 0.4]]
  },
  {
    paint: {
      'line-color': '#ff3300'
    },
    filter: ['>=', ['get', 'arrival_time'], 0.4]
  }
];

const ROUTE_LAYER = 'route_stroke';
const ROUTE_SOURCE = 'routeSource';

const HEATMAP_LAYER = 'heatmap_stroke';
const HEATMAP_SOURCE = 'heatmapSource';

const IDENT_RESULT_LAYER = 'ident_result';

function addChartSourceAndLayer(componentId, getters, mapboxSources, mapboxLayers) {
  const mapDiagramData = getters[getOptions](componentId).mapDiagramData || null;
  if (mapDiagramData) {
    for (const sourceId in mapDiagramData.sources) {
      mapboxSources[sourceId] = mapDiagramData.sources[sourceId];
    }
    for (const layerId in mapDiagramData.layers) {
      mapboxLayers[layerId] = mapDiagramData.layers[layerId];
    }
  }
}

/**Подключение слоев для выбора и просмотра спутниковых снимков*/
function addSpaceImageryRastersSourceAndLayer(componentId, getters, mapboxSources, mapboxLayers) {
  const { spaceImageryFoundFeatures, spaceImageryVisibleFeatureIds } = getters[getOptions](componentId);

  const spaceImageryVisibleFeatures = spaceImageryFoundFeatures.filter((feature) => spaceImageryVisibleFeatureIds.includes(feature.properties.id));
  //Для выбранных объектов отобразить снимки
  spaceImageryVisibleFeatures.forEach((imageFeature) => {
    const imageId = imageFeature.properties.imageid;
    const bbox = imageFeature.properties.bbox;
    mapboxSources[imageId] = {
      type: 'image',
      url: imageFeature.properties.thumbnail,
      coordinates: [
        [bbox[0], bbox[3]],
        [bbox[2], bbox[3]],
        [bbox[2], bbox[1]],
        [bbox[0], bbox[1]]
      ]
    };

    mapboxLayers[imageId] = {
      id: imageId,
      type: 'raster',
      source: imageId
    };
  });
}

/**Подключение слоев для выбора и просмотра спутниковых снимков*/
function addSpaceImageryFeaturesSourceAndLayer(componentId, getters, mapboxSources, mapboxLayers) {
  const SPACE_IMAGERY_SOURCE = 'spaceImagerySource';
  const SPACE_IMAGERY_HOVER_SOURCE = 'spaceImageryHoverSource';
  const SPACE_IMAGERY_LAYER = 'spaceImageryGeoJsonLayer';
  const SPACE_IMAGERY_HOVER_LAYER = 'spaceImageryGeoJsonHoverLayer';
  const SPACE_IMAGERY_FILL_LAYER = 'spaceImageryGeoJsonFillLayer';
  const SPACE_IMAGERY_HOVER_FILL_LAYER = 'spaceImageryGeoJsonHoverFillLayer';

  const SPACE_IMAGERY_PAINT = {
    'line-color': '#ffcd41',
    'line-width': 2
  };

  const SPACE_IMAGERY_HOVER_PAINT = {
    'line-color': '#3388ff',
    'line-width': 2
  };

  const { spaceImageryFoundFeatures, spaceImageryHoveredFeatureId } = getters[getOptions](componentId);

  //Отображение списка найденных контуров снимков
  if (spaceImageryFoundFeatures[0]) {
    mapboxSources[SPACE_IMAGERY_SOURCE] = {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: spaceImageryFoundFeatures
      }
    };

    mapboxLayers[SPACE_IMAGERY_LAYER] = {
      id: SPACE_IMAGERY_LAYER,
      type: 'line',
      source: SPACE_IMAGERY_SOURCE,
      paint: SPACE_IMAGERY_PAINT
    };

    mapboxLayers[SPACE_IMAGERY_FILL_LAYER] = {
      id: SPACE_IMAGERY_FILL_LAYER,
      type: 'fill',
      source: SPACE_IMAGERY_SOURCE,
      paint: {
        'fill-color': 'transparent'
      }
    };
  }

  const spaceImageryHoverFeatures = spaceImageryFoundFeatures.filter((feature) => feature.properties.id === spaceImageryHoveredFeatureId);
  //Отображение контура под мышью
  if (spaceImageryHoverFeatures[0]) {
    mapboxSources[SPACE_IMAGERY_HOVER_SOURCE] = {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: spaceImageryHoverFeatures
      }
    };

    mapboxLayers[SPACE_IMAGERY_HOVER_LAYER] = {
      id: SPACE_IMAGERY_HOVER_LAYER,
      type: 'line',
      source: SPACE_IMAGERY_HOVER_SOURCE,
      paint: SPACE_IMAGERY_HOVER_PAINT
    };

    mapboxLayers[SPACE_IMAGERY_HOVER_FILL_LAYER] = {
      id: SPACE_IMAGERY_HOVER_FILL_LAYER,
      type: 'fill',
      source: SPACE_IMAGERY_HOVER_SOURCE,
      paint: {
        'fill-color': 'transparent'
      }
    };
  }
}

function addBufferSelectionSourceAndLayer(mapboxSources, mapboxLayers, bufferSelectionGeoJson) {
  mapboxSources[BUFFER_SELECTION_SOURCE] = {
    type: 'geojson',
    data: bufferSelectionGeoJson
  };

  mapboxLayers[BUFFER_SELECTION_LAYER] = {
    id: BUFFER_SELECTION_LAYER,
    type: 'line',
    source: BUFFER_SELECTION_SOURCE,
    paint: BUFFER_SELECTION_PAINT,
    filter: ['all', ['==', ['geometry-type'], 'Polygon']]
  };

  mapboxLayers[RADIUS_SELECTION_CENTER_LAYER] = {
    id: RADIUS_SELECTION_CENTER_LAYER,
    type: 'circle',
    source: BUFFER_SELECTION_SOURCE,
    paint: RADIUS_SELECTION_PAINT,
    filter: ['all', ['==', ['geometry-type'], 'Point']]
  };
}

function addSelectedObjectIntersectLayer(mapboxSources, mapboxLayers, selectedObjectIntersectGeoJson) {
  mapboxSources[SELECTED_OBJECT_INTERSECT_SOURCE] = {
    type: 'geojson',
    data: selectedObjectIntersectGeoJson
  };

  for (const layerId in SELECTED_OBJECT_INTERSECT_LAYERS) {
    mapboxLayers[layerId] = {
      ...SELECTED_OBJECT_INTERSECT_LAYERS[layerId],
      id: layerId,
      source: SELECTED_OBJECT_INTERSECT_SOURCE
    };
  }
}

function addBindingTileLayer(mapboxSources, mapboxLayers, bindingTileLayerGeoJson) {
  mapboxSources[BINDING_TILE_SOURCE] = {
    type: 'geojson',
    data: bindingTileLayerGeoJson
  };

  mapboxLayers[BINDING_TILE_LAYER] = {
    id: BINDING_TILE_LAYER,
    type: 'line',
    source: BINDING_TILE_SOURCE,
    paint: BINDING_TILE_PAINT
  };
}

function addInterpolateHeatmapCanvasLayer(mapboxSources, mapboxLayers, coordArray) {
  mapboxSources[HEATMAP_SOURCE] = {
    type: 'canvas',
    canvas: 'heatmap-canvas',
    coordinates: coordArray.slice(0, -1),
    animate: false
  };

  mapboxLayers[HEATMAP_LAYER] = {
    id: HEATMAP_LAYER,
    type: 'raster',
    source: HEATMAP_SOURCE
  };
}

function addPointAvailabilityNearOrganisationLayer(
  mapboxSources,
  mapboxLayers,
  pointAvailabilityNearOrganisation,
  pointAvailabilityIsZdravLayer,
  pointAvailabilityRouteMode
) {
  mapboxSources[POINT_AVAILABILITY_ORGANISATION_SOURCE] = {
    type: 'geojson',
    data: pointAvailabilityNearOrganisation
  };
  if (pointAvailabilityIsZdravLayer) {
    mapboxLayers[VALID_ORGANISATION_LAYER] = {
      id: VALID_ORGANISATION_LAYER,
      source: POINT_AVAILABILITY_ORGANISATION_SOURCE,
      type: 'symbol',
      filter: ['==', ['get', 'is_valid'], true],
      layout: { 'icon-image': pointAvailabilityRouteMode === 0 ? 'footRouteIcon' : 'transportRouteIcon', 'icon-size': 1 }
    };

    mapboxLayers[INVALID_ORGANISATION_LAYER] = {
      id: INVALID_ORGANISATION_LAYER,
      source: POINT_AVAILABILITY_ORGANISATION_SOURCE,
      type: 'symbol',
      filter: ['==', ['get', 'is_valid'], false],
      layout: { 'icon-image': 'notFitIcon', 'icon-size': 1 }
    };
  } else {
    mapboxLayers[DEFAULT_ORGANISATION_LAYER] = {
      id: DEFAULT_ORGANISATION_LAYER,
      source: POINT_AVAILABILITY_ORGANISATION_SOURCE,
      type: 'symbol',
      layout: { 'icon-image': 'defaultRouteIcon', 'icon-size': 0.5 }
    };
  }
}

function addPointAvailabilityObjectPathLayer(
  mapboxSources,
  mapboxLayers,
  pointAvailabilityObjectPath,
  pointAvailabilityRouteMode,
  pointAvailabilityActiveRouteObjectId
) {
  mapboxSources[POINT_AVAILABILITY_SOURCE] = {
    type: 'geojson',
    data: pointAvailabilityObjectPath
  };

  const mapboxLayerBase = {
    id: POINT_AVAILABILITY_LAYER,
    source: POINT_AVAILABILITY_SOURCE,
    type: 'line'
  };

  /*mapboxLayers[POINT_AVAILABILITY_LAYER] = {
    id: POINT_AVAILABILITY_LAYER,
    source: POINT_AVAILABILITY_SOURCE,
    type: 'line'
  };*/

  if (pointAvailabilityRouteMode === 1) {
    //Транспортная доступность
    mapboxLayerBase.paint = {
      'line-color': '#42DDFF',
      'line-width': 7
    };
    //Раскраска маршрутов по фильтрам
    POINT_AVAILABILITY_STYLES_AUTO.forEach((mapboxLayer, index) => {
      const layerId = `${POINT_AVAILABILITY_LAYER}_${index}`;
      const mapboxLayerNew = deepMerge({}, mapboxLayerBase, mapboxLayer, { id: layerId });
      mapboxLayers[layerId] = mapboxLayerNew;
    });
  } else if (pointAvailabilityRouteMode === 0) {
    //Пешая доступность
    mapboxLayerBase.paint = {
      'line-color': '#CE65FF',
      'line-width': 7,
      'line-dasharray': [2, 2]
    };
    //Раскраска маршрутов по фильтрам
    POINT_AVAILABILITY_STYLES_HIKE.forEach((mapboxLayer, index) => {
      const layerId = `${POINT_AVAILABILITY_LAYER}_${index}`;
      const mapboxLayerNew = deepMerge({}, mapboxLayerBase, mapboxLayer, { id: layerId });
      mapboxLayers[layerId] = mapboxLayerNew;
    });
  }

  mapboxLayers[POINT_AVAILABILITY_ACTIVE_LAYER] = {
    id: POINT_AVAILABILITY_ACTIVE_LAYER,
    source: POINT_AVAILABILITY_SOURCE,
    type: 'line',
    paint: {
      'line-color': '#3366ff',
      'line-width': 7
    },
    filter: ['==', ['get', 'object_id'], pointAvailabilityActiveRouteObjectId]
  };
}

function addRoutePointsLayer(mapboxSources, mapboxLayers, routePoints, pointAvailabilityRouteMode) {
  mapboxSources[ROUTE_SOURCE] = {
    type: 'geojson',
    data: { features: routePoints, type: 'FeatureCollection' }
  };

  mapboxLayers[ROUTE_LAYER] = {
    id: ROUTE_LAYER,
    source: ROUTE_SOURCE,
    type: 'circle',
    paint: {
      'circle-color': ['get', 'color'],
      'circle-radius': 10,
      'circle-opacity': 1
    }
  };
}

function addReportSourceAndLayer(getters, reportLayerComponentId, reportFeatureId, mapboxSources, mapboxLayers) {
  const { type } = getters[getLayerOptions](reportLayerComponentId);

  //Добавление источника при необходимости
  const tileSourceId = getters[getMapboxSourceId](reportLayerComponentId, type);
  if (!mapboxSources[tileSourceId]) {
    const tileSourceConfig = getters[getMapboxSourceConfig](reportLayerComponentId, type);

    if (!tileSourceConfig) {
      return;
    }

    mapboxSources[tileSourceId] = tileSourceConfig;
  }

  //Добавление слоя
  const mapboxLayerConfigs = getters[getMapboxLayerConfigs](reportLayerComponentId, true);
  if (!mapboxLayerConfigs || !mapboxLayerConfigs[0]) {
    return;
  }

  //getReportMapboxLayerConfigs
  getters[getSelectionMapboxLayerConfigs](reportLayerComponentId, mapboxLayerConfigs[0]).forEach((mapboxReportLayerConfig, index) => {
    const newConfig = deepCopy(mapboxReportLayerConfig);
    newConfig.source = tileSourceId;
    newConfig.id = `${reportLayerComponentId}_report_${index}`;
    mapboxLayers[newConfig.id] = newConfig;
  });
}

function extendFilter(filter, newFilter) {
  if (filter && filter[0]) {
    return ['all', filter, newFilter];
  } else {
    return newFilter;
  }
}

function foreachLayerRecursive(layerComponentId, getters, callback) {
  callback(layerComponentId);
  getters[getChildLayerComponentIds](layerComponentId).forEach((childComponentId) => {
    foreachLayerRecursive(childComponentId, getters, callback);
  });
}

function addKinksLayer(getters, mapboxSources, mapboxLayers, editingLayerComponentId) {
  const kinks = getters[getKinksInEditingFeatures](editingLayerComponentId);

  mapboxSources[KINKS_SOURCE] = {
    type: 'geojson',
    data: {
      type: 'FeatureCollection',
      features: kinks
    }
  };

  mapboxLayers[KINKS_LAYER] = {
    id: KINKS_LAYER,
    type: 'circle',
    source: KINKS_SOURCE,
    paint: KINKS_LAYER_PAINT
  };
}
