import Vue from 'vue';

//Page - основной компонент
import Page from './components/Containers/Page';
Vue.component('Page', Page);

//Диаграммы
Vue.component('BarGauge', () => import(/* webpackChunkName: "charts" */ './components/Charts/BarGauge'));
Vue.component('Bullet', () => import(/* webpackChunkName: "charts" */ './components/Charts/Bullet'));
Vue.component('Chart', () => import(/* webpackChunkName: "charts" */ './components/Charts/Chart'));
Vue.component('CircularGauge', () => import(/* webpackChunkName: "charts" */ './components/Charts/CircularGauge'));
Vue.component('Funnel', () => import(/* webpackChunkName: "charts" */ './components/Charts/Funnel'));
Vue.component('IntervalChart', () => import(/* webpackChunkName: "charts" */ './components/Charts/IntervalChart'));
Vue.component('LinearGauge', () => import(/* webpackChunkName: "charts" */ './components/Charts/LinearGauge'));
Vue.component('PieChart', () => import(/* webpackChunkName: "charts" */ './components/Charts/PieChart'));
Vue.component('PolarChart', () => import(/* webpackChunkName: "charts" */ './components/Charts/PolarChart'));
Vue.component('BulletChart', () => import(/* webpackChunkName: "charts" */ './components/Charts/BulletChart'));
Vue.component('RangeSelector', () => import(/* webpackChunkName: "charts" */ './components/Charts/RangeSelector'));
Vue.component('RangeSelectorSet', () => import(/* webpackChunkName: "charts" */ './components/Charts/RangeSelectorSet'));
Vue.component('Sankey', () => import(/* webpackChunkName: "charts" */ './components/Charts/Sankey'));
Vue.component('Sparkline', () => import(/* webpackChunkName: "charts" */ './components/Charts/Sparkline'));
Vue.component('TreeMap', () => import(/* webpackChunkName: "charts" */ './components/Charts/TreeMap'));
Vue.component('TimelineChart', () => import(/* webpackChunkName: "charts" */ './components/Charts/TimelineChart'));
Vue.component('VectorMap', () => import(/* webpackChunkName: "charts" */ './components/Charts/VectorMap'));
Vue.component('ChartColorForm', () => import(/* webpackChunkName: "charts" */ './components/Charts/ChartColorForm'));

//Контейнеры
Vue.component('Accordion', () => import(/* webpackChunkName: "base-components" */ './components/Containers/Accordion'));
Vue.component('Frame', () => import(/* webpackChunkName: "base-components" */ './components/Containers/Frame'));
Vue.component('CardTabs', () => import(/* webpackChunkName: "base-components" */ './components/Containers/CardTabs/CardTabs'));
Vue.component('Drawer', () => import(/* webpackChunkName: "base-components" */ './components/Containers/Drawer'));
Vue.component('Form', () => import(/* webpackChunkName: "base-components" */ './components/Containers/Form'));
Vue.component('GridLayout', () => import(/* webpackChunkName: "base-components" */ './components/Containers/GridLayout'));
Vue.component('Group', () => import(/* webpackChunkName: "base-components" */ './components/Containers/Group'));
Vue.component('HorizontalLayout', () => import(/* webpackChunkName: "base-components" */ './components/Containers/HorizontalLayout'));
Vue.component('Popover', () => import(/* webpackChunkName: "base-components" */ './components/Containers/Popover'));
Vue.component('Popup', () => import(/* webpackChunkName: "base-components" */ './components/Containers/Popup'));
Vue.component('ScrollView', () => import(/* webpackChunkName: "base-components" */ './components/Containers/ScrollView'));
Vue.component('Splitter', () => import(/* webpackChunkName: "base-components" */ './components/Containers/Splitter'));
Vue.component('TabPanel', () => import(/* webpackChunkName: "base-components" */ './components/Containers/TabPanel'));
Vue.component('TabPanelItem', () => import(/* webpackChunkName: "base-components" */ './components/Containers/TabPanelItem'));
Vue.component('Toolbar', () => import(/* webpackChunkName: "base-components" */ './components/Containers/Toolbar'));
Vue.component('VerticalLayout', () => import(/* webpackChunkName: "base-components" */ './components/Containers/VerticalLayout'));

//Поля ввода
Vue.component('Autocomplete', () => import(/* webpackChunkName: "base-components" */ './components/Editors/Autocomplete'));
Vue.component('Calendar', () => import(/* webpackChunkName: "base-components" */ './components/Editors/Calendar'));
Vue.component('CheckBox', () => import(/* webpackChunkName: "base-components" */ './components/Editors/CheckBox'));
Vue.component('CheckList', () => import(/* webpackChunkName: "base-components" */ './components/Editors/CheckList/CheckList'));
Vue.component('ColorBox', () => import(/* webpackChunkName: "base-components" */ './components/Editors/ColorBox'));
Vue.component('daDataLookup', () => import(/* webpackChunkName: "base-components" */ './components/Editors/DaData'));
Vue.component('DateBox', () => import(/* webpackChunkName: "base-components" */ './components/Editors/DateBox'));
Vue.component('DropDownBox', () => import(/* webpackChunkName: "base-components" */ './components/Editors/DropDownBox'));
Vue.component('FileUploader', () => import(/* webpackChunkName: "base-components" */ './components/Editors/FileUploader'));
Vue.component('FilterBuilder', () => import(/* webpackChunkName: "base-components" */ './components/Editors/FilterBuilder'));
Vue.component('GeocoderAutocomplete', () => import(/* webpackChunkName: "base-components" */ './components/Editors/GeocoderAutocomplete'));
Vue.component('Lookup', () => import(/* webpackChunkName: "base-components" */ './components/Editors/Lookup'));
Vue.component('NumberBox', () => import(/* webpackChunkName: "base-components" */ './components/Editors/NumberBox'));
Vue.component('RadioGroup', () => import(/* webpackChunkName: "base-components" */ './components/Editors/RadioGroup'));
Vue.component('RangeSlider', () => import(/* webpackChunkName: "base-components" */ './components/Editors/RangeSlider'));
Vue.component('Rating', () => import(/* webpackChunkName: "base-components" */ './components/Editors/Rating'));
Vue.component('Scale', () => import(/* webpackChunkName: "base-components" */ './components/Editors/Scale'));
Vue.component('SelectBox', () => import(/* webpackChunkName: "base-components" */ './components/Editors/SelectBox'));
Vue.component('Slider', () => import(/* webpackChunkName: "base-components" */ './components/Editors/Slider/Slider'));
Vue.component('Switcher', () => import(/* webpackChunkName: "base-components" */ './components/Editors/Switch'));
Vue.component('SwitchTaskState', () => import(/* webpackChunkName: "base-components" */ './components/Editors/SwitchTaskState'));
Vue.component('TagBox', () => import(/* webpackChunkName: "base-components" */ './components/Editors/TagBox'));
Vue.component('TagCloud', () => import(/* webpackChunkName: "base-components" */ './components/Editors/TagCloud'));
Vue.component('TagBoxObjectList', () => import(/* webpackChunkName: "base-components" */ './components/Editors/TagBoxObjectList'));
Vue.component('TextArea', () => import(/* webpackChunkName: "base-components" */ './components/Editors/TextArea'));
Vue.component('TextBox', () => import(/* webpackChunkName: "base-components" */ './components/Editors/TextBox'));
Vue.component('TimeIntervalGroup', () => import(/* webpackChunkName: "base-components" */ './components/Editors/TimeIntervalGroup'));

//Остальное
Vue.component('AvatarBlock', () => import(/* webpackChunkName: "base-components" */ './components/AvatarBlock'));
Vue.component('bpmn', () => import(/* webpackChunkName: "bpmn" */ './components/Bpmn/Bpmn'));
Vue.component('Button', () => import(/* webpackChunkName: "base-components" */ './components/Button/Button'));
Vue.component('RoundButton', () => import(/* webpackChunkName: "base-components" */ './components/Button/RoundButton'));
Vue.component('ToolbarButton', () => import(/* webpackChunkName: "base-components" */ './components/Button/ToolbarButton'));
Vue.component('DropSideButton', () => import(/* webpackChunkName: "base-components" */ './components/Button/DropSideButton'));
Vue.component('ButtonGroup', () => import(/* webpackChunkName: "base-components" */ './components/ButtonGroup'));
Vue.component('Chain', () => import(/* webpackChunkName: "base-components" */ './components/Chain'));
Vue.component('ContextMenu', () => import(/* webpackChunkName: "base-components" */ './components/ContextMenu'));
Vue.component('DataGrid', () => import(/* webpackChunkName: "base-components" */ './components/DataGrid/DataGrid'));
Vue.component('DataGridComments', () => import(/* webpackChunkName: "base-components" */ './components/DataGrid/DataGridComments'));
Vue.component('feed', () => import(/* webpackChunkName: "base-components" */ './components/Feed/Feed'));
Vue.component('InfoPanel', () => import(/* webpackChunkName: "base-components" */ './components/DataGrid/InfoPanel'));
Vue.component('DataSet', () => import(/* webpackChunkName: "base-components" */ './components/DataSet/DataSet'));
Vue.component('DataSetGeoJson', () => import(/* webpackChunkName: "base-components" */ './components/DataSet/DataSetGeoJson'));
Vue.component('DropDownButton', () => import(/* webpackChunkName: "base-components" */ './components/DropDownButton'));
Vue.component('Gallery', () => import(/* webpackChunkName: "base-components" */ './components/Gallery/Gallery'));
Vue.component('VerticalGallery', () => import(/* webpackChunkName: "base-components" */ './components/Gallery/VerticalGallery'));
Vue.component('Gantt', () => import(/* webpackChunkName: "gantt" */ './components/Gantt'));
Vue.component('GroupTitle', () => import(/* webpackChunkName: "base-components" */ './components/GroupTitle'));
Vue.component('HtmlContent', () => import(/* webpackChunkName: "base-components" */ './components/HtmlContent'));
Vue.component('HtmlEditor', () => import(/* webpackChunkName: "base-components" */ './components/HtmlEditor'));
Vue.component('htmlRender', () => import(/* webpackChunkName: "base-components" */ './components/HtmlRender'));
Vue.component('IconBlock', () => import(/* webpackChunkName: "base-components" */ './components/IconBlock'));
Vue.component('ImageBlock', () => import(/* webpackChunkName: "base-components" */ './components/ImageBlock'));
Vue.component('List', () => import(/* webpackChunkName: "base-components" */ './components/List/List'));
Vue.component('LoadIndicator', () => import(/* webpackChunkName: "base-components" */ './components/LoadIndicator'));
Vue.component('LoadPanel', () => import(/* webpackChunkName: "base-components" */ './components/LoadPanel'));
Vue.component('LevelContainer', () => import(/* webpackChunkName: "base-components" */ './components/Containers/LevelContainer'));
Vue.component('Menu', () => import(/* webpackChunkName: "base-components" */ './components/Menu'));
Vue.component('MockDataSet', () => import(/* webpackChunkName: "base-components" */ './components/DataSet/MockDataSet'));
Vue.component('MonacoEditor', () => import(/* webpackChunkName: "monaco-editor" */ './components/MonacoEditor'));
Vue.component('NavigationMenuButton', () => import(/* webpackChunkName: "base-components" */ './components/NavigationMenuButton'));
Vue.component('PhotoAlbum', () => import(/* webpackChunkName: "base-components" */ './components/Gallery/PhotoAlbum'));
Vue.component('PivotGrid', () => import(/* webpackChunkName: "pivot" */ './components/PivotGrid'));
Vue.component('PivotGridDataSource', () => import(/* webpackChunkName: "pivot" */ './components/DataSet/PivotGridDataSource'));
Vue.component('PivotGridFieldChooser', () => import(/* webpackChunkName: "pivot" */ './components/PivotGridFieldChooser'));
Vue.component('progressBar', () => import(/* webpackChunkName: "base-components" */ './components/ProgressBar'));
Vue.component('Scheduler', () => import(/* webpackChunkName: "scheduler" */ './components/Scheduler'));
Vue.component('ServerSideDataSource', () => import(/* webpackChunkName: "base-components" */ './components/DataSet/ServerSideDataSource'));
Vue.component('SpeedDialAction', () => import(/* webpackChunkName: "base-components" */ './components/SpeedDialAction'));
Vue.component('SummaryTileView', () => import(/* webpackChunkName: "base-components" */ './components/TileView/SummaryTileView'));
Vue.component('TableauDataSet', () => import(/* webpackChunkName: "base-components" */ './components/DataSet/TableauDataSet'));
Vue.component('TextBlock', () => import(/* webpackChunkName: "base-components" */ './components/TextBlock'));
Vue.component('InfoBlock', () => import(/* webpackChunkName: "base-components" */ './components/Charts/InfoBlock'));
Vue.component('TileCheckBox', () => import(/* webpackChunkName: "base-components" */ './components/TileView/TileCheckBox'));
Vue.component('TileView', () => import(/* webpackChunkName: "base-components" */ './components/TileView/TileView'));
Vue.component('Timer', () => import(/* webpackChunkName: "base-components" */ './components/Timer'));
Vue.component('Toast', () => import(/* webpackChunkName: "base-components" */ './components/Toast'));
Vue.component('Tooltip', () => import(/* webpackChunkName: "base-components" */ './components/Tooltip'));
Vue.component('TreeList', () => import(/* webpackChunkName: "base-components" */ './components/DataGrid/TreeList'));
Vue.component('TreeView', () => import(/* webpackChunkName: "base-components" */ './components/TreeView/TreeView'));
Vue.component('UserPanel', () => import(/* webpackChunkName: "base-components" */ './components/UserPanel'));
Vue.component('AnyComponent', () => import(/* webpackChunkName: "base-components" */ './components/AnyComponent'));
Vue.component('VideoStream', () => import(/* webpackChunkName: "video-stream" */ './components/VideoStream'));
Vue.component('VideoPlayer', () => import(/* webpackChunkName: "base-components" */ './components/VideoPlayer'));
Vue.component('MainShortcutMenu', () => import(/* webpackChunkName: "base-components" */ './components/MainShortcutMenu/MainShortcutMenu'));
Vue.component('CustomLayout', () => import(/* webpackChunkName: "base-components" */ './components/Containers/CustomLayout'));
Vue.component('YSuggest', () => import(/* webpackChunkName: "base-components" */ './components/Editors/YSuggest'));
Vue.component('LinkBox', () => import(/* webpackChunkName: "base-components" */ './components/Editors/LinkBox'));
Vue.component('DirectGeocoder', () => import(/* webpackChunkName: "base-components" */ './components/Editors/DirectGeocoder'));
Vue.component('LocalesPanel', () => import(/* webpackChunkName: "base-components" */ './components/LocalesPanel'));
Vue.component('AuthRegForm', () => import(/* webpackChunkName: "base-components" */ './components/AuthRegForm/AuthRegForm'));
Vue.component('AuthRegFormPopup', () => import(/* webpackChunkName: "base-components" */ './components/AuthRegForm/AuthRegFormPopup'));
Vue.component('TaskStatistics', () => import(/* webpackChunkName: "base-components" */ './components/Task/TaskStatistics'));
Vue.component('TaskSteps', () => import(/* webpackChunkName: "base-components" */ './components/Task/TaskSteps'));
Vue.component('TasksButton', () => import(/* webpackChunkName: "base-components" */ './components/Task/TasksButton'));
Vue.component('OperationsServiceButton', () => import(/* webpackChunkName: "base-components" */ './components/OperationService/OperationsServiceButton'));
Vue.component('CartServiceButton', () => import(/* webpackChunkName: "base-components" */ './components/CartService/CartServiceButton'));
Vue.component('Carousel', () => import(/* webpackChunkName: "base-components" */ './components/Carousel/Carousel'));
Vue.component('ObjectUrl', () => import(/* webpackChunkName: "base-components" */ './components/ObjectUrl.vue'));
// Компонент для интеграции через shadowRoot
Vue.component('ShadowDomElementIntegration', () => import(/* webpackChunkName: "base-components" */ './components/integrations/ShadowDomElementIntegration'));

//Гис
Vue.component('Gis', () => import(/* webpackChunkName: "gis" */ './components/Gis/Alternative/GisAlternative'));
Vue.component('GisEmbedded', () => import(/* webpackChunkName: "gis" */ './components/GisEmbedded/GisEmbedded'));
Vue.component('GisTablo', () => import(/* webpackChunkName: "gis" */ './components/GisTablo/GisTablo'));
Vue.component('GisLayer', () => import(/* webpackChunkName: "gis" */ './components/Gis/GisLayer'));
Vue.component('OpencvComponent', () => import(/* webpackChunkName: "opencv" */ './components/Gis/Digitizer/OpencvComponent'));

//Сервис по заявкам на компенсацию от ЧС
Vue.component('EmergencyList', () => import(/* webpackChunkName: "emergency" */ './components/Emergency/EmergencyList'));
Vue.component('EmergencyForm', () => import(/* webpackChunkName: "emergency" */ './components/Emergency/EmergencyForm'));
Vue.component('EmergencyGKNParcelList', () => import(/* webpackChunkName: "emergency" */ './components/Emergency/EmergencyGKNParcelList'));
Vue.component('EmergencyCompensationForm', () => import(/* webpackChunkName: "emergency" */ './components/Emergency/EmergencyCompensationForm'));
Vue.component('EmergencyCompensationClaimCard', () =>
  import(/* webpackChunkName: "emergency" */ './components/Emergency/CompensationClaim/CompensationClaimCard')
);
Vue.component('EmergencySelectedObjectsForm', () => import(/* webpackChunkName: "emergency" */ './components/Emergency/EmergencySelectedObjectsForm'));
Vue.component('EmergencyObjectFieldCrop', () => import(/* webpackChunkName: "emergency" */ './components/Emergency/EmergencyObjectFieldCrop'));
Vue.component('EmergencyObjectPermanentCrop', () => import(/* webpackChunkName: "emergency" */ './components/Emergency/EmergencyObjectPermanentCrop'));
Vue.component('EmergencyObjectLivestock', () => import(/* webpackChunkName: "emergency" */ './components/Emergency/EmergencyObjectLivestock'));
Vue.component('EmergencyObjectAquaculture', () => import(/* webpackChunkName: "emergency" */ './components/Emergency/EmergencyObjectAquaculture'));
Vue.component('EmergencyObjectMaterialAsset', () => import(/* webpackChunkName: "emergency" */ './components/Emergency/EmergencyObjectMaterialAsset'));
Vue.component('EmergencyObjectInfrastructureObject', () =>
  import(/* webpackChunkName: "emergency" */ './components/Emergency/EmergencyObjectInfrastructureObject')
);
Vue.component('RegisterApplications', () => import(/* webpackChunkName: "emergency" */ './components/Emergency/RegisterApplications/RegisterApplications'));

//CAdES- плагин КриптоПро для подписи
Vue.component('CAdESCertGrid', () => import(/* webpackChunkName: "CAdES" */ './components/CAdES/CAdESCertGrid'));

//bpmn
Vue.component('DataMappingGraph', () => import(/* webpackChunkName: "bpmn" */ './components/DataMappingGraph/DataMappingGraph'));

// Карточки как в интернет магазинах
Vue.component('CardList', () => import(/* webpackChunkName: "card" */ './components/Card/CardList'));
Vue.component('CardCell', () => import(/* webpackChunkName: "card" */ './components/Card/CardCell'));
Vue.component('CardCellRow', () => import(/* webpackChunkName: "card" */ './components/Card/CardCellRow'));
Vue.component('CardRow', () => import(/* webpackChunkName: "card" */ './components/Card/CardRow'));
Vue.component('Providers', () => import(/* webpackChunkName: "card" */ './components/Providers'));

Vue.component('AirGis', () => import(/* webpackChunkName: "air" */ './components/Air/AirGis'));
Vue.component('AirDevicePopup', () => import(/* webpackChunkName: "air" */ './components/Air/AirDevicePopup'));
Vue.component('AirFactoryPopup', () => import(/* webpackChunkName: "air" */ './components/Air/AirFactoryPopup'));
Vue.component('AirLaboratoryPopup', () => import(/* webpackChunkName: "air" */ './components/Air/LaboratoryPopup'));

// Кастомные компоненты для проекта ИУСТ раздела НСИ
// Панель с вкладками (внутри карточки НСИ)
Vue.component('NsiTabPanel', () => import(/* webpackChunkName: "nsi" */ './components/CustomComponents/nsi/NsiTabPanel'));
// Карточка объекта НСИ
Vue.component('NsiObjectCard', () => import(/* webpackChunkName: "nsi" */ './components/CustomComponents/nsi/NsiObjectCard'));
// Карточка класса НСИ
Vue.component('NsiClassCard', () => import(/* webpackChunkName: "nsi" */ './components/CustomComponents/nsi/NsiClassCard'));
// Кастомный компонент для проекта ИУСТ
Vue.component('LineUnitDiagram', () => import(/* webpackChunkName: "nsi" */ './components/CustomComponents/lineDiagram/LineUnitDiagram'));
Vue.component('LineUnitDiagramContainer', () => import(/* webpackChunkName: "nsi" */ './components/CustomComponents/lineDiagram/LineUnitDiagramContainer'));
// Карточка редактирования справочников
Vue.component('EditDictionaryListCard', () => import(/* webpackChunkName: "nsi" */ './components/CustomComponents/EditDictionaryListCard'));
//Компонент редактируемой таблицы, по типу Exel
Vue.component('Exel', () => import(/* webpackChunkName: "nsi" */ './components/Exel/Exel'));
