import GisLegendStyleItemOptions from '@/models/GisLegendStyleItemOptions';
import { DEFAULT_EPSG_BBOX } from '@/utils/const';

export default function GisLayerFactory(componentConfig, stateItems, rootGetters) {
  let { parentGisId, parentComponentId } = componentConfig;
  const savedLayerOptions = getLayerSavedOptions(componentConfig.componentId, parentGisId, rootGetters['config/getComponentOptions'](parentGisId));
  let showVisibleLayers = rootGetters['config/getComponentOptions'](parentGisId)?.showVisibleLayers;
  //Для базовых слоев настройка showVisibleLayers не используется
  if (componentConfig.options.baseMap || componentConfig.options.baseTextMap) {
    showVisibleLayers = true;
  }

  if (showVisibleLayers === false || ['geojson', 'wfs'].includes(componentConfig.options.type)) {
    //Для слоев с данными на клиенте не принимаем во внимание сохраненную видимость
    savedLayerOptions.visible && delete savedLayerOptions.visible;
  }
  const options = {
    ...componentConfig.options,
    ...savedLayerOptions
  };

  const gisMinimizedVisible = options.rights?.gisMinimizedVisible || false;

  const parentOptions = rootGetters['config/getComponentOptions'](parentComponentId);

  //Новый код формирования словаря настроек для компонентоа GisLegendStyleItem и массива дочерних GisLegendStyleItem
  const legendStyleItems = getLegendStyleItems(componentConfig.componentId, options, showVisibleLayers, gisMinimizedVisible);
  let legendStyleItemIds;
  if (legendStyleItems) {
    legendStyleItemIds = Object.keys(legendStyleItems).filter((legendStyleItemKey) => {
      return legendStyleItems[legendStyleItemKey].parentId === componentConfig.componentId;
    });
  }

  const newOptions = {
    /**Promise о том, что дочерние компоненты загружены */
    loadChildComponentsPromise: null,

    /**Ссылка на сервис */
    url: options.url || '',

    /**Дополнительная ссылка на wms-сервис */
    wmsUrl: options.wmsUrl || '',

    //Стили отображения
    mapboxLayers: options.mapboxLayers,

    //Стили отображения выделенных объектов
    //Учет старого атрибута
    mapboxSelectionLayers: options.mapboxSelectionLayers || options.selectionMapboxLayers || [],

    //Стили подсветки при наведении
    mapboxHoverLayers: options.mapboxHoverLayers || [],

    //Стили при пересчете интерполяции
    mapboxCalcInterpolationLayers: null,

    //Функция пересчета интерполяции при событиях карты
    //(также является индикатором активности режима)
    calcInterpolationFunction: false,

    //Объекты, подсвеченные мышью
    hoverFeatureIds: [],

    maxNativeZoom: options.maxNativeZoom || 22,

    minNativeZoom: options.minNativeZoom || 0,

    /**Ссылки на copyright слоя */
    copyright: options.copyright || null,

    layers: options.layers || '',

    // Типи для получения объекта идентификации ПКК
    pkkIdentificationTypes: options.pkkIdentificationTypes || null,
    // Слои для настройки урл в выделении ПКК
    pkkSelectIdentificationLayers: options.pkkSelectIdentificationLayers || null,
    //Тип для получения точек в слое ПКК для дальнейшего получения объектов ПКК разных подслоев
    pkkSelectIdentificationLayersType: options.pkkSelectIdentificationLayersType || null,
    // УРЛ для выделения ПКК
    pkkSelectIdentificationUrl: options.pkkSelectIdentificationUrl || null,

    styles: options.styles || '',

    format: options.format || 'image/png',

    transparent: options.transparent || false,

    version: options.version || '1.1.1',

    tileSize: options.tileSize || 256,

    subdomains: options.subdomains || '',

    //arcgis
    size: options.size || 256,

    //arcgis
    dpi: options.dpi || 50,

    arcGisToken: null,

    bbox: options.bbox || DEFAULT_EPSG_BBOX,

    //wcs
    coordinates: options.coordinates,

    //wfs
    typeName: options.typeName || '',

    //wfs
    maxFeatures: options.maxFeatures || 500,

    /**Флаг базовой карты */
    baseMap: options.baseMap || false,

    /**Флаг базовой карты подписей */
    baseTextMap: options.baseTextMap || false,

    /**Флаг базовой карты рельефа */
    terrainMap: options.terrainMap || false,

    /**Флаг отображения в легенде */
    visibleInLegend: options.visibleInLegend !== false,

    /**Идентификатор Gis-компонента */
    parentGisId,

    /**Идентификатор компонента-слоя */
    layerComponentId: componentConfig.componentId || null,

    /**Идентификатор компонента DataSet связанного со слоем */
    dataSetComponentId: componentConfig.dataSetComponentId || null,

    /**Mapbox source layer */
    sourceLayer: options.sourceLayer || null,

    /**Доступные типы геометрий ['Polygon', 'Line', 'Point'] */
    geomTypes: options.geomtypes || options.geomTypes || null,

    /**Удалять объект целиком или только геометрию
     * Раньше было специально поставлено в false. Почему-то.
     */
    deleteObject: options.deleteObject ?? false,

    /**Показывать кнопки "Калькулятор" и "Геокодирование по кадастру"*/
    visibleCalcAndGeocodeButtons: options.visibleCalcAndGeocodeButtons ?? true,

    /**Массив возможных проверок для слоя*/
    validationList: [],

    /**Массив выбранных проверок для слоя*/
    selectedValidationIdsList: [],

    /**Массив выбранных проверок для слоя, сохраненных в базе*/
    selectedValidationsIdsListFromDataset: [],

    /**Костыльное свойство, которое блокирует обновление сущности после обновления карточки */
    blockPropertiesUpdate: options.blockPropertiesUpdate || false,

    /**Показывать карточку при вставке объекта */
    showCardOnPaste: options.showCardOnPaste !== false,

    /**Открывать карточку при левом клике */
    openCardOnLeftClick: options.openCardOnLeftClick || false,

    /**Включено редактирование, не принимать во внимание настройки сервера */
    edit: false,

    /**Непрозрачность слоя */
    rasterOpacity: options.rasterOpacity || 1,

    /**Включен выбор */
    enable: options.enable || false,

    /**Включено частичное отображение */
    halfVisible: options.halfVisible || false,

    /**Исключение слоя из отображения не меняя более ничего */
    excluded: false,

    /**Показывать карточку при добавлении объекта */
    showInsertCard: options.showInsertCard || false,

    /**Код DataSet */
    dataSetName: options.dataSetName || null,

    /**Карточка, Таблица и Фильтр */
    cards: options.cards || {},

    /**Тип слоя */
    type: options.type,

    /**Подтип слоя, модифицирующий поведение слоя*/
    subType: options.subType,

    /**Флаг о том, что wms слой создан из растра*/
    raster: options.raster,

    /**Сервер-источник растрового слоя (portal | geoserver) */
    server: options.server,

    /**Свойства */
    properties: options.properties,

    /**
     * Параметры фильтрации векторного слоя, которые надо отправлять во все запросы (плитки, подсчет количества, таблица)
     * Примеры: здания, сооружения из ДОМ.РФ, Псков по видам уборки
     */
    params: options.params || {},

    /**Значения по умолчанию */
    defaultValues: options.defaultValues,

    /**Слой с одним объектом */
    singleFeature: options.singleFeature || false,

    /**TODO: дополнить описание */
    singleFeatureEventOnly: options.singleFeatureEventOnly || false,

    /**Настройки mapbox source */
    sourceOptions: options.sourceOptions || {},

    /**Свойства надо брать из отображаемого объекта а не с помощью датасета */
    identifyGeometryProperties: options.identifyGeometryProperties || false,

    /**Настройки полей для FilterBuilder в форме стиля */
    styleFilterFields: options.styleFilterFields || [],

    /**Названия полей, которые должны приходить в векторной плитке */
    tmsFields: options.tmsFields || [],

    /**Текст(заголовок) слоя */
    text: options.text || '',

    /**Параметр для перерисовки тайлов (добавлением к url) */
    paramForRepaint: 0,

    /**
     * Параметр для запроса к векторной плитке
     * */
    is_cache: options.is_cache ?? (options.type === 'vector' ? false : true),

    /**Токен для авторизации в сервисе*/
    token: options.token || null,

    /**Название стиля Mapbox, для предустановленых стилей базовых карт*/
    styleName: options.styleName || '',

    /**Url иконки */
    iconUrl: options.iconUrl || null,

    /**Css-класс для иконки */
    iconCss: options.iconCss || null,

    /**Css-класс для иконки открытой папки */
    iconExpandedCss: options.iconExpandedCss || null,

    /**Цвет для иконки */
    iconColor: options.iconColor || null,

    /**Видимость подписей */
    textVisible: savedLayerOptions.hasOwnProperty('textVisible') ? savedLayerOptions.textVisible : options.textVisible || false,

    /**Видимость кнопки переключения видимости подписей */
    textVisibleShow: ['vector', 'geojson'].includes(options.type),

    /**Видимость кнопки включения редактирования ("карандаш")*/
    editShow: true,

    /**Видимость кнопки видимости ("глаз") */
    visibleShow: !options.singleFeature,

    /**Разрешено ли включать видимость нескольких слоев в папке одновременно
     * (если false, то при включении видимости слоя в такой папке, видимость предыдущего отключается)
     * TODO: Актуально?
     */
    multiple: options.multiple === undefined ? true : options.multiple,

    /**Состояние раскрытия для папки */
    expanded: options.expanded || false,

    /**Возможность раскрытия для папки */
    expandEnabled: options.expandEnabled !== undefined ? options.expandEnabled : true,

    //Ширина картинки для легенды в растровом слое
    legendImageWidth: options.legendImageWidth || '150px',
    //Высота картинки для легенды в растровом слое
    legendImageHeight: options.legendImageHeight || '150px',
    //Адрес картинки для легенды в растровом слое
    legendImageUrl: options.legendImageUrl || '',

    /**Состояние раскрытия списка правил стилей */
    styleListExpanded: options.styleListExpanded || false,

    /**Видимость меню с доп. инструментами слоя (Активность кнопки "три точки") */
    more: false,

    /**Настройка видимости дополнительных инструментов слоя
     * {
      table: false,
      options: false,
      moveToLayer: false,
      export: false,
      buffer: false,
      filter: false,
      clone: false,
    }
     */
    // Объекту со значением null не прсваиваются значения свойств
    panelButtons: options.panelButtons || { basic: true },

    /**Объект с правами */
    rights: {
      /**Открытие окна настроек слоя */
      editLayer: true,

      /**Открытие карточки объекта */
      showCard: true,

      /**Открытие интерактивной карточки с отчетом  */
      showReport: true,

      /**Дозагрузка данных в слой */
      loadData: true,

      /**Изменение атрибутов слоя */
      editFields: true,

      /**Изменение прав */
      editAccess: true,

      // Вкладка Настройки слоя
      editOptions: true,

      /**Открытие калькулятора**/
      showCalc: rootGetters['session/userIsAdmin'],

      /**Редактирование объектов слоя (карандаш)*/
      editObjects: true,

      /**Редактирование атрибутов объектов слоя */
      editAttributes: true,

      /**Вкладка "Отчеты" в форме настроек слоя */
      reports: true,

      /**Вкладка проверки*/
      validations: true,

      /**Геокодирование данных (на вкладке "Колонки")*/
      geocode: true,

      /**По данному слою разрешено формировать запросы */
      requestEnabled: false,

      /**Отображать кнопку настройки подсказки*/
      showTooltipButton: true,

      /**Расчет колонок слоя (на вкладке "Колонки")*/
      showCalcField: true,

      /**Удаление слоя*/
      remove: true,

      gisMinimizedVisible: false,

      ...(options.rights || {})
    },

    /**Включено отображение */
    //Для geojson (если не singleFeature) visible при инициализации false - это костыль
    //!['geojson', 'wfs'].includes(options.type) || !!options.singleFeature
    //Костыль убрал, рекомендация Сергея от 2022-06-29, нужно для /reestr-dokumentov-gkn
    visible: gisMinimizedVisible ? true : showVisibleLayers === false ? false : options.visible ?? false,

    /**Показывать индикатор загрузки */
    loading: false,

    /**Групповой слоя (папка) */
    group: componentConfig.componentType === 'GisGroupLayer',

    /**Идентификаторы дочерних слоев */
    items: [],

    /**Идентификатор компонента карточки */
    cardComponentId: null,

    /**Идентификатор компонента таблицы */
    tableComponentId: null,

    /**Идентификатор компонента фильтра */
    filterComponentId: null,

    /**Идентификатор компонента всплывающей подсказки */
    tooltipComponentId: null,

    /**Сворачивать при инициализации */
    collapsedOnInit: options.hasOwnProperty('collapsedOnInit') ? options.collapsedOnInit : null,

    /**{String} Тип слоя */
    layerType: null,

    /**Название слоя (для отладки)*/
    layerCaption: '',

    // Значение кол-ва объектов слоя из prop для инфо по слою в легенде
    layer_count: options.layer_count || null,

    // Значение площади слоя из prop для инфо по слою в легенде
    layer_area: options.layer_area || null,

    /**{String} Идентификатор тайлового источника данных mapbox*/
    tileSourceId: null,

    /**{String} Идентификатор geojson источника данных mapbox */
    geojsonSourceId: null,

    /**{Object} Настройки источника geojson mapbox */
    geojsonSourceConfig: null,

    /**{Object} Настройки источника тайлов mapbox */
    tileSourceConfig: null,

    /**{Object[]} Настройки слоев mapbox для редактируемых объектов */
    tileEditcomponentConfigs: [],

    /**{Object} Словарь GeoJson Feature */
    featuresDict: {},

    /**Время последнего обновления geoJson для принудительного обновления карты*/
    featuresLastEdit: 10,

    /**{String} Ключевое поле для сохранения загруженных объектов в словарь */
    keyField: options.keyField || 'id',

    /**Геометрия, используемая для выделения*/
    selectionGeoJson: {
      features: [],
      ids: [],
      not_ids: [],
      //Сама геометрия
      geoJson: []
    },

    /**{Array} Идентификаторы выбранных объектов */
    selectedIds: [],

    /**{Array} Идентификаторы редактируемых объектов */
    editingIds: [],

    /**Признак выделения объекта после добавления */
    selectObjectAfterAdd: options.selectObjectAfterAdd ?? true,

    /**{Boolean} Прятать выбранные объекты в основном слое. Нужно чтобы полностью менять стиль выделения*/
    excludeSelectedFeatures: options.excludeSelectedFeatures || false,

    //Отображать слои выделения под основными слоями
    selectionBelow: false,

    /**{Array} Список столбцов данных слоя*/
    dataFields: [],

    /**{String} Слой, перед которым добавлять */
    beforeLayerId: null,

    /**@type {Function} Callback при изменени geojson выбранных объектов */
    onSelectionGeoJsonChanged: null,

    /**@type {Function} Callback правый клик на выделенном объекте */
    onSelectionFeatureRightClick: null,

    /**{String} Специальный слой для сортировки отображения слоев */
    specialSortLayer: 'specialSortLayer',

    /**{Number[]} */
    bounds: options.bounds || null,

    /**@type {Function} Callback обновление geojson данных  */
    onDataRefreshed: null,

    /**@type {Integer} Максимальное количество шагов, сохраняемых в истории изменений */
    historyLength: undefined,

    /**Индекс в истории изменений */
    historyIndex: -1,

    /**Сохраненный индекс в истории изменений */
    savedHistoryIndex: 0,

    /**История операций*/
    operationHistory: [],

    /**Идентификаторы добавленных несохраненных объекты*/
    addedIds: [],

    /**Идентификаторы отредактированных несохраненных объектов */
    editedIds: {},

    /**Идентификаторы удаленных несохраненных объектов */
    deletedIds: [],

    /**Идентификаторы удаленных сохраненных и несохраненных объектов
     * хранится до перезагрузки страницы
     */
    permanentDeletedIds: [],

    /**@type {Function} Callback изменение индекса истории */
    onHistoryIndexChanged: null,

    /**@type {Function} Callback отмена последнего действия */
    onFeatureUndo: null,

    /**@type {Function} Callback повтор последнего действия */
    onFeatureRedo: null,

    /**Фильтр, скрывающий выделенные элементы слоя */
    selectionFilter: null,

    /**Массив id элементов векторного слоя, находящихся в слое для редактированния */
    //geojsonFilterFeaturesIds:  [],

    /**Вспомогательная карта для фильтрации слоя по атрибутам других слоев. Ключ - id слоя, значение - фильтр */
    /**TODO: Кажется не используется */
    filtersMap: {},

    /**Вспомогательная карта для фильтрации слоя по атрибутам других слоев. Ключ - код датасета, значение - фильтрю Только для чтения */
    /**TODO: Кажется не используется */
    filter: null,

    /**Список слоев, добавленных пользователем для кроссфильтрации */
    filterCrossLayers: [],

    /**Значение серверного фильтра*/
    filterValue: null,
    filter_value: null, //TODO: где-то наконструировали использование этого поля? ДА! Старая кнопка в гриде "Фильтровать"

    /**Предустановленный фильтр
     * configFilterId - id фильтра из базы CONFIG
     * filterName - имя фильтра
     * loading - флаг процесса загрузки
     * loaded - флаг окончания загрузки
     */
    defaultFilterConfig: {
      configFilterId: null,
      filterName: null,
      loading: false,
      loaded: false,
      ...(options.defaultFilterConfig || {})
    },

    /**Идентификатор серверного фильтра который создаем для сложных фильтров между слоями разных баз */
    filterId: null,

    /**Постоянное значение where фильтра из настроек слоя */
    where: options.where || options.type === 'vector' ? getWhereFromUrl(options.url) : null,

    /**Словарь настроек для отображения GisLegendStyleItem */
    legendStyleItems,

    /**Идентификаторы настроек корневых GisLegendStyleItem */
    legendStyleItemIds,

    /**Видимость таблицы слоя */
    tableVisible: false,

    /**Флаг показывает, что дочерние слои загружены */
    childLayersLoaded: false,

    /**Промисы загрузки дополнительной информации по слоям arcGis */
    arcGisLayerPromises: {},

    /**Информация по слоям arcGis */
    arcGisLayers: null,

    /**Информация по легенде arcGis */
    arcGisLegend: null,

    /**Информация по легендам WMS */
    wmsLegends: [],

    /**Поля фильтра для слоя */
    filterFieldOptions: null,

    /**Значения полей фильтрации, TODO: сделать класс */
    filterFieldValues: null,

    /**Массив полей для FilterBuilder, обновляеть с помощью дейтвия loadFilterBuilderFields */
    filterBuilderFields: [],

    /**Массив значений фильтра статистики слоя */
    statisticFilterKeys: null,

    /**Массив идентификаторов списков объектов (списки исключения со знаком "-") */
    filterListIds: null,

    /**Значение сложного запроса**/
    complexFilterValue: null,

    /**Фильтр из формы фильтрации конструктора**/
    constructorFilterValue: null,

    /**Значения полей формы фильтрации, созданной на конструкторе (необходимо для сложной фильтрации на бэке) */
    constructorFilterParams: null,

    /**id объекта для которого редактируется геометрия**/
    editingObjectId: null,

    //Название параметра в который надо передать дату из таймлайна при запросе плиток
    timelineDateField: options.timelineDateField || null,

    //Тип поля временной фильтрации: для года может быть целым или датой
    timelineDateFieldType: null,

    //Режим работы временной шкалы: year, month, date, hour, 20minutes
    timelineMode: options.timelineMode || 'date',

    /**Отображение данных во времени: с накоплением, без накопления */
    timelineViewType: options.timelineViewType || 'simple',

    /** Указывает, в какой параметр при запросе плиток передавать поле временной шкалы: params, where, all
     * костыльный параметр, поскольку разные слои работают с разными параметрами
     */
    timeLineFieldSendMode: options.timeLineFieldSendMode || 'where',

    /**Не передавать при отключении временной шкалы */
    timeLineNoTransmit: options.timeLineNoTransmit || false,

    /**Promise первоначальной загрузки geoJson, нужен для geoJson слоев */
    geoJsonLoadPromise: null,

    /**Долгота и широта расположения модели */
    modelOrigin: options.modelOrigin,

    /**Высота расположения модели */
    modelAltitude: options.modelAltitude,

    /**Матрица поворота модели */
    modelRotate: options.modelRotate,

    /**Всплывающая подсказка слоя */
    //Варианты: mouse, center, none
    tooltipPosition: options.tooltipPosition || 'none',
    tooltipExpression: options.tooltipExpression || '',

    /**Поля поиска объектов слоя */
    searchFields: null,

    /**Флаг для отображения лоадиндикатора в поле быстрого поиска объектов */
    searchDataLoading: false,

    /**Последний параметр, который был передан в действие refreshData */
    refreshDataParam: null,

    /**Настройки выбора дополнительных параметров слоя
     * { label, listCaptionField, listKeyField, dataset, targetField }
     */
    formParams: options.formParams || null,

    /**Дополнительные параметры фильтрации слоя. Настройки выбираются из датасета, установленного в настройках formParams.
     * Выбранные настройки отображаются в списке слоев в виде tagbox. Используется в слое Посты air-rf-map.
     */
    formParamsValue: null,

    /**Отображать форму с параметрами в панели легенд */
    showFormParams: options.showFormParams ?? true,

    /**
     * Проверять самопересечения в добавляемых и редактируемых полигонах
     */
    validatePolygonKinks: options.validatePolygonKinks || false,

    /**
     * Использовать прокси при запросах (только для растровых тайлов)
     */
    useProxy: options.useProxy || false,

    /**
     * Можно ли перемещать слои потомки, но не сам данный слой, по-умолчанию наследуется от родителя
     */
    allowDragChildLayers: options.allowDragChildLayers ?? (parentOptions && parentOptions.allowDragChildLayers),

    /**
     * Показывать кнопку инструмента "Доступность в точке"
     * Значение true перезаписывает аналогичный параметр для компонента карты
     */
    showPointAvailabilityButton: options.showPointAvailabilityButton,

    /**
     * Разрешить выбор нескольких типов геометрии для слоя
     */
    enableMultipleGeomTypes: options.enableMultipleGeomTypes ?? true,

    /**Анимация слоя*/
    animation: options.animation ?? null,

    /**
     * Стили для портального растрового слоя
     * */
    rasterStyle: options.rasterStyle || [],
    /**
     * Списки каналов с опциями для настроек растра
     * */
    bandsRasterList: options.bandsRasterList || {},
    /**
     * Для внешних сервисов в легенду для перехода по ссылке
     * */
    externalUrl: options.externalUrl || null,
    /**
     * Для внешних сервисов в легенду для перехода по ссылке, название ссылки
     * */
    externalUrlCaption: options.externalUrlCaption || null,
    /**
     * Включалась ли уже видимость слоя. Для минимизированной карты
     * */
    minimizeOnceVisible: false,
    /**
     * Id включенного стиля для слоя
     * */
    legendStyleItemId: options.legendStyleItemId || (legendStyleItemIds ? legendStyleItemIds[legendStyleItemIds.length - 1] : null),
    /**
     * {GeoJson} Геометрия привязки растра сохранненное последнее состояние, что бы после перезагрузки можно было продолжить работу
     * */
    bindingTileGeoJsonBackup: options.bindingTileGeoJsonBackup || null,
    /**
     * Общее кол-во объектов в фильтрах сбрасыввается и запрашивается только в самой форме фильтрации, флаг для сброса данных
     * */
    refreshFilterTotalDataFlag: false,
    /**Позиция слоя в списке избранных */
    favoritePos: 0,
    /**Настройки всплывающих подсказок, отображаемых постоянно*/
    permanentTooltips: options.permanentTooltips,
    /** Отображение постоянных всплывающих подсказок*/
    permanentTooltipsVisible: options.permanentTooltipsVisible ?? true,
    /** Тип кадастрового объекта для поиска по кн*/
    cadastrType: options.cadastrType || null,
    /** Имя/номер шаблона для проверки на соответствие файла импорта данных в слой*/
    layerImportTemplate: options.layerImportTemplate || null
  };
  //С сервера может прийти не массив
  if (!Array.isArray(newOptions.mapboxSelectionLayers)) {
    newOptions.mapboxSelectionLayers = [];
  }
  if (!Array.isArray(newOptions.mapboxHoverLayers)) {
    newOptions.mapboxHoverLayers = [];
  }
  if (!Array.isArray(newOptions.mapboxLayers)) {
    newOptions.mapboxLayers = [];
  }
  return newOptions;
}

export function getLayerSavedOptions(layerComponentId, parentGisId, gisOptions) {
  const savedLayerOptionsString = gisOptions?.persistLayerState !== false ? localStorage.getItem(`${parentGisId}_${layerComponentId}`) : null;
  if (savedLayerOptionsString) {
    return JSON.parse(savedLayerOptionsString);
  } else {
    return {};
  }
}

export function getLegendStyleItems(layerComponentId, layerComponentOptions, showVisibleLayers = true, gisMinimizedVisible = false) {
  let result = {};

  switch (layerComponentOptions.type) {
    case 'vector':
    case 'geojson':
    case 'wfs':
    case 'tile':
      result = getVectorLegendStyleItems(layerComponentId, layerComponentOptions, showVisibleLayers, gisMinimizedVisible);
      break;
    case 'wms':
      result = getWmsLegendStyleItems(layerComponentId, layerComponentOptions, showVisibleLayers, gisMinimizedVisible);
      break;
    case 'pkk':
    case 'arcgis':
      result = getArcGisLegendStyleItems(layerComponentId, layerComponentOptions, showVisibleLayers, gisMinimizedVisible);
      break;
  }

  return result;
}

function getVectorLegendStyleItems(layerComponentId, layerComponentOptions, showVisibleLayers = true, gisMinimizedVisible = false) {
  let result = {};
  const mapboxLayerConfigs = layerComponentOptions.mapboxCalcInterpolationLayers || layerComponentOptions.mapboxLayers || [];
  if (Array.isArray(mapboxLayerConfigs)) {
    mapboxLayerConfigs.forEach((mapboxLayerConfig) => {
      const id = mapboxLayerConfig.name || mapboxLayerConfig['source-layer'] || 'default';

      if (!result.hasOwnProperty(id)) {
        result[id] = new GisLegendStyleItemOptions({
          id,
          parentId: layerComponentId,
          caption: mapboxLayerConfig.caption,
          visible:
            //Костыль убран
            //!['geojson', 'wfs'].includes(layerComponentOptions.type) || !!layerComponentOptions.singleFeature
            gisMinimizedVisible ? true : showVisibleLayers === false ? false : layerComponentOptions.visible || false,
          expanded: true,
          iconCss: null,
          iconExpandedCss: null,
          iconColor: null,
          iconUrl: null
        });
      }

      result[id].addMapboxLayerConfig(mapboxLayerConfig);
    });

    return result;
  }
}

function getArcGisLegendStyleItems(layerComponentId, layerComponentOptions, showVisibleLayers, gisMinimizedVisible = false) {
  let result = {};
  const layers = layerComponentOptions.layers || '';
  const arcGisLayerIds = layers.replace('show:', '').split(',');

  arcGisLayerIds.forEach((arcGisLayerId) => {
    result[arcGisLayerId] = new GisLegendStyleItemOptions({
      id: arcGisLayerId,
      parentId: layerComponentId,
      caption: '',
      visible: gisMinimizedVisible ? true : showVisibleLayers === false ? false : layerComponentOptions.visible || false,
      expanded: true,
      iconCss: null,
      iconExpandedCss: null,
      iconColor: null,
      iconUrl: null,
      hideVisibleButton: false
    });
  });

  return result;
}

function getWmsLegendStyleItems(layerComponentId, layerComponentOptions, showVisibleLayers, gisMinimizedVisible = false) {
  const result = {};
  const layerIds = layerComponentOptions.layers.split(',');

  layerIds.forEach((layerId) => {
    result[layerId] = new GisLegendStyleItemOptions({
      id: layerId,
      parentId: layerComponentId,
      caption: '',
      visible: gisMinimizedVisible ? true : showVisibleLayers === false ? false : layerComponentOptions.visible || false,
      expanded: true,
      iconCss: null,
      iconExpandedCss: null,
      iconColor: null,
      iconUrl: null,
      hideVisibleButton: false
    });
  });

  return result;
}

function getWhereFromUrl(url) {
  if (!url || !url.includes('?')) {
    return null;
  }
  let where = null;
  url
    .substr(url.lastIndexOf('?') + 1)
    .split('&')
    .forEach((item) => {
      if (item.includes('where')) {
        const value = item.substr(item.indexOf('=') + 1);
        where = JSON.parse(decodeURIComponent(value));
      }
    });
  return where;
}
